import React from "react";
import { func, object } from "prop-types";
import AsistenciaHelp from "components/help/helpMedidas/AsistenciaHelp";
import BiodigestoresHelp from "components/help/helpMedidas/BiodigestoresHelp";
import MedidasRegionesContainer from "./MedidasRegionesContainer";

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (medida === "Asistencia técnica al uso eficiente de fertilizantes") {
    return <AsistenciaHelp region={region} />;
  } else if (medida === "Biodigestores de purines porcinos") {
    return <BiodigestoresHelp region={region} />;
  }
  return null;
};

// Crea un container con la lista de medidas que se puede seleccionar para el sector 'Agricultura'
const AgriculturaPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="Agricultura"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

AgriculturaPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default AgriculturaPolicies;

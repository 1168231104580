import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/taxiselectricos.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const TaxisElectricosHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 233,
        porcentaje: 0.24,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 1,
        porcentaje: 0.04,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 0,
        porcentaje: 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 1,
        porcentaje: 0.02,
      },
    },
    region_de_atacama: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 4,
        porcentaje: 0.15,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 11,
        porcentaje: 0.19,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 8,
        porcentaje: 0.07,
      },
    },
    region_metropolitana: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 151,
        porcentaje: 0.41,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 41,
        porcentaje: 0.83,
      },
    },
    region_del_maule: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 1,
        porcentaje: 0.02,
      },
    },
    region_del_nuble: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 0,
        porcentaje: 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 3,
        porcentaje: 0.08,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 3,
        porcentaje: 0.09,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 5,
        porcentaje: 0.27,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 2,
        porcentaje: 0.04,
      },
    },
    region_de_aysen: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 2,
        porcentaje: 0.28,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        parque: 0,
        porcentaje: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Taxis_Eléctricos")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Taxis eléctricos"]);
      set2050(dataset[60]["Taxis eléctricos"]);
    })
    .catch((error) => {
      console.error(error);
    });
  return (
    <HelpContent>
      <div>
        <h1>Taxis Eléctricos</h1>
      </div>
      <h4>Sector Transporte</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera una penetración del 100% de taxis eléctricos
        (colectivos y taxis básicos) al 2040 a nivel nacional.
      </p>
      <p>
        En el escenario de referencia, se considera una penetración de un 21% de
        taxis eléctricos al 2050 (15% a batería y 6% híbrido enchufable). Se
        considera una tasa de retiro de vehículos antiguos del 2% anual
        constante.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Parque de taxis{" "}
                    <span>{cumplimientoRegion["compromiso_referencial"]}</span>%
                    eléctricos al año 2040 a nivel nacional.
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,5 (2030) y 1,6 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    {" "}
                    Participación de taxis eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                  <p>
                    Parque de taxis eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["parque"]
                      ).replace(".", ",")}
                    </span>
                  </p>
                </WrapperExp>
             

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Parque de taxis{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]}
                </span>
                % eléctricos al año 2040
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,5 (2030) y 1,6 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>
          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                {" "}
                Participación de taxis eléctricos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
              <p>
                Parque de taxis eléctricos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque"]
                  ).replace(".", ",")}
                </span>
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.5, 1.6, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default TaxisElectricosHelp;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/electromovilidad transporte publico rm.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const TransporteRMHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        "porcentaje_total": 6,
        "porcentaje_urbano" : 1,
        "porcentaje_inter" : 0,
        "porcentaje_RED" : 37,
        "parque_urbano" : 70,
        "parque_inter" : 1,
        "parque_RED": 2414
      },
    },
    region_metropolitana: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        "porcentaje_total": 6,
        "porcentaje_urbano" : 0,
        "porcentaje_inter" : 0,
        "porcentaje_RED" : 37,
        "parque_urbano" : 0,
        "parque_inter" : 1,
        "parque_RED": 2414
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Usos_Motrices_en_Industria_y_Minería_Hidrógeno"
          )
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Buses Eléctricos en Transporte público RM"]);
      set2050(dataset[60]["Buses Eléctricos en Transporte público RM"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Buses Eléctricos en Transporte Público - RM</h1>
      </div>
      <h4>Sector Transporte</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera un adelanto de la medida de la Estrategia de
        Electromovilidad, simulando una penetración del 100% de buses eléctricos
        en la Región Metropolitana al 2040.
      </p>
      <p>
        Se considera una participación del 100% de buses eléctricos en la Región
        Metropolitana al 2040, equivalente a una flota de 11.300 buses. Se
        estableció la entrada de nuevas máquinas considerando una vida útil de
        10 años para buses diésel y 14 años para buses eléctricos.
      </p>
      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Parque de buses <span>{cumplimientoMedidas["region_metropolitana"]["compromiso_referencial"]}</span>% eléctricos en la RM al año
                    2040 (11.300 buses)
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,4 (2030) y 1,1 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Participación total de buses eléctricos (estimación):{" "}
                    <span>{String(cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["porcentaje_total"]).replace(".",",")}
                    </span>
                    %
                  </p>

                  <p>
                    Participación de buses urbanos eléctricos (diciembre 2022):{" "}
                    <span>{String(cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["porcentaje_urbano"]).replace(".", ",")}
                    </span>
                    %<br />
                    Participación de buses interurbanos eléctricos (diciembre 2022):{" "}
                    <span>{String(cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["porcentaje_inter"]).replace(".",",")}
                    </span>
                    %<br />
                    Participación de buses RED eléctricos (enero 2024):{" "}
                    <span>{String(cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["porcentaje_RED"]).replace(".",",")}
                    </span>
                    %
                  </p>

                  <p>
                    Parque de buses urbanos eléctricos (diciembre 2022):{" "}
                    <span>{cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["parque_urbano"]}</span>
                    <br />
                    Parque de buses interurbanos eléctricos (diciembre 2022):{" "}
                    <span>{cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["parque_inter"]}</span>
                    <br />
                    Parque de buses RED eléctricos (enero 2024):{" "}
                    <span>{cumplimientoMedidas["region_metropolitana"]["estado_de_implementacion"]["parque_RED"]}</span>
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Parque de buses <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]}</span>% eléctricos en la RM al año
                2040 (11.300 buses)
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,4 (2030) y 1,1 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Participación total de buses eléctricos (estimación):{" "}
                <span>
                  {String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_total"]).replace(".",",")}
                </span>
                %
              </p>

              <p>
                Participación de buses urbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_urbano"]).replace(".",",")}
                </span>
                %<br />
                Participación de buses interurbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_inter"]).replace(".",",")}
                </span>
                %<br />
                Participación de buses RED eléctricos (enero 2024):{" "}
                <span>
                  {String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_RED"]).replace(".",",")}
                </span>
                %
              </p>

              <p>
                Parque de buses urbanos eléctricos (diciembre 2022):{" "}
                <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque_urbano"]}</span>
                <br />
                Parque de buses interurbanos eléctricos (diciembre 2022):{" "}
                <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque_inter"]}</span>
                <br />
                Parque de buses RED eléctricos (enero 2024):{" "}
                <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque_RED"]}</span>
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.4, 1.1, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default TransporteRMHelp;

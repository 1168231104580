import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}

  @media(max-width: 720px) {
    display: block;
  }
`;

export default Wrapper;
export const MEDIDAS = [
  "Generación distribuida",
  "Fomento a renovación energética de viviendas",
  "Calefacción eléctrica público comercial",
  "Sistemas solares térmicos – residencial y público",
  "Electrificación motriz comercial",
  "Bombas Geotérmicas de Calor",
  "RT viviendas vulnerables",
  "Uso térmico vía gasoductos - Hidrógeno",
  "Calefacción distrital",
  "MEPS Nuevos",
  "Cambio modal transporte",
  "Taxis eléctricos",
  "Vehículos comerciales eléctricos",
  "Transporte de carga - Hidrógeno",
  "Buses Eléctricos en Transporte público RM",
  "Buses Eléctricos en Transporte público regiones",
  "Vehículos particulares eléctricos",
  "Sistemas de gestión de energía",
  "Electrificación motriz industria",
  "MEPS Motores hasta 100HP",
  "Usos motrices en industria y minería - Hidrógeno",
  "SST Industria y minería",
  "Electrificación térmica",
  "Electrificación motriz - minería cobre",
  "Electrificación motriz - resto minería",
  "Generación biogás",
  "Energías renovables en reemplazo de centrales Térmicas",
  "Asistencia técnica al uso eficiente de fertilizantes",
  "Biodigestores de purines porcinos",
  "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal",
  "Captura o uso de biogás de rellenos sanitarios",
];

export const Autoridades = [
  "Ministerio de Energía",
  "Ministerio de Transportes y Telecomunicaciones",
  "Ministerio de Salud",
  "Ministerio de Obras Públicas",
  "Ministerio de Vivienda y Urbanismo",
  "Ministerio de Minería",
  "Ministerio de Agricultura",
];

export const PresupuestoAutoridades = {
  "Ministerio de Energía": 271.8,
  "Ministerio de Transportes y Telecomunicaciones": 303.1,
  "Ministerio de Salud": 51.1,
  "Ministerio de Obras Públicas": 43.3,
  "Ministerio de Vivienda y Urbanismo": 95.3,
  "Ministerio de Minería": 174.1,
  "Ministerio de Agricultura": 122.4,
};

export const ReduccionesEsperadas = {
  "Ministerio de Energía": 38.87,
  "Ministerio de Transportes y Telecomunicaciones": 2.83,
  "Ministerio de Salud": 2.42,
  "Ministerio de Obras Públicas": 0.71,
  "Ministerio de Vivienda y Urbanismo": 4.75,
  "Ministerio de Minería": 6.76,
  "Ministerio de Agricultura": 0.99,
};

export const YEAR_CATEGORY = "año";
export const MIN_YEAR = 1990;
export const MAX_YEAR = 2018;
export const YEAR_INVENTORY = 2020;
export const YEAR_UPDATE = 2023;
export const YEAR_UPDATE_NDC = 2020; // Año en que empieza la proyeccion oficial NDC y Carbon Budget
export const YEAR_KPI_HOME_1 = 2030;
export const YEAR_KPI_HOME_2 = 2050;
export const FECHA_ACTUALIZACION = "5 de abril de 2024";

// Incertidumbre para el kpi del primer grafico del home
export const incertidumbre_grafico_1 = 5;

// incertidumbre para el kpi del segundo grafico del home al año home 1, actual 2030
export const incertidumbre_grafico_2 = 6;

// incertidumbre para el kpi del segundo grafico del home al año home 2, actual 2050
export const incertidumbre_grafico_3 = 8;

export const COLORS = [
  "rgba(244, 67, 54, 1)",
  "rgba(233, 30, 99, 1)",
  "rgba(156, 39, 176, 1)",
  "rgba(103, 58, 183, 1)",
  "rgba(63, 81, 181, 1)",
  "rgba(33, 150, 243, 1)",
  "rgba(3, 169, 244, 1)",
  "rgba(0, 188, 212, 1)",
  "rgba(0, 150, 136, 1)",
  "rgba(76, 175, 80, 1)",
  "rgba(139, 195, 74, 1)",
  "rgba(205, 220, 57, 1)",
  "rgba(255, 235, 59, 1)",
  "rgba(255, 193, 7, 1)",
  "rgba(255, 152, 0, 1)",
  "rgba(255, 87, 34, 1)",
  "rgba(121, 85, 72, 1)",
  "rgba(158, 158, 158, 1)",
  "rgba(96, 125, 139, 1)",
  "rgba(244, 67, 54, 1)",
  "rgba(233, 30, 99, 1)",
  "rgba(156, 39, 176, 1)",
  "rgba(103, 58, 183, 1)",
  "rgba(63, 81, 181, 1)",
  "rgba(33, 150, 243, 1)",
];

export const sectoresEmisores = [
  "Industrias de la energía",
  "Industrias manufactureras y de la construcción",
  "Transporte",
  "Otros sectores",
  "No especificado",
  "Emisiones fugitivas de combustibles",
  "Transporte y almacenamiento de CO2",
  "Procesos industriales y uso de productos (IPPU)",
  "Agricultura",
  "Residuos",
  "Residuos No aguas",
  "Consumo Propio",
  "Electricidad",
  "Industria",
  "Minería",
  "Público",
  "Comercial",
  "Residencial",
];

export const desagregacion = [
  "Residuos No aguas",
  "Consumo Propio",
  "Electricidad",
  "Industria",
  "Minería",
  "Público",
  "Comercial",
  "Residencial",
];

export const medidasPorMinisterio = {
  "Ministerio de Energía": {
    "Generación distribuida": 0.67,
    "Fomento a renovación energética de viviendas": 0.67,
    "Calefacción eléctrica público comercial": 0.8,
    "Sistemas solares térmicos – residencial y público": 0.67,
    "Electrificación motriz comercial": 1,
    "Bombas Geotérmicas de Calor": 0.57,
    "Uso térmico vía gasoductos - Hidrógeno": 0.8,
    "Calefacción distrital": 0.57,
    "MEPS Nuevos": 1,
    "Taxis eléctricos": 0.38,
    "Vehículos comerciales eléctricos": 0.8,
    "Transporte de carga - Hidrógeno": 0.8,
    "Buses Eléctricos en Transporte público RM": 0.2,
    "Buses Eléctricos en Transporte público regiones": 0.2,
    "Vehículos particulares eléctricos": 0.67,
    "Sistemas de gestión de energía": 0.67,
    "Electrificación motriz industria": 1,
    "MEPS Motores hasta 100HP": 0.67,
    "Usos motrices en industria y minería - Hidrógeno": 0.67,
    "SST Industria y minería": 0.67,
    "Electrificación térmica": 0.67,
    "Electrificación motriz - minería cobre": 0.57,
    "Electrificación motriz - resto minería": 0.67,
    "Generación biogás": 0.67,
    "Energías renovables en reemplazo de centrales Térmicas": 1,
    "Biodigestores de purines porcinos": 0.2,
    "Captura o uso de biogás de rellenos sanitarios": 0.33,
  },
  "Ministerio de Transportes y Telecomunicaciones": {
    "Cambio modal transporte": 0.57,
    "Taxis eléctricos": 0.5,
    "Vehículos comerciales eléctricos": 0.2,
    "Transporte de carga - Hidrógeno": 0.2,
    "Buses Eléctricos en Transporte público RM": 0.8,
    "Buses Eléctricos en Transporte público regiones": 0.8,
    "Vehículos particulares eléctricos": 0.17,
  },
  "Ministerio de Salud": {
    "Generación biogás": 0.17,
    "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal": 0.17,
    "Captura o uso de biogás de rellenos sanitarios": 0.67,
  },
  "Ministerio de Obras Públicas": {
    "Bombas Geotérmicas de Calor": 0.14,
    "Calefacción distrital": 0.14,
    "Cambio modal transporte": 0.14,
    "Generación biogás": 0.17,
    "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal": 0.5,
  },
  "Ministerio de Vivienda y Urbanismo": {
    "Generación distribuida": 0.33,
    "Fomento a renovación energética de viviendas": 0.33,
    "Calefacción eléctrica público comercial": 0.2,
    "Sistemas solares térmicos – residencial y público": 0.33,
    "Bombas Geotérmicas de Calor": 0.14,
    "RT viviendas vulnerables": 1,
    "Uso térmico vía gasoductos - Hidrógeno": 0.2,
    "Calefacción distrital": 0.14,
    "Cambio modal transporte": 0.29,
    "Taxis eléctricos": 0.13,
    "Vehículos particulares eléctricos": 0.17,
  },
  "Ministerio de Minería": {
    "Bombas Geotérmicas de Calor": 0.14,
    "Calefacción distrital": 0.14,
    "Sistemas de gestión de energía": 0.33,
    "MEPS Motores hasta 100HP": 0.33,
    "Usos motrices en industria y minería - Hidrógeno": 0.33,
    "SST Industria y minería": 0.33,
    "Electrificación térmica": 0.33,
    "Electrificación motriz - minería cobre": 0.43,
    "Electrificación motriz - resto minería": 0.43,
  },
  "Ministerio de Agricultura": {
    "Asistencia técnica al uso eficiente de fertilizantes": 1,
    "Biodigestores de purines porcinos": 0.8,
    "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal": 0.33,
  },
};

export const IPPU = {
  Industrial: 0.3172,
  Comercial: 0.0983,
  Residencial: 0.0033,
  Transporte: 0.1185,
  Energía: 0.0878,
  Minería: 0.1174,
  Salud: 0.0125,
  MOP: 0.245,
};

export const Electricidad = {
  Transporte: 0.0115,
  Comercial: 0.1116,
  "Consumo Propio": 0.0388,
  Residencial: 0.2016,
  Minería: 0.3841,
  Industria: 0.2241,
  Público: 0.0282,
};

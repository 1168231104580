import React, { useEffect, useState } from "react";
import Columnas from "components/help/Columnas";
import axios from "axios";
import { REGIONES_PATH } from "config";
import styled from "styled-components";
import Header from "components/layout/Header";
import NavRegion from "components/layout/NavRegion";
import MUIButton from "@material-ui/core/Button";
import Footer from "components/layout/Footer";
import { Link } from "react-router-dom";
import Loader from "components/ui/Loader";
const Wrapper = styled.div`
  width: 100%;
  align: center;
  justify-content: center;
  text-align: center;
`;

const ImageButton = styled(MUIButton)`
  position: relative;
  height: 140px;
  border-radius: 4px;
  display: table-cell;
  width: 25%;

  :hover {
    zindex: 1;
    .MuiImageBackdrop-root {
      opacity: 0.15;
    }
  }
  @media (max-width: 720px) {
    padding: 3%;
    margin: 1%;
    font-size: 8px;
    border-radius: 2px;
    height: 80px;
    width: 50%;
  }
`;

const ImageSrc = styled("span")`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backgroundsize: cover;
  backgroundposition: center 40%;
`;

const Image = styled("span")`
  position: absolute;
  display: block;
  alignitems: center;
  justifycontent: center;
  color: #ffff;
  font-family: Arial;
  font-weight: 700;
  text-decoration: none;
`;

const ImageBackdrop = styled("span")(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: "#080808",
  opacity: 0.4,
  transition: "opacity 200ms",
}));

/**
 * MenuRegiones.js
 * Componente que corresponde a la página de menu de regiones mostrando 16 fotos que redirigen a loa analisis de cada region
 */
const MenuRegiones = () => {
  const [regiones, setRegiones] = useState(null);
  
  useEffect(() => {
    // Load regiones json file
    axios
      .get(REGIONES_PATH)
      .then((response) => setRegiones(response.data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //Crea una fila de botones entre la region min y max del json de regiones.
  const addRow = (min, max) => {
    const regionesRow = [];
    for (let i = min; i <= max; i++) {
      const region = regiones[i];
      const img = require("img/regiones/"+region.image);
      regionesRow.push(
          <Link
            to={`/porregion/${region.name}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            key={region.title.toString()}
          >
            <ImageButton focusRipple>
              <ImageSrc style={{ backgroundImage: `url(${img})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root"/>
              <Image>{region.title.toString()}</Image>
            </ImageButton>
          </Link>
      );
    }
    return regionesRow;
  };

  return (
    <>
      <Header />
      <NavRegion />
      <Wrapper>
        {regiones ? (
          <>
            <Columnas key={'fila1'}>{addRow(0, 3)}</Columnas>
            <Columnas key={'fila2'}>{addRow(4, 7)}</Columnas>
            <Columnas key={'fila3'}>{addRow(8, 11)}</Columnas>
            <Columnas key={'fila4'}>{addRow(12, 15)}</Columnas>
          </>
        ) : (
          <Loader />
        )}
      </Wrapper>
      <center>
        <Footer />
      </center>
    </>
  );
};

export default MenuRegiones;

import React from "react";
import Settings from "components/ui/Settings";
import Checkbox from "components/ui/Checkbox";

/**
 * Crea una lista de Checkbox con las medidas del sector y sus fichas correspondientes.
 * @param {string} sector Nombre del sector que se muestra en el encabezado de la lista
 * @param {object} settings Medidas correspondientes al sector
 * @param {function} onChange Setea el Objeto de medidas que se esta utilizando
 * @param {function} setMedidasActivas Setea la lista de medidas que estan seleccionadas para mostrar en el grafico
 * @param {array} medidasActivas Lista de medidas que estan seleccionadas
 * @param {function} chooseHelp Entrega la ficha dependiendo de la medida y region seleccionada
*/
const MedidasAutoridadesContainer = ({
  sector,
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  chooseHelp,
}) => {
  const medidasNDC = [];
  const otrasMedidas = [];

  // Desactiva los checkbox en caso de que no haya ninguna medida seleccionada
  if (medidasActivas.length === 0){
    Object.entries(settings).forEach(([key,item]) => {
        if(item.enabled === true){
            settings[key].enabled = false;
        }
    })
  }
  // Separa las medidas entre NDC y no NDC segun el valor booleano en settings
  Object.entries(settings).map(([key, item]) => {
    item.ndc ? medidasNDC.push([key, item]) : otrasMedidas.push([key, item]);
  });
  // Chequea si la medida esta en la lista de medidas activas
  const isActive = (medidas, medida) => {
    for (let i in medidas) {
      if (medidas[i] === medida) {
        return true;
      }
    }
    return false;
  };
  // Este componente esta separado en medidas ndc y no ndc, para volver a una lista sin separacion descomentar el bloque de abajo
  return (
    <Settings.Panel.Correct>
      <h4>{`Medidas de mitigación ${sector}`}</h4>
      <h4>NDC</h4>
      {/* Borrar este bloque si se quiere volver a la version sin separacion NDC */}
      {medidasNDC.length > 0 ? (
        medidasNDC.map(([key, item]) => (
          <Checkbox
            id={key}
            key={key}
            label={item.label}
            checked={isActive(medidasActivas, item.label) ? true : item.enabled}
            onChange={(e, value) => {
              if (value) {
                settings[key].enabled = true;
              } else {
                settings[key].enabled = false;
              }
              onChange({
                ...settings,
              });
              if (value) {
                medidasActivas.push(item.label);
              } else {
                medidasActivas = medidasActivas.filter(
                  (medida) => medida !== item.label
                );
              }
              setMedidasActivas(medidasActivas);
            }}
            help={chooseHelp(item.label)}
            
          />
        ))
      ) : (
        <> No existen medidas NDC disponibles </>
      )}
      <h4>Otras medidas</h4>
      {otrasMedidas.length > 0 ? (
        otrasMedidas.map(([key, item]) => (
          <Checkbox
            id={key}
            key={key}
            label={item.label}
            checked={() => (isActive(medidasActivas, item.label) ? true : item.enabled)}
            onChange={(e, value) => {
              if (value) {
                settings[key].enabled = true;
              } else {
                settings[key].enabled = false;
              }
              onChange({
                ...settings,
              });
              if (value) {
                medidasActivas.push(item.label);
              } else {
                medidasActivas = medidasActivas.filter(
                  (medida) => medida !== item.label
                );
              }
              setMedidasActivas(medidasActivas);
            }}
            help={chooseHelp(item.label)}/>
        ))
      ) : (
        <> No existen otras medidas disponibles</>
      )}
    </Settings.Panel.Correct>
  );
};
export default MedidasAutoridadesContainer;
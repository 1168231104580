import React from "react";
import { func, object } from "prop-types";
import UsoLodosHelp from "components/help/helpMedidas/UsoLodosHelp";
import CapturaBiogasHelp from "components/help/helpMedidas/CapturaBiogasHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";

const chooseHelp = (medida) => {
  if (
    medida ===
    "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal"
  ) {
    return <UsoLodosHelp />;
  } else if (medida === "Captura o uso de biogás de rellenos sanitarios") {
    return <CapturaBiogasHelp />;
  }
  return null;
};

const ResiduosPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="Residuos"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

ResiduosPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default ResiduosPolicies;

import React, { useState } from "react";
import styled from "styled-components";
import AutoridadSelector from "./AutoridadSelector";
import ChartMode from "./ChartMode";
import Settings from "components/ui/Settings";
import MinisterioEnergiaPolicies from "./Autoridades/medidasMinisterioEnergia";
import MinisterioTransportePolicies from "./Autoridades/medidasMinisterioTransporte";
import MinisterioSaludPolicies from "./Autoridades/medidasMinisterioSalud";
import MinisterioOpPolicies from "./Autoridades/medidasMinisterioObrasPublicas";
import MinisterioViviendaPolicies from "./Autoridades/medidasMinisterioVivienda";
import MinisterioMineriaPolicies from "./Autoridades/medidasMinisterioMineria";
import MinisterioAgriculturaPolicies from "./Autoridades/medidasMinisterioAgricultura";
import { COLORS, MEDIDAS } from "utils/constants";
import Color from "color";
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  @media (max-width: 720px) {
    display: block;
  }
`;

const Sidebar = styled.div`
  width: ${(props) => (props.narrow ? "360px" : "420px")};
  margin-right: 10px;
  margin-left: 5px;
  overflow-x: hidden;

  @media (max-width: 720px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

const Main = styled.div`
  width: 100%;
`;

const AddMode = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 10px;
    appearance: none;
    float: right;
    margin: 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    background-color: var(--light-grey-color);
    color: white;
    cursor: pointer;
  }
`;
const HowTo = styled.div`
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: justify;
  border-radius: 4px;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;
`;

const Icon = styled.i`
  cursor: pointer;
  position: relative;
  right: 10px;
  color: #333;
  left: 3px;

  &.fa-chevron-up,
  &.fa-chevron-down {
    position: relative;
    right: 15px;
  }
`;

/**
 *  Obtenener el color de fondo de usa serie
 *  basado en el color de la serie
 */
const getBg = (code) => {
  if (code === "rgba(0, 0, 0, 1)") {
    return "rgba(0, 0, 0, 0.5)";
  }
  const color = Color(code);
  return color.alpha(0.4).rgb().string();
};

const DashboardContainerAutoridad = ({ policies }) => {
  // Opciones del menú izquierdo, por ejemplo: Instrumentos económicos
  const [medidaSettings, setMedidaSettings] = useState(
    policies.find((item) => item.name === "Seleccionar Autoridad Climática")
      .settings
  );

  // Obtenemos la ruta del set de datos, basado en la configuración de las medidas.
  const [currentAutoridad_Sectorial, setCurrentAutoridad_Sectorial] = useState(
    policies.find((item) => item.name === "Seleccionar Autoridad Climática")
  );

  // const [medidas, setMedidas] = useState(policies.find(item => item.name === "Transporte").medidas)
  // setup visible charts
  const [visibleCharts, setVisibleCharts] = useState([true]);

  // whether or not we're alread showing all the possible charts
  const amountVisible = visibleCharts.filter((item) => item).length;
  const [medidasActivas, setMedidasActivas] = useState([]);
  const selectAutoridad_SectorialMedidas = (
    currentAutoridad_Sectorial,
    settings,
    medidasActivas
  ) => {
    const howToAutoridad = (
      <HowTo>
        Al seleccionar una medida de este listado, se mostrará en el gráfico el
        efecto de reducción en los gases de efecto invernadero
        <span
          style={{ fontSize: "16px" }}
          role="img"
          aria-labelledby="down-pointing"
        >
          &#x1f447;
        </span>{" "}
        <br />
        Para obtener mayor información respecto a una medida especifica presione
        el ícono <Icon className="fas fa-question-circle" />
      </HowTo>
    );
    if (currentAutoridad_Sectorial.name === "Ministerio de Energía") {
      return (
        <div>
          {howToAutoridad}
          <MinisterioEnergiaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentAutoridad_Sectorial.name ===
      "Ministerio de Transportes y Telecomunicaciones"
    ) {
      return (
        <div>
          {howToAutoridad}
          <MinisterioTransportePolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentAutoridad_Sectorial.name === "Ministerio de Salud") {
      return (
        <div>
          {howToAutoridad}
          <MinisterioSaludPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentAutoridad_Sectorial.name === "Ministerio de Obras Públicas"
    ) {
      return (
        <div>
          {howToAutoridad}
          <MinisterioOpPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentAutoridad_Sectorial.name === "Ministerio de Vivienda y Urbanismo"
    ) {
      return (
        <div>
          {howToAutoridad}
          <MinisterioViviendaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentAutoridad_Sectorial.name === "Ministerio de Minería") {
      return (
        <div>
          {howToAutoridad}
          <MinisterioMineriaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentAutoridad_Sectorial.name === "Ministerio de Agricultura"
    ) {
      return (
        <div>
          {howToAutoridad}
          <MinisterioAgriculturaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    }
  };
  const mostrarMedidas = (medidas) => {
    if (medidas.length === 0) {
      return <div>No hay medidas contabilizadas</div>;
    } else {
      const listItems = medidas.map((number) => {
        let color = getBg(COLORS[MEDIDAS.indexOf(number) % COLORS.length]);
        return (
          <li style={{ color: color, fontSize: "18px", padding: "0px" }}>
            <span style={{ color: "black", fontSize: "14px", padding: "0px" }}>
              {number}
            </span>
          </li>
        );
      });
      return listItems;
    }
  };

  // hide chart on index === i
  const hideChart = (i) => {
    setVisibleCharts(
      visibleCharts.map((item, j) => {
        if (i === j) {
          return false;
        }
        return item;
      })
    );
  };

  const showAdditionalChart = () => {
    setVisibleCharts([...visibleCharts, true]);
  };
  return (
    <Wrapper>
      {/* El analisis por Autoridad solo se muestra si el alcance territorial es nacional */}
      <>
        <Sidebar>
          {currentAutoridad_Sectorial.name ===
          "Seleccionar Autoridad Climática" ? (
            <HowTo>
              En este selector es posible encontrar las autoridades climáticas a
              cargo de la implementación de las medidas de mitigación. <br />
              Seleccione alguna autoridad para desplegar la lista de medidas de
              mitigación a cargo de ésta.
              <span
                style={{ fontSize: "16px" }}
                role="img"
                aria-labelledby="down-pointing"
              >
                &#x1f447;
              </span>
            </HowTo>
          ) : null}
          <AutoridadSelector
            onChange={setCurrentAutoridad_Sectorial}
            onChange2={setMedidaSettings}
            onChange3={setMedidasActivas}
            scenarios={policies}
            selected={
              currentAutoridad_Sectorial
                ? currentAutoridad_Sectorial.name
                : "custom"
            }
          />
          {selectAutoridad_SectorialMedidas(
            currentAutoridad_Sectorial,
            medidaSettings,
            medidasActivas
          )}
        </Sidebar>
        <Main>
          {visibleCharts.map(
            (visible, i) =>
              visible && (
                <ChartMode
                  key={i}
                  index={i}
                  onHide={amountVisible > 1 ? () => hideChart(i) : null}
                  currentScenario={currentAutoridad_Sectorial}
                  settings={medidaSettings}
                  medidasActivas={medidasActivas}
                  type={"autoridad"}
                  selected={currentAutoridad_Sectorial.name}
                />
              )
          )}
          {amountVisible < 5 && (
            <AddMode>
              <button type="button" onClick={showAdditionalChart}>
                Agregar indicador +
              </button>
            </AddMode>
          )}
        </Main>
        <Sidebar>
          <HowTo>
            Lista de las medidas contabilizadas, para la autoridad seleccionada.
            <span
              style={{ fontSize: "16px" }}
              role="img"
              aria-labelledby="down-pointing"
            >
              &#x1f447;
            </span>
          </HowTo>
          <Settings.Panel.Medidas>
            <h4>Medidas Contabilizadas</h4>
            <ul>{mostrarMedidas(medidasActivas)}</ul>
          </Settings.Panel.Medidas>
        </Sidebar>
      </>
    </Wrapper>
  );
};

export default DashboardContainerAutoridad;

import React from 'react';
import styled from 'styled-components';
import logos from 'img/logos_footer.png';
import title from 'img/LogoOCN_450footer.png';
import { Link } from "react-router-dom";
import { FECHA_ACTUALIZACION } from 'utils/constants';

const Wrapper = styled.div`
  margin-bottom: 0px;
  background-color: #009245;
  color: #e0e0e0;
  width: 100%
  h4{
    color: #d9d902;
  }
  a{
      color: #ffffff;
      text-decoration: none;
      font-size: 14px;
      @media (max-width: 720px) {
      font-size:calc(5px + 1vmin)
  }
  }
`;

const Table = styled.div`
  display: table;
  max-width: 1000px;
  padding:0px;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const TitleImg = styled.img`
  padding: 0px;
  margin-top: 20px;
  margin-right: 0px;

  @media (max-width: 720px) {
    width: 90%;
  }
`;

const Columnas = styled.div`

  ${props => props.table_img && 'display: table-cell'}
  ${props => props.table_img && 'width: 40%'} 
  ${props => props.table && 'display: table-cell'}
  ${props => props.table && 'width: 20%'} 
  margin-right:10px;
  margin-left:10px;
  margin-top:0px;
  padding:5px;
  align-items: center;
  text-align: left;
  font-size: 12px;
  justify-content: center;

  @media (max-width: 720px) {
    font-size: 8px;
    margin-right:5px;
    margin-left:5px;
    ${props => props.table_img && 'display: table-cell'}
    ${props => props.table_img && 'width: 30%'} 
    ${props => props.table && 'display: table-cell'}
    ${props => props.table && 'width: 15%'} 
  }
`;

const Fecha = styled.div`
  background-color: #009245;
  font-size:14px;
  color: #ffffff; 
  padding:5px;
  margin:0px;
  display:block;

  @media(max-width:720px){
    font-size:8px;
      }
`;
// Menu antes tenia la propiedad  //text-transform: uppercase;

const Footer = () => (
  <Wrapper>
  <Table>
    <Columnas table_img>
      <TitleImg text src={title} alt="Logo Observatorio"/>
      <TitleImg logo style={{"marginTop":"0px"}}src={logos} alt="Logo Observatorio"/>
    </Columnas>
    <Columnas table>
      <h4>Contenido</h4>
      <Link to="/inventario">Inventario</Link>
      <br/>
      <Link to="/porautoridad">Análisis por Autoridad Climática</Link>
      <br/>
      <Link to="/porsector">Análisis por Sector del INGEI</Link>
    </Columnas>
    <Columnas table>
    <h4>Iniciativas</h4>

    <a href="http://www.diplomacambioclimatico.cl/" target="_blank" rel="noopener noreferrer">
        Diplomado Cambio Climático
        </a>
    </Columnas>
    <Columnas table>
    <h4>Contacto</h4>
    <a href="http://centroenergia.cl/contacto/" target="_blank" rel="noopener noreferrer">
      Centro de Energía
    </a>
    </Columnas>
    <Columnas table>
    <h4>Acerca De</h4>
    <Link to="/noticias">Noticias</Link>
    <br />
    <Link to="/acercade">Información y agradecimientos</Link>
    </Columnas>
    </Table>
    <center>
    <Fecha>Fecha de última actualización: {FECHA_ACTUALIZACION}.</Fecha>
    </center>
    </Wrapper>
);

export default Footer;

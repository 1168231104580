import React, {useState,useEffect} from 'react';
import HelpContent from '../HelpContent';
import proyeccionParticipacion from 'img/medidas/meps nuevos.png'
import csvtojson from 'csvtojson/v2';
import axios from 'axios';
import { YEAR_CATEGORY } from 'utils/constants';
import WrapperExp from '../WrapperExp';
import Columnas from '../Columnas';
import { desviacion } from 'utils/helpers';
/**
 * Cargar los datos del gráfico
 */
 const getData = (datasetPath) => (
    axios.get(datasetPath)
      .then((response) => (
        csvtojson({ ignoreEmpty: true })
          // TODO: dynamic path depending on settings
  
          // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
          // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
          // esta solucion temporal
          .fromString(response.data.replace("a�o", YEAR_CATEGORY))
      ))
  );

const MepsNuevosHelp = ({ region }) => {
    const [dato_2030, set2030] = useState(0);
    const [dato_2050, set2050] = useState(0);

    //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
    const [policyInRegion, setPolicyInRegion] = useState(false);

    //DatasetPath que se cambia si es ficha regional
    const [datasetPath, setDatasetPath] = useState("./datasets/inventario/inventarioEmisionesOficialMedidas.csv")

    //Contiene el compromiso referencial y estado de implementacion de la region 
    const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

    //Aquí se deben ingresar los datos referenciales y el avance de la medida
    const cumplimientoMedidas = {
      nacional: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_arica_y_parinacota: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_tarapaca: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_antofagasta: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_atacama: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_coquimbo: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_valparaiso: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_metropolitana: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_ohiggins: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_del_maule: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_del_nuble: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_del_biobio: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_la_araucania: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_los_rios: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_los_lagos: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_aysen: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
      region_de_magallanes: {
        compromiso_referencial:{
          televisores: 50,
          lavavajillas: 32,
          Secadoras: 26,
          hornos: 6,
          microondas: 6,
        },
        estado_de_implementacion: {
          televisores: "Cumplido",
          lavavajillas: "No Cumplido",
          Secadoras: "No Cumplido",
          hornos: "No Cumplido",
          microondas: "No Cumplido",
        },
      },
    };
    
    useEffect(() => {
      //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
      if (region) {
        if (
          region.medidas.find((item) =>
            item.settings.hasOwnProperty("MEPS_Nuevos")
          )
        ) {
          setPolicyInRegion(true);
          setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
          const [key, value] = Object.entries(cumplimientoMedidas).find(
            ([key, value]) => key === region.name
          );
          setcumplimientoRegion(value);
        } else {
          setPolicyInRegion(false);
        }
      }
    }, [region]);
    getData(datasetPath)
      .then((dataset) => {
        set2030(dataset[40]["MEPS Nuevos"]);
        set2050(dataset[60]["MEPS Nuevos"]);
      })
      .catch((error) => {
        console.error(error);
      });

    return(
    <HelpContent>
        <div><h1>MEPS Nuevos</h1></div>
        <h4>Sector Comercial / Institucional y Residencial</h4>
        <p>
        <img src={proyeccionParticipacion} style={{float: "right", marginBottom:"2em", widht:"320px"}} alt="Proyección de participación de motores eficientes" />
        La medida considera la implementación de estándares de mínimos de eficiencia energética (MEPS por sus siglas en inglés) en diversos electrodomésticos.
        </p>
        <p>
        Considera la entrada de los siguientes artefactos en el escenario carbono neutral (existen otros artefactos con MEPS vigentes en escenario referencial):
        
        </p>
        <ul>
        <p>Etiquetado de televisores desde el 2024, logrando un ahorro por artefacto del 50%; </p>
        <p>Lavavajillas desde el 2025 logrando un ahorro por artefacto de 32%; </p>
        <p>Secadoras desde el 2026 logrando un ahorro por artefacto de 26%; </p>
        <p>Hornos eléctricos desde el 2027 logrando un ahorro por artefacto del 6% </p>
        <p>Microondas desde el 2027 logrando un ahorro por artefacto del 6%.</p>
        </ul>   

        {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>Etiquetado de televisores desde el 2024, logrando un ahorro por artefacto del <span>{cumplimientoRegion["compromiso_referencial"]["televisores"]}; </span></p>
                  <p>Lavavajillas desde el 2025 logrando un ahorro por artefacto de <span>{cumplimientoRegion["compromiso_referencial"]["lavavajillas"]}%; </span></p>
                  <p>Secadoras desde el 2026 logrando un ahorro por artefacto de <span>{cumplimientoRegion["compromiso_referencial"]["Secadoras"]}%; </span></p>
                  <p>Hornos eléctricos desde el 2027 logrando un ahorro por artefacto del <span>{cumplimientoRegion["compromiso_referencial"]["hornos"]}%; </span></p>
                  <p>Microondas desde el 2027 logrando un ahorro por artefacto del <span>{cumplimientoRegion["compromiso_referencial"]["microondas"]}% </span></p>
                </WrapperExp>
                <WrapperExp table>
        <h5 style={{fontWeight:800, fontSize:"16px"}}>Reducción de emisiones esperada: <br/></h5>
            <p>
                La reducción esperada de emisiones desde el 2020 (millón tCO<sub>2eq</sub>): <br/>
                <span>
                    0,1 (2030)  y  0,1  (2050)  </span>
            </p>
        </WrapperExp>
        </Columnas>
        <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>Etiquetado de televisores: <span>{cumplimientoRegion["estado_de_implementacion"]["televisores"]}</span></p>
                  <p>Etiquetado de lavavajillas: <span>{cumplimientoRegion["estado_de_implementacion"]["lavavajillas"]}</span></p>
                  <p>Etiquetado de secadoras: <span>{cumplimientoRegion["estado_de_implementacion"]["Secadoras"]}</span></p>
                  <p>Etiquetado de hornos: <span>{cumplimientoRegion["estado_de_implementacion"]["hornos"]}</span></p>
                  <p>Etiquetado de microondas: <span>{cumplimientoRegion["estado_de_implementacion"]["microondas"]}</span></p>
                  
                </WrapperExp>
              

              <WrapperExp>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                    La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                    <span>
                      {" "}
                      {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                      {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                    </span>
                  </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
        <Columnas>
        <WrapperExp table>
        <h5 style={{fontWeight:800, fontSize:"16px"}}>Compromiso referencial: <br/></h5>
        
        <p>Etiquetado de televisores desde el 2024, logrando un ahorro por artefacto del <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["televisores"]}%; </span></p>
        <p>Lavavajillas desde el 2025 logrando un ahorro por artefacto de <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["lavavajillas"]}%; </span></p>
        <p>Secadoras desde el 2026 logrando un ahorro por artefacto de <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["Secadoras"]}%; </span></p>
        <p>Hornos eléctricos desde el 2027 logrando un ahorro por artefacto del <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["hornos"]}%; </span></p>
        <p>Microondas desde el 2027 logrando un ahorro por artefacto del <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["microondas"]}% </span></p>
           
        </WrapperExp>
        

        <WrapperExp table>
        <h5 style={{fontWeight:800, fontSize:"16px"}}>Reducción de emisiones esperada: <br/></h5>
            <p>
                La reducción esperada de emisiones desde el 2020 (millón tCO<sub>2eq</sub>): <br/>
                <span>
                    0,1 (2030)  y  0,1  (2050)  </span>
            </p>
        </WrapperExp>
        </Columnas>
        <Columnas>
        {/* A continuacion se deben ingresar los valores referentes */}
        <WrapperExp table>
        <h5 style={{fontWeight:800, fontSize:"16px"}}>Estado de implementación de la medida: <br/></h5>
        <p>Etiquetado de televisores: <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["televisores"]}</span></p>
        <p>Etiquetado de lavavajillas: <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["lavavajillas"]}</span></p>
        <p>Etiquetado de secadoras: <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Secadoras"]}</span></p>
        <p>Etiquetado de hornos: <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["hornos"]}</span></p>
        <p>Etiquetado de microondas: <span>{cumplimientoMedidas["nacional"]["estado_de_implementacion"]["microondas"]}</span></p>

        </WrapperExp>

        <WrapperExp table>
        <h5 style={{fontWeight:800, fontSize:"16px"}}>Reducción de emisiones esperada por el Observatorio: <br/></h5>
        <p>
            La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br/>
            <span>
                {Number(dato_2030).toFixed(1).replace('.', ',')} (2030) y   {Number(dato_2050).toFixed(1).replace('.', ',')} (2050)  </span>
        </p>

        </WrapperExp>
        </Columnas>
        {desviacion(0.1,0.1,dato_2030, dato_2050)}
        </>)}
    </HelpContent>
)};

export default MepsNuevosHelp;
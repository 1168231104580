import React,  { useEffect, useState } from 'react';
import Header from 'components/layout/Header';
import NavHome from 'components/layout/NavHome';
import fotoFondo from 'img/header1.jpg'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { APOLICIES_FILE_PATH } from 'config';
import Loader from 'components/ui/Loader';
import DashboardContainerInventarioHome from 'containers/DashboardContainerInventarioHome';
import Footer from 'components/layout/Footer';
import InfoBanner from 'containers/BannerNoticias';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media(max-width:720px) {
    display: block;
  }
`;
const Sidebar = styled.div`
  overflow-x: hidden;
  width: 30%;
  max-width:300px
  min-width:200px;
  margin-top: 20px;
  margin-left: 80px;
  @media(max-width:720px) {
    width: 100%;
    max-width: 600px;
    margin-left: 0px;
  }
`;

const Main = styled.div`
  width: 50%;
  @media(max-width:720px) {
    width: 100%;
  }
`;

const FotoFondo = styled.img`
  width: 100%;
  height:100%;
`;

const Descriptor = styled.div`
  padding: 0 5px;
  background-color: #fff;
  margin-top:1%;
  align-items: center;
  justify-content: center;
  text-Align: justify;
  text-Justify: inter-word;
  display:block;
  @media(max-width: 720px){
    font-size:8px;
    margin-top:1%;
    margin-right:0%;
    margin-left:0%;
    margin-bottom:10px;
    max-width: 90%;
    max-height: auto;
  }
  `;

const Grafico = styled.div`
  padding: 5px;
  background-color: #fff;
  padding-bottom:0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  a {
    text-decoration: none;
    color: #1d1e21;
    @media (max-width: 720px) {
    font-size:calc(5px + 1vmin)
    text-align: right;
    justify-content: right
}
  }
@media(max-width: 720px){
  font-size:8px;
  margin:0.5px;
  display:block;
  padding: 5px;
  padding-bottom:0px;
  
}
`;

const Columnas = styled.div`
  font-size: 14px;
  border-radius: 4px;
  padding: 5px;
  height:100%
  background-color: #ffffff;
  text-Align: justify;
  text-Justify: inter-word
  h4 { 
    margin: 0 0 20px 0;
    color: #7b91b1;
  }
  span{
    align: center;
    font-size: 14px;
    justify: center;
    color: black;
    @media(max-width:720px) {
      font-size: 10px;
    }
  }
  .success {
    color: #0D9207;
  }
  .warning {
    color: #F92403;
  }
  img {
    display: block;
    margin: 20px auto 0;
  }
  b {
    font-size: 18px;
    font-weight: normal;
    display: inline-block;
    margin: 0 4px;
  }
  a {
    color: #009245;
    :hover {
      text-decoration: underline;
    }
  }
  @media(max-width:720px) {
    font-size: 8px;
    border-radius: 2px;
  }
`;

const Analisis = styled.div`
  display: table;
  width: 100%
`;

const Fact = styled.div`
  padding: 8px;
  margin: 3px;
  font-size: 12px;
  border-radius: 4px;
  max-width: 480px;
  max-height: 300px;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;

  ${props => props.table && 'display: table-cell'}
  ${props => props.table && 'width: 33%'}

  span{
    align: center;
    font-size:12px;
    justify: center;
    color: black;
    display: inline-table;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media(max-width:720px) {
    margin: 50px;
    font-size: 10px;
    border-radius: 2px;
    span{
      padding:5px;
      font-size:8px
    }

`;

const Title = styled.div`
  font-size:16px;
  font-weight:700;
  color: #009245;
  
  @media(max-width: 720px){
    font-size:12px;
    font-weight:700;
  }
`;

const Title2 = styled(Link)`
  font-size:14px;
  font-weight:700;
  color: #009245 !important;
  display:block;
  text-decoration: none;
  @media(max-width: 720px){
    font-size:10px;
    font-weight:700;
  }
`;

const Pulsador = styled(Link)`
  color:#ffffff !important;
  font-weight:700; 
  font-size:14px;
  background-color:#1bbf02;
  max-width: 40px;
  border-radius:4px;
  padding: 0 0.8rem;
  text-decoration: none;

  @media(max-width: 720px){
    font-size:9px;
    max-width:35px;
  }
`;

/**
 * Home.js
 * Componente que corresponde a la página de inicio de la aplicacion
 */
const Home = () => {

  const [policies, setPolicies] = useState(null);
  useEffect(() => {
    // Load scenarios json file
    axios.get(APOLICIES_FILE_PATH)
      .then(response => setPolicies(response.data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div >
      <Header />
      <NavHome />
      <FotoFondo src={fotoFondo} alt="fotodefondo" align="center"/>
      <br/>
      <Wrapper>
      <Main>
      <div>
      <center>
      <Descriptor>
      
      <Columnas>
        <Title>Presentación:</Title>
        <br/>
        <span>El “Observatorio de Carbono Neutralidad para Chile” es una iniciativa independiente de la Universidad de Chile que 
        tiene como objetivo principal monitorear el nivel de cumplimiento de la meta de carbono neutralidad propuesta por 
        Chile en su NDC (Nationally Determined Contribution). </span><br/>
        <br/>

        <span>Este desarrollo lleva a cabo una estimación actualizada, con no más de un año de desfase, del inventario de Gases de Efecto 
        Invernadero (GEI) del sector energía, del cumplimiento de los compromisos de reducción en materia de GEI del país y de los sectores productivos involucrados,
        tanto a nivel de presupuesto de emisiones (Carbon Budget) como de metas en emisiones. También analiza el cumplimiento de las medidas de mitigación y presupuestos
        sectoriales asignados a las Autoridades Climáticas respectivas.</span>
          <br/>
          <br/>
        <span>Las estimaciones de los valores actualizados del Inventario de Emisiones de Gases de Efecto Invernadero (INGEI) se basan en fuentes oficiales a nivel nacional. 
        Las estimaciones de reducciones de emisiones de GEI de las medidas de mitigación consideradas y de los escenarios formulados son modeladas usando la plataforma
        computacional <a href='https://modelopmr.cl/#/acercade' target="_blank" rel="noopener noreferrer">Modelo Energético PMR</a> e información actualizada respecto 
        a los sectores y tecnologías. Las metas se basan en los inputs actualizados del ejercicio de Carbono Neutralidad en Chile al 2050 de los Ministerios de Energía y del Medio Ambiente.</span>
      </Columnas>
          
      <Columnas>
        <Title>Principales análisis del Observatorio:</Title>      
        <br/>
        <Analisis>
        <Fact table>
          <Title2 to="/inventario"> Inventario </Title2>
          <span  >Revise los datos oficiales del INGEI de Chile 
          y la actualización del Observatorio para el sector energía. </span>
          <br/>
          <center>
            <Pulsador to="/inventario"> Ver </Pulsador>
          </center>
        </Fact>
        
        <Fact table>
          <Title2 to="/porautoridad"> Análisis por Autoridad Climática</Title2>
          <span>
          Revise el nivel de cumplimiento de las metas de reducción de las Autoridades Climáticas.</span>
          <br/>
          <center>
          <Pulsador to="/porautoridad"> Ver </Pulsador>
          </center>
        </Fact>
        <Fact table>
          <Title2 to="/porsector"> Análisis por sector del INGEI </Title2>
          <span >Revise los escenarios prospectivos de emisiones e impacto de medidas de mitigación desarrollados por el Observatorio para los sectores del INGEI. </span>
          <br/>
          <center>
            <Pulsador to="/porsector"> Ver </Pulsador>
          </center>
        </Fact>
        </Analisis>
        
      </Columnas>      
    </Descriptor>

    </center>
    </div>
    <center>
    
    
    <Descriptor style = {{padding:"0px"}}>
      <center>
      <Title>Inventario actualizado con sectores</Title>
      </center>
    <Grafico>
        {
          policies ? (
            <DashboardContainerInventarioHome policies={policies} inv={"inventario2"}/>
          ) : (
            <Loader />
          )
        }
    
      </Grafico>
      </Descriptor>
      </center>
      <br/>
      <br/>

      <center>
      <Descriptor style = {{padding:"0px"}}>
        <center>
        <Title>Inventario actualizado: proyección con sectores</Title>
        </center>
        <Grafico>
        {
          policies ? (
            <DashboardContainerInventarioHome policies={policies} inv={"inventario3"}/>
          ) : (
            <Loader />
          )
        }
        </Grafico>  
      </Descriptor>
      </center> 
    </Main>
    <Sidebar>
      <InfoBanner />
    </Sidebar>
    </Wrapper>
    <div>
    <br/>
    <center>
    <Footer/>
    </center>
    </div>
    </div>
    
  );
};

export default Home;
import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/sistemas solares termicos residencial y publico.gif";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const SistemasSolaresHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 128,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 2.6,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 3.3,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 3.5,
      },
    },
    region_de_atacama: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 5.8,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 8.3,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 13.7,
      },
    },
    region_metropolitana: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 38,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 4.1,
      },
    },
    region_del_maule: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 10.2,
      },
    },
    region_del_nuble: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 4.1,
      },
    },
    region_del_biobio: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 18.2,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 7.2,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 4,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 5.2,
      },
    },
    region_de_aysen: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: 7,
      estado_de_implementacion: {
        participacion: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Sistemas_Solares_Térmicos_Residencial_y_Publico"
          )
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Sistemas solares térmicos – residencial y público"]);
      set2050(dataset[60]["Sistemas solares térmicos – residencial y público"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Sistemas Solares Térmicos - Residencial y Público</h1>
      </div>
      <h4>Sector Comercial / Institucional y Residencial</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida tiene como objetivo el aprovechamiento del recurso solar
        para la generación de calor en viviendas para agua caliente sanitaria,
        desplazando el uso de combustibles y electricidad para estos fines.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    <span>{cumplimientoRegion["compromiso_referencial"]}</span>%
                    de participación de SST para uso de ACS en hogares al 2050
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones acumuladas a nivel nacional desde el 2020
                (millón tCO<sub>2eq</sub>): <br />
                <span>0,7 (2030) y 2,4 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Número de Viviendas declaradas con Instalaciones de
                    Colectores Solares Térmicos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["participacion"]
                      ).replace(".", ",")}
                    </span>{" "}
                    mil{" "}
                  </p>
                </WrapperExp>
              

              <WrapperExp>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]}
                </span>
                % de participación de SST para uso de ACS en hogares al 2050
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones acumuladas desde el 2020
                (millón tCO<sub>2eq</sub>): <br />
                <span>0,7 (2030) y 2,4 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Número de Viviendas declaradas con Instalaciones de Colectores
                Solares Térmicos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["participacion"]
                  ).replace(".", ",")}
                </span>{" "}
                mil{" "}
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones acumuladas (millón tCO<sub>2eq</sub>):{" "}
                <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.7, 2.4, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default SistemasSolaresHelp;

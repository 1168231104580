import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/sistemas solares termidos industria y mineria.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const SSTIndustriaMineriaHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0.004,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0.064,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        "industria%": 10,
        "mineria%": 16,
      },
      estado_de_implementacion: {
        "industria%": 0,
        "mineria%": 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("SST_Industria_y_Minería")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["SST Industria y minería"]);
      set2050(dataset[60]["SST Industria y minería"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>SST Industria y Minería</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em", width: "380 ptx"}}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida tiene como objetivo el uso de sistemas solares térmicos
        (SST) para procesos de calor/vapor tales como calefacción, calentamiento
        de fluidos, secado, entre otros.
      </p>
      <p>
        Se proyecta el reemplazo paulatino de tecnologías en base a combustibles
        fósiles por energía solar térmica a partir del año 2026, alcanzando una
        participación del 10% al 2050 en industrias varias y una participación
        del 16% en procesos de minería de cobre.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Participación de energía solar térmica a nivel nacional del{" "}
                    <span>
                      {cumplimientoRegion["compromiso_referencial"]["industria%"]}
                    </span>
                    % al año 2050 en industrias varias y del{" "}
                    <span>
                      {cumplimientoRegion["compromiso_referencial"]["mineria%"]}
                    </span>
                    % en procesos de minería de cobre.
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>1,3 (2030) y 3,7 (2050) </span>
              </p>
            </WrapperExp>
              </Columnas>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Participación de energía solar térmica a nivel regional en la industria
                    (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["industria%"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                  <p>
                    Participación de energía solar térmica a nivel regional en la minería
                    (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["mineria%"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
            </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Participación de energía solar térmica del{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["industria%"]}
                </span>
                % al año 2050 en industrias varias y del{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["mineria%"]}
                </span>
                % en procesos de minería de cobre.
              </p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>1,3 (2030) y 3,7 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Participación de energía solar térmica en la industria
                (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["industria%"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
              <p>
                Participación de energía solar térmica en la minería
                (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["mineria%"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(1.3, 3.7, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default SSTIndustriaMineriaHelp;

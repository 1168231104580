import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";
import CambioModalHelp from "components/help/helpMedidas/CambioModalHelp";
import TaxisElectricosHelp from "components/help/helpMedidas/TaxisElectricosHelp";
import VehiculosComercialesElectricosHelp from "components/help/helpMedidas/VehiculosComercialesElectricosHelp";
import TransporteCargaHidroHelp from "components/help/helpMedidas/TransporteCargaHidroHelp";
import TransporteRMHelp from "components/help/helpMedidas/TransporteRMHelp";
import TransporteRegionesHelp from "components/help/helpMedidas/TransporteRegionesHelp";
import VehiculosParticularesHelp from "components/help/helpMedidas/VehiculosParticularesHelp";
// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (medida === "Cambio modal transporte") {
    return <CambioModalHelp region={region} />;
  } else if (medida === "Taxis eléctricos") {
    return <TaxisElectricosHelp region={region} />;
  } else if (medida === "Vehículos comerciales eléctricos") {
    return <VehiculosComercialesElectricosHelp region={region} />;
  } else if (medida === "Transporte de carga - Hidrógeno") {
    return <TransporteCargaHidroHelp region={region} />;
  } else if (medida === "Buses Eléctricos en Transporte público RM") {
    return <TransporteRMHelp region={region} />;
  } else if (medida === "Buses Eléctricos en Transporte público regiones") {
    return <TransporteRegionesHelp region={region} />;
  } else if (medida === "Vehículos particulares eléctricos") {
    return <VehiculosParticularesHelp region={region} />;
  }
  return null;
};
// Crea un container con la lista de medidas que se puede seleccionar para sector de 'Transporte'
const TransportePolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="Transporte"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

TransportePolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default TransportePolicies;

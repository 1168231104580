import React from 'react';
import { func, object } from 'prop-types';
import BombasGeoHelp from 'components/help/helpMedidas/BombasGeoHelp';
import GeneracionBiogasHelp from 'components/help/helpMedidas/GeneracionBiogasHelp';
import UsoLodosHelp from 'components/help/helpMedidas/UsoLodosHelp';
import CalefaccionDistritalHelp from 'components/help/helpMedidas/CalefaccionDistritalHelp';
import CambioModalHelp from 'components/help/helpMedidas/CambioModalHelp';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "Bombas Geotérmicas de Calor"){
        return <BombasGeoHelp/>;
    }else if (medida === "Generación biogás"){
        return <GeneracionBiogasHelp/>;
    }else if(medida === "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal"){
        return <UsoLodosHelp/>;
    }else if(medida === "Calefacción distrital"){
        return <CalefaccionDistritalHelp/>;
    }else if(medida === "Cambio modal transporte"){
        return <CambioModalHelp/>;
    }
    return null;
}
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Obras Publicas
const MinisterioOpPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => {
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Obras Públicas'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
);
}

MinisterioOpPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
export default MinisterioOpPolicies;
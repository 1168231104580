import React from 'react';
import Header from 'components/layout/Header';
import Nav from 'components/layout/Nav';
import styled from 'styled-components';
import scotiabank from 'img/scotiabank_logo.png';
import Footer from 'components/layout/Footer';
const Wrapper = styled.div`
  background-color: white;	
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: justify;
  text-justify: inter-word;
  width: 800px;
  @media (max-width: 720px) {
    width: 95%;
    margin-left: 0px;
    margin-right: 0px;
    p{
      font-size: 10px;
    }
  }
`;
const Logo = styled.img`
  width: 180px;
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: 720px) {
    width: 80px;
  }
`;

/**
 * AcercaDe.js
 * Pagina estática que explica para que sirve la herramienta Observatorio.
 */
const AcercaDe = () => {

  return (
    <div> 
      <center>
      <Header />
      <Nav />
      <Wrapper>
       
	   <div>
	   <h2>Agradecimientos</h2>
       
		<p>
		El desarrollado de la plataforma Observatorio Carbono Neutralidad fue financiada por el fondo de
    investigación Net Zero Research Fund (NZRF). Esta iniciativa fue lanzada el año 2021 a nivel global
    por Scotiabank como parte de sus compromisos climáticos, mediante la cual se busca promover la
    investigación para transitar hacia una economía baja en emisiones de carbono. Para postular a
    este fondo de investigación la Universidad de Chile contó con el apoyo del Ministerio de Medio
    Ambiente de Chile y la Asociación de Generadoras de Chile.
    </p>
    <p>

    El Observatorio de Carbono Neutralidad es una iniciativa independiente de la
    Universidad de Chile que tiene como objetivo principal monitorear el nivel de cumplimiento de la
    meta de carbono neutralidad propuesta por Chile en su NDC. Los resultados y análisis publicados
    en esta plataforma son de exclusiva responsabilidad de los investigadores de la Universidad de
    Chile que participan de esta iniciativa.
    </p>
		
      <p style={{'margin-top':'50px'}}>Este proyecto está financiado por el fondo de investigación Net Zero Research Fund (NZRF) de
Scotiabank:</p>
      <center >
      <a href='https://www.scotiabankchile.cl/Personas/responsabilidad-social-empresarial/noticias/udechile-adjudica-net-zero-scotia' target="_blank" rel="noopener noreferrer">
      <Logo src={scotiabank} alt="Logo Scotiabank"/>
      </a>
    </center>
		</div>
      </Wrapper>
    <Footer/></center>
    </div>
  );
};

export default AcercaDe;
import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/electromovilidad transporte publico regiones.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const TransporteRegionesHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState("./datasets/inventario/inventarioEmisionesOficialMedidas.csv");

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.2,
        porcentaje_urbano: 0.3,
        porcentaje_inter: 0,
        parque_urbano: 131,
        parque_inter: 0,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.2,
        porcentaje_urbano: 1.9,
        porcentaje_inter: 0,
        parque_urbano: 44,
        parque_inter: 0,
      },
    },
    region_de_atacama: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.2,
        porcentaje_urbano: 0.2,
        porcentaje_inter: 0,
        parque_urbano: 2,
        parque_inter: 0,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.0,
        porcentaje_urbano: 0.1,
        porcentaje_inter: 0,
        parque_urbano: 2,
        parque_inter: 0,
      },
    },
    region_metropolitana: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 1.5,
        porcentaje_urbano: 2.1,
        porcentaje_inter: 0,
        parque_urbano: 53,
        parque_inter: 0,
      },
    },
    region_del_maule: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.2,
        porcentaje_urbano: 0.3,
        porcentaje_inter: 0,
        parque_urbano: 8,
        parque_inter: 0,
      },
    },
    region_del_nuble: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0.3,
        porcentaje_urbano: 0.4,
        porcentaje_inter: 0,
        parque_urbano: 19,
        parque_inter: 0,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_aysen: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: 100,
      estado_de_implementacion: {
        porcentaje_total: 0,
        porcentaje_urbano: 0,
        porcentaje_inter: 0,
        parque_urbano: 0,
        parque_inter: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Transporte_Público_Regiones")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Buses Eléctricos en Transporte público regiones"]);
      set2050(dataset[60]["Buses Eléctricos en Transporte público regiones"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Buses Eléctricos en Transporte Público - Regiones</h1>
      </div>
      <h4>Sector Transporte</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera una penetración del 100% de buses eléctricos en
        regiones al 2040.
      </p>
      <p>
        Se considera una penetración del 100% de buses eléctricos en regiones al
        2040, equivalente a una flota de 96.000 buses y la entrada de nuevas
        máquinas, con una vida útil de 10 años para buses diésel y 14 años para
        buses eléctricos
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    <span>{cumplimientoRegion["compromiso_referencial"]}</span>%
                    de buses eléctricos en regiones al año 2040 (flota de 96.000
                    buses)
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,4 (2030) y 3,3 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Participación total de buses eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje_total"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>

                  <p>
                    Participación de buses urbanos eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje_urbano"]
                      ).replace(".", ",")}
                    </span>
                    %<br />
                    Participación de buses interurbanos eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje_inter"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>

                  <p>
                    Parque de buses urbanos eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {
                        cumplimientoRegion["estado_de_implementacion"]["parque_urbano"]
                      }
                    </span>
                    <br /> Parque de buses interurbanos eléctricos a nivel regional (diciembre 2022):{" "}
                    <span>
                      {
                        cumplimientoRegion["estado_de_implementacion"]["parque_inter"]
                      }
                    </span>
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]}
                </span>
                % de buses eléctricos en regiones al año 2040 (flota de 96.000
                buses)
              </p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,4 (2030) y 3,3 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>
          {/* A continuacion se deben ingresar los valores referentes */}

          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Participación total de buses eléctricos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_total"]
                  ).replace(".", ",")}
                </span>
                %
              </p>

              <p>
                Participación de buses urbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_urbano"]
                  ).replace(".", ",")}
                </span>
                %<br />
                Participación de buses interurbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_inter"]
                  ).replace(".", ",")}
                </span>
                %
              </p>

              <p>
                Parque de buses urbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque_urbano"]
                  }
                </span>
                <br /> Parque de buses interurbanos eléctricos (diciembre 2022):{" "}
                <span>
                  {
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["parque_inter"]
                  }
                </span>
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.4, 3.3, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default TransporteRegionesHelp;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/Cambio modal en transporte.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import { desviacion } from "utils/helpers";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const CambioModalHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        DisminucionAnual: 0.2,
        bicicletas: 60,
        buses: 40,
      },
      estado_de_implementacion: {
        Transacciones2020: -62,
        Transacciones2021: 29,
        Porcentaje: 0.6,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Cambio_Modal_Transporte")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Cambio modal transporte"]);
      set2050(dataset[60]["Cambio modal transporte"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Cambio Modal Transporte</h1>
      </div>
      <h4>Sector Transporte</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera la operación de un programa de cambio modal con el
        objetivo de lograr la disminución del uso de transporte privado
        motorizado, con el objetivo de fomentar el uso de bicicletas y
        transporte público (específicamente buses).
      </p>
      <p>
        La medida considera que el programa de cambio modal se inicia el año
        2020 y finaliza el 2025, proyectando una disminución anual de 0,2% de la
        partición modal asignada a transporte particular privado motorizado, la
        que es distribuida en promedio en un 40% en el modo bicicleta y 60% en
        modo buses.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Disminución anual de{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["DisminucionAnual"]
                      ).replace(".", ",")}
                    </span>
                    % de partición modal de transporte particular privado
                    motorizado entre los años 2020 y 2025 hacia bicicleta y
                    buses a nivel nacional, repartidos en un{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["bicicletas"]
                      ).replace(".", ",")}
                    </span>
                    % y{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["buses"]
                      ).replace(".", ",")}
                    </span>
                    %, respectivamente.
                  </p>
                  <p>
                    Intervención de <span>1.000</span> km de vías para
                    construcción de ciclovías y otros proyectos complementarios.
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,1 (2030) y 0,1 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Transacciones anuales buses en Santiago:{" "}
                    <span>
                      789
                    </span>
                   , <span>
                      296
                    </span>
                    , <span>
                      381
                    </span>
                    , <span>
                      497 
                    </span> millones en años 2019, 2020, 2021 y 2022, respectivamente
                  </p>
                  
                </WrapperExp>
              
              <WrapperExp notTable>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp notTable>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Disminución anual de{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["DisminucionAnual"]
                  ).replace(".", ",")}
                </span>
                % de partición modal de transporte particular privado motorizado
                entre los años 2020 y 2025 hacia bicicleta y buses, repartidos
                en un{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["bicicletas"]
                  ).replace(".", ",")}
                </span>
                % y{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["buses"]
                  ).replace(".", ",")}
                </span>
                %, respectivamente.
              </p>
              <p>
                Intervención de <span>1.000</span> km de vías para construcción
                de ciclovías y otros proyectos complementarios.
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,1 (2030) y 0,1 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Transacciones anuales buses:{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Transacciones2020"]
                  ).replace(".", ",")}
                </span>
                % en año 2020, respecto al año 2019
              </p>
              <p>
                Transacciones anuales buses:{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Transacciones2021"]
                  ).replace(".", ",")}
                </span>
                % en año 2021, respecto al año 2020
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.1, 0.1, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default CambioModalHelp;

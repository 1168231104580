import React from 'react';
import { Page, Font, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';

import logoCentro from 'img/logo-centro-energia.png';
import Title from './Title';
import SubTitle from './SubTitle';
import KPIPdf from './KPIPdf'
import robotoBold from './fonts/Roboto-Bold.ttf'
import robotoRegular from './fonts/Roboto-Regular.ttf'
import robotoItalic from './fonts/Roboto-Italic.ttf'
// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 12,
        paddingTop: 30,
        paddingLeft: 60,
        fontFamily: 'Roboto',
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        marginTop: '10',
        marginLeft: '30',
        marginRight: 'auto'
    },
    head: {
      // width: 74,
      marginTop: '0',
      marginRight: '20',
      marginLeft: 'auto'
    },
    bulletPoint: {
      width: 10,
      fontSize: 10,
    },
    chart : {
      paddingTop: '10',
      paddingBottom: '20'
    },
    subtitle_separator : {
      paddingTop: '20',
    },
    policies: {
      paddingTop: 7,
      paddingLeft: 15,
    },
    nada: {
      paddingTop: 2,
      paddingLeft: 15,
      fontStyle: 'italic'
    }

  });

  Font.register({
    family: "Roboto",
    fonts: [
      {src: robotoRegular, fontWeight: '400'},
      {src: robotoBold, fontStyle: 'bold'},
      {src: robotoItalic, fontStyle: 'italic'}
    ]
  });



// Create Document Component
const PdfGenerator = ({
    graphImgs,
    datasetPath,
    kpis,
    year,
    settings,
    escenario
  }) => {
  let policies = []
  let instrumentos = []
  let title = ''
  if(escenario){
    title = escenario.name
  }
  
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <Image style={styles.logo} src={logoCentro} wrap={true}/>
        <Title title={"Reporte " + title}/>
        <View wrapper={false}>
          <SubTitle title={"Instrumentos Económicos"}> </SubTitle>
          {instrumentos.length > 0 ?
          instrumentos.map((item, index) => 
            <Text key={"instrument-" + index} style={styles.policies}>   <Text style={styles.bulletPoint}>•</Text> <Text style={{fontStyle: 'bold'}}>{item.label}</Text> {item.trayectoria ? ": trayectoria " + item.trayectoria : ": trayectoria 1"}
            </Text>)
          : <Text style={styles.nada}> No hay instrumentos económicos seleccionados.</Text>}
        </View>
        <View wrapper={false}>
          <SubTitle title={"Medidas de mitigación"}> </SubTitle>
          {policies.length > 0 ?
            [(<Text key="sub" style={styles.nada}> Este escenario incluye las siguientes medidas de mitigación:  </Text>),
            (policies.map((item, index) => 
            <Text key={"policie-" + index} style={styles.policies}>   <Text style={styles.bulletPoint}>•</Text> <Text style={{fontStyle: 'bold'}}>{item.label}</Text> {item.trayectoria ? ": trayectoria " + item.trayectoria : ": trayectoria 1"}
            </Text>))] : <Text style={styles.nada}> No hay políticas energéticas seleccionadas.</Text>}
        </View>
      </Page>
      { graphImgs.map((graphImg, index) => {
        
        return (<Page key={"graph" + index} size="A4" style={styles.page} wrap={true}>
        <Image style={styles.logo} src={logoCentro} fixed={true}/>
        <Title title={settings[index].label}/>
        <View wrapper={false}>
          <Text> La siguiente figura muestra la proyeccion de {settings[index].name}</Text>
          <Image src={graphImg.toDataURL()} style={styles.chart} />
        </View>
      {escenario.name === "Escenario Base"   ? null :
      <View >
      <KPIPdf
        key="kpi-pdf"
        currentScenario={escenario}
        base={kpis[index][0]}
        mode={settings[index].id}
        newValue={kpis[index][1]}
        año={year}
        />
      </View>}
        </Page>)

      }
    )}
        

      
    </Document>
)};

export default PdfGenerator;
import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Dashboard from "components/pages/Dashboard";
import Home from "components/pages/Home";
import AcercaDe from "components/pages/AcercaDe";
import defaultTheme from "utils/theming";
import { Route, Switch, HashRouter } from "react-router-dom";
import DashboardAutoridad from "components/pages/DashboardAutoridad";
import DashboardInventario from "components/pages/DashboardInventario";
import PostNoticia from "components/pages/PostNoticia";
import MenuRegiones from "components/pages/MenuRegiones";
import DashboarRegiones from "components/pages/DashboardRegiones";
import Noticias from "components/pages/Noticias";
const theme = createTheme(defaultTheme);

/**
 * Principal contenedor de la aplicación.
 * Utiliza react router para la configuración de las rutas
 */
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <HashRouter>
        <Switch>
          <Route path="/acercade">
            <AcercaDe />
          </Route>
          <Route path="/porsector">
            <Dashboard />
          </Route>
          <Route path="/inventario">
            <DashboardInventario />
          </Route>
          <Route path="/porautoridad">
            <DashboardAutoridad />
          </Route>
          <Route exact path="/porregion">
            <MenuRegiones />
          </Route>
          <Route path="/porregion/:region">
            <DashboarRegiones />
          </Route>
          <Route exact path="/noticias">
            <Noticias />
          </Route>
          <Route path="/noticias/:slug">
            <PostNoticia />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;

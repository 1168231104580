import styled from 'styled-components';

export default styled.div`
  padding: 20px;
  min-height: 800px;
  background-color: #ffffff;
  h1 {
    margin-top: 0;
    text-align: center;
    width: 100%;
    background-color: #009245;
    color: #e0e0e0;
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }

  h3 {
    margin-top: 0px;
    fontWeight: 600;
    @media (max-width: 720px) {
      font-size: 14px;
    }
  }

  h4{
    color: #009245;
  }


  p{
    align-items: center;
    text-align: justify;
    font-size: 12px;
    justify-content: center;
    @media (max-width: 720px) {
      font-size: 13px;
    }
  }

  img {
    display: block;
    margin: 0 0 0 20px;
    height: auto;
    max-height: 250px;
    max-width: 550px;
    @media (max-width: 720px) {
      display: block;
      max-width: 100%;
      height: auto;
  
  }
  }

  label {
    font-style: italic;
    font-size: 11px;
    display: block;
    margin: 10px auto 40px auto;
    width: 100%;
    text-align: center;
    color: navy;
  }
  span {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    fontSize:18px;
    margin-left: 2px;
    
  }
  h5{
      text-align: center;
      align-items: center;
      justify-content: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      fontSize:18px;
      margin-left: 2px;
      color: #009245;
    }
`;

import BigNumber from "./layout/bigNumber";
import Fact from "./layout/fact";
import React from "react";
import { PresupuestoAutoridades, ReduccionesEsperadas } from "utils/constants";
import Columnas from "components/help/Columnas";

const Autoridad = ({ base, newValue, año, kpiDict, selected }) => {
  let reducciones =
    kpiDict["tco2_entre_2020_2030_ref"] - kpiDict["tco2_entre_2020_2030"];
  return (
    <div>
      <Columnas>
        <Fact table>
          <h4>Presupuesto para {selected} es </h4>
          <BigNumber>
            <h1>
              {PresupuestoAutoridades[selected].toFixed(1).replace(".", ",")}
            </h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>

        <Fact table>
          <h4>
            Reducciones esperadas producto de medidas del {selected} entre 2020
            y 2030 son
          </h4>
          <BigNumber>
            <h1>
              {ReduccionesEsperadas[selected].toFixed(1).replace(".", ",")}
            </h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>
      </Columnas>

      <Columnas>
        <Fact table>
          <h4>
            Porcentaje de Carbon Budget del {selected} utilizado entre 2020 y
            2030
          </h4>
          <BigNumber>
            {kpiDict["co2_entre_2020_2030_autoridad"] - reducciones >
            PresupuestoAutoridades[selected] ? (
              <h1 className="warning">
                {(
                  ((kpiDict["co2_entre_2020_2030_autoridad"] - reducciones) *
                    100) /
                  PresupuestoAutoridades[selected]
                )
                  .toFixed(1)
                  .replace(".", ",")}
                %{" "}
              </h1>
            ) : (
              <h1 className="success">
                {(
                  ((kpiDict["co2_entre_2020_2030_autoridad"] - reducciones) *
                    100) /
                  PresupuestoAutoridades[selected]
                )
                  .toFixed(1)
                  .replace(".", ",")}
                %{" "}
              </h1>
            )}
          </BigNumber>
        </Fact>

        <Fact table>
          <h4>
            Reducciones proyectadas producto de medidas del {selected} entre
            2020 y 2030 son
          </h4>
          <BigNumber>
            {(
              kpiDict["tco2_entre_2020_2030_ref"] -
              kpiDict["tco2_entre_2020_2030"]
            ).toFixed(1) < ReduccionesEsperadas[selected] ? (
              <h1 className="warning">
                {" "}
                {(
                  kpiDict["tco2_entre_2020_2030_ref"] -
                  kpiDict["tco2_entre_2020_2030"]
                )
                  .toFixed(1)
                  .replace(".", ",")}
              </h1>
            ) : (
              <h1 className="success">
                {" "}
                {(
                  kpiDict["tco2_entre_2020_2030_ref"] -
                  kpiDict["tco2_entre_2020_2030"]
                )
                  .toFixed(1)
                  .replace(".", ",")}
              </h1>
            )}

            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>
      </Columnas>

      <Columnas>
        <Fact notTable>
          <h4>
            Emisiones de los sectores a cargo del {selected} entre 2020 y 2030
          </h4>
          <BigNumber>
            {kpiDict["co2_entre_2020_2030_autoridad"] - reducciones >
            PresupuestoAutoridades[selected] ? (
              <h1 className="warning">
                {(kpiDict["co2_entre_2020_2030_autoridad"] - reducciones)
                  .toFixed(1)
                  .replace(".", ",")}{" "}
              </h1>
            ) : (
              <h1 className="success">
                {(kpiDict["co2_entre_2020_2030_autoridad"] - reducciones)
                  .toFixed(1)
                  .replace(".", ",")}{" "}
              </h1>
            )}
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>
      </Columnas>
    </div>
  );
};

export default Autoridad;

import React, { useState } from "react";
import styled from "styled-components";
// import ModeSelection from './ModeSelection';
import Grafico from "./Chart";
import { inventoryChartSettings2 } from "config";
import { MEDIDAS, MIN_YEAR } from "utils/constants";
import MoreInfoHome from "components/ui/MoreInfoHome";

const Wrapper = styled.div`
  position: relative;
  ${(props) => props.index > 0 && "margin-top: 10px;"}
`;

const ChartModeHome2 = ({
  index,
  onHide,
  currentScenario,
  settings,
  medidasActivas,
  type,
}) => {
  // que reciba tambien si es Sector o autoridad, para hacer la bajada a los kpi y determinar contribucion
  // Modo del gráfico: Por ejemplo: Emisiones, generacion etc,.
  const mode2 = inventoryChartSettings2[1].id;
  const min_max2 = [MIN_YEAR, 2050];
  const [years2, setYears2] = useState([MIN_YEAR, 2050]);
  // Multiple or single scenario
  const multipleScenarios2 = false;

  // Obtener opciones de configuración del gráfico actual
  const currentChartSettings2 = inventoryChartSettings2.find(
    (item) => item.id === mode2
  );

  // get dataset path based on current scenario
  const datasetPath = currentScenario ? currentScenario.datasets[mode2] : null;
  const noUsadas = (array, cate) => {
    for (let i in array) {
      if (array[i] === cate) {
        return false;
      }
    }
    return true;
  };
  let medidasNoUsadas = MEDIDAS.filter((item) =>
    noUsadas(medidasActivas, item)
  );

  return (
    <Wrapper index={index}>
      {currentChartSettings2.description && (
        <MoreInfoHome label="Más información" option={true} tipo={2}>
          {currentChartSettings2.description}
        </MoreInfoHome>
      )}
      {multipleScenarios2 ? (
        <Grafico
          id={`multiple_scenario_container` + index}
          mode={mode2}
          currentScenario={currentScenario}
          datasetPath={`/datasets/${mode2}/Escenarios.csv`}
          chartSettings={currentChartSettings2}
          isMultiscenario
          settings={settings}
          years={years2}
          min_year={min_max2[0]}
          max_year={min_max2[1]}
          setYears={setYears2}
          medidasActivas={medidasNoUsadas}
          selected={"home"}
        />
      ) : (
        <Grafico
          id={`chart_container` + index}
          mode={mode2}
          currentScenario={currentScenario}
          datasetPath={datasetPath}
          chartSettings={currentChartSettings2}
          kpiMode={currentChartSettings2 ? "nacional" : null}
          allowDownloads
          settings={settings}
          years={years2}
          min_year={min_max2[0]}
          max_year={min_max2[1]}
          setYears={setYears2}
          medidasActivas={medidasNoUsadas}
          selected={"home"}
        />
      )}
    </Wrapper>
  );
};

export default ChartModeHome2;

import React,  { useState } from 'react';
import styled from 'styled-components';
import { YEAR_INVENTORY, YEAR_KPI_HOME_1, YEAR_KPI_HOME_2, YEAR_UPDATE } from 'utils/constants';
import csvtojson from 'csvtojson/v2';
import axios from 'axios';
import { YEAR_CATEGORY } from 'utils/constants';
import { parseValue } from 'utils/helpers';
import BigNumber from 'containers/kpis/layout/bigNumber';
import Fact from 'containers/kpis/layout/fact';
import Columnas from 'components/help/Columnas';

const InfoHome = styled.div`
  border-radius: 3px;
  padding:5px;
  font-Size: 12px;

  @media(max-width: 720px){
    font-size:10px
  }
`;

const Title = styled.div`
  font-size:12px; 
  color: #009245;
  font-weight:700;
`

/**
 * Cargar los datos del gráfico
 */
 const getData = (datasetPath) => (
  axios.get(datasetPath)
    .then((response) => (
      csvtojson({ ignoreEmpty: true })
        // TODO: dynamic path depending on settings

        // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
        // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
        // esta solucion temporal
        .fromString(response.data.replace("a�o", YEAR_CATEGORY))
    ))
);


const info = (tipo, setupdate, setproyeccion, 
  setacumulado, setoficial, dato_acumulado, dato_oficial, dato_proyeccion, dato_update) => {

  if (tipo === 1){
    getData('./datasets/inventario/inventarioEmisiones_actualizado.csv').then(dataset => {
      let sum = 0
      for (var key in dataset[YEAR_UPDATE-1990]) {
        if (key !== "año"){
          sum+= parseValue(dataset[YEAR_UPDATE-1990][key])
        }
      }
      setupdate(sum.toFixed(1).replace('.',','))

      let sum2 = 0

      for (var key in dataset[YEAR_INVENTORY-1990]) {
        if (key !== "año"){
          sum2+= parseValue(dataset[YEAR_INVENTORY-1990][key])
        }
      }
      setoficial((sum - sum2).toFixed(1).replace('.',','))
      }).catch(error => {
        console.error(error);
      });
    return (
      <>
      <Columnas>
          <Fact notTable>
          <h3>El gráfico muestra el inventario oficial para el periodo 1990-2020.</h3>
          <h3>Las estimaciones se calculan para el año 2021, 2022 y 2023.</h3>
          </Fact>
          </Columnas>
      <Columnas>
        <Fact table>
          <h3>Emisiones estimadas al año {YEAR_UPDATE}</h3> 
          <BigNumber>
            <h1>{ dato_update }</h1>
            <label>millones tCO<sub>2eq</sub>.</label>
          </BigNumber>
        </Fact>
        <Fact table>
          <h3>
            Variación respecto a valor oficial {YEAR_INVENTORY}
          </h3>
          <BigNumber>
            <h1>{dato_oficial}</h1>
            <label>millones tCO<sub>2eq</sub>.</label>
          </BigNumber>
      </Fact>
      </Columnas>
    </>)
  } else {
    getData('./datasets/inventario/inventarioEmisionesOficial.csv').then(dataset => {
      let sum = 0
      for (var key in dataset[YEAR_KPI_HOME_1-1990]) {
        if (key !== "año" && key !== "Proyeccion NDC oficial" && key !== "Proyeccion Observatorio"){
          sum+= parseValue(dataset[YEAR_KPI_HOME_1-1990][key])
        }
      }
      setupdate(sum.toFixed(1).replace('.',','))

      sum = 0

      for (var key in dataset[YEAR_KPI_HOME_2-1990]) {
        if (key !== "año" && key !== "Proyeccion NDC oficial" && key !== "Proyeccion Observatorio"){
          sum+= parseValue(dataset[YEAR_KPI_HOME_2-1990][key])
        }
      }
      setproyeccion((sum).toFixed(1).replace('.',','))

      sum = 0
      for (let i = (2020-1990); i <=(2030-1990); i++){
        for (var key in dataset[i]) {
          if (key !== "año" && key !== "Proyeccion NDC oficial" && key !== "Proyeccion Observatorio"){
            sum+= parseValue(dataset[i][key])
          }
        }
      }

      setacumulado((sum).toFixed(1).replace('.',','))
      }).catch(error => {
        console.error(error);
      });
      
      return (
      <>
      <Columnas>
          <Fact notTable>
          <h3>El gráfico muestra el inventario oficial para el periodo 1990-2020.</h3>
          <h3>Las estimaciones se calculan para el año 2021, 2022 y 2023.</h3>
          <h3>Las emisiones se proyectan para el periodo 2024-2050.</h3>
          </Fact>
          </Columnas>
      <Columnas>
        <Fact table>
          <h3>Emisiones estimadas al año {YEAR_KPI_HOME_1}</h3> 
          <BigNumber>
            <h1>{dato_update}</h1>
            <label>millones tCO<sub>2eq</sub>.</label>
            {/* <label>Rango de incertidumbre: &#177;{incertidumbre_grafico_2}%</label> */}
            <h3>Se superaría el límite de 95 millones tCO<sub>2eq</sub> en un 7%.</h3>
          </BigNumber>
        </Fact>
        <Fact table>
          <h3>Emisiones estimadas al año {YEAR_KPI_HOME_2}</h3> 
          <BigNumber>
            <h1>{dato_proyeccion}</h1>
            <label>millones tCO<sub>2eq</sub>.</label>
            <h3>No se alcanzaría la Carbono Neutralidad.</h3>
            {/* <label>Rango de incertidumbre: &#177;{incertidumbre_grafico_3}%</label> */}
          </BigNumber>
        </Fact>
      </Columnas>
      <Columnas>
        <Fact notTable>
          <h3>Proyección de emisiones acumuladas entre 2020 y 2030</h3>
          <BigNumber>
            <h1>{dato_acumulado}</h1>
            <label>millones tCO<sub>2eq</sub>.</label>
            <h3>Se superaría el presupuesto de 1100 millones tCO<sub>2eq</sub> en un 5%.</h3>
          </BigNumber>
        </Fact>
      </Columnas>
      </>
    )
  }
}

const MoreInfoHome = ({ label, children, option, tipo}) => {

  const [dato_update, setupdate] = useState(0);
  const [dato_oficial, setoficial] = useState(0);
  const [dato_proyeccion, setproyeccion] = useState(0);
  const [dato_acumulado, setacumulado] = useState(0);


  return (
    <div>
    <div style={{"alignItems": "center",
    "justifyContent": "center",
    "textAlign": "center",
    display: "flex"}}> 
          <InfoHome>
            { children }
          </InfoHome>
      </div>
         <center>
          <br/>
          <Title>Principales Indicadores</Title>
          {info(tipo, setupdate, setproyeccion, 
                setacumulado, setoficial, dato_acumulado, dato_oficial, dato_proyeccion, dato_update)}
        </center>
        
    
    </div>
  );
};

export default MoreInfoHome;

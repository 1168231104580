import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
`;

const Menu = styled.div`
  background-color: #009245;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  min-height: 50px;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 20px;
    font-weight: bold;
    font-size: 14px;
    margin-right: 2%;
    margin-left: 2%;
    @media (max-width: 720px) {
      display: inline-block;
      font-size: 8px;
    }
    a {
      color: #ffffff;
      text-decoration: none;
      :hover {
        color: #2f6594;
      @media (max-width: 720px) {
        font-size: 8px;
      }
    }
  }
`;
const MenuItem = styled(Link)`
  color: #ffffff;
  :hover {
    color: #2f6594;
  }
`;

const NavRegion = () => (
  <Wrapper>
    <Menu>
      <li>
        <MenuItem to="/">Inicio</MenuItem>
      </li>
      <li>
        <MenuItem to="/inventario">Inventario</MenuItem>
      </li>
      <li>
        <MenuItem to="/porautoridad"> Análisis por Autoridad Climática</MenuItem>
      </li>
      <li>
        <MenuItem to="/porsector">Análisis por Sector del INGEI</MenuItem>
      </li>
      <li>
        <MenuItem to="/porregion">
          <span style={{ color: "#2f6594" }}>Análisis por Región</span>
        </MenuItem>
      </li>
      <li>
        <a
          href="http://centroenergia.cl/contacto/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contacto
        </a>
      </li>
    </Menu>
  </Wrapper>
);

export default NavRegion;

import React, { useState } from "react";
import styled from "styled-components";
import ScenarioSelector from "./ScenarioSelector";
import ChartMode from "./ChartMode";
import Settings from "components/ui/Settings";
import TransportePolicies from "./Sectores/medidasTransporte";
import EnergiaPolicies from "./Sectores/medidasEnergia";
import OtrosSectoresPolicies from "./Sectores/medidasOtrosSectores";
import IndustriasPolicies from "./Sectores/medidasIndustrias";
import AgriculturaPolicies from "./Sectores/medidasAgricultura";
import ResiduosPolicies from "./Sectores/medidasResiduos";
import ForestalPolicies from "./Sectores/medidasForestal";
import { COLORS, MEDIDAS } from "utils/constants";
import Color from "color";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  @media (max-width: 720px) {
    display: block;
  }
`;
const Sidebar = styled.div`
  width: ${(props) => (props.narrow ? "360px" : "420px")};
  margin-right: 10px;
  margin-left: 5px;
  overflow-x: hidden;

  @media (max-width: 720px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

const Main = styled.div`
  width: 100%;
`;

const AddMode = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 10px;
    appearance: none;
    float: right;
    margin: 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    background-color: var(--light-grey-color);
    color: white;
    cursor: pointer;
  }
`;

const HowTo = styled.div`
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  text-align: justify;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;
`;

const Icon = styled.i`
  cursor: pointer;
  position: relative;
  right: 10px;
  left: 3px;
  color: #333;

  &.fa-chevron-up,
  &.fa-chevron-down {
    position: relative;
    right: 15px;
  }
`;

/**
 *  Obtenener el color de fondo de usa serie
 *  basado en el color de la serie
 */
const getBg = (code) => {
  if (code === "rgba(0, 0, 0, 1)") {
    return "rgba(0, 0, 0, 0.5)";
  }
  const color = Color(code);
  return color.alpha(0.4).rgb().string();
};

const DashboardContainer = ({ policies }) => {
  // Opciones del menú izquierdo, por ejemplo: Instrumentos económicos
  const [medidaSettings, setMedidaSettings] = useState(
    policies.find((item) => item.name === "Seleccionar Sector").settings
  );

  // Obtenemos la ruta del set de datos, basado en la configuración de las medidas.
  const [currentSector, setCurrentSector] = useState(
    policies.find((item) => item.name === "Seleccionar Sector")
  );

  // const [medidas, setMedidas] = useState(policies.find(item => item.name === "Transporte").medidas)
  // setup visible charts
  const [visibleCharts, setVisibleCharts] = useState([true]);

  // whether or not we're alread showing all the possible charts
  const amountVisible = visibleCharts.filter((item) => item).length;
  const [medidasActivas, setMedidasActivas] = useState([]);
  const selectSectorMedidas = (currentSector, settings, medidasActivas) => {
    const howToSectorINGEI = (
      <HowTo>
        Al seleccionar una medida de este listado, se mostrará en el gráfico el
        efecto de reducción en los gases de efecto invernadero
        <span
          style={{ fontSize: "16px" }}
          role="img"
          aria-labelledby="down-pointing"
        >
          &#x1f447;
        </span>{" "}
        <br />
        Para obtener mayor información respecto a una medida especifica presione
        el ícono <Icon className="fas fa-question-circle" />
      </HowTo>
    );
    if (currentSector.name === "Transporte") {
      return (
        <div>
          {howToSectorINGEI}
          <TransportePolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentSector.name === "Generación de electricidad") {
      return (
        <div>
          {howToSectorINGEI}
          <EnergiaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentSector.name === "Otros Sectores: Comercial, Público y Residencial"
    ) {
      return (
        <div>
          {howToSectorINGEI}
          <OtrosSectoresPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (
      currentSector.name === "Industrias manufactureras y de la construcción"
    ) {
      return (
        <div>
          {howToSectorINGEI}
          <IndustriasPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentSector.name === "Agricultura") {
      return (
        <div>
          {howToSectorINGEI}
          <AgriculturaPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentSector.name === "Residuos") {
      return (
        <div>
          {howToSectorINGEI}
          <ResiduosPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    } else if (currentSector.name === "Forestal") {
      return (
        <div>
          {howToSectorINGEI}
          <ForestalPolicies
            settings={settings}
            onChange={setMedidaSettings}
            medidasActivas={medidasActivas}
            setMedidasActivas={setMedidasActivas}
          />
        </div>
      );
    }
  };

  const mostrarMedidas = (medidas) => {
    if (medidas.length === 0) {
      return <div>No hay medidas contabilizadas</div>;
    } else {
      const listItems = medidas.map((number) => {
        let color = getBg(COLORS[MEDIDAS.indexOf(number) % COLORS.length]);
        return (
          <li style={{ color: color, fontSize: "18px", padding: "0px" }}>
            <span style={{ color: "black", fontSize: "14px", padding: "0px" }}>
              {number}
            </span>
          </li>
        );
      });
      return listItems;
    }
  };

  // hide chart on index === i
  const hideChart = (i) => {
    setVisibleCharts(
      visibleCharts.map((item, j) => {
        if (i === j) {
          return false;
        }
        return item;
      })
    );
  };

  const showAdditionalChart = () => {
    setVisibleCharts([...visibleCharts, true]);
  };
  return (
    <Wrapper>
      <Sidebar>
        {currentSector.name === "Seleccionar Sector" ? (
          <HowTo>
            En este selector es posible encontrar los sectores del INGEI. <br />
            Seleccione algún sector para desplegar la lista de medidas de
            mitigación que se pueden implementar en los distintos sectores.
            <span
              style={{ fontSize: "16px" }}
              role="img"
              aria-labelledby="down-pointing"
            >
              &#x1f447;
            </span>
          </HowTo>
        ) : null}
        <ScenarioSelector
          onChange={setCurrentSector}
          onChange2={setMedidaSettings}
          scenarios={policies}
          selected={currentSector ? currentSector.name : "custom"}
        />
        {selectSectorMedidas(currentSector, medidaSettings, medidasActivas)}
      </Sidebar>
      <Main>
        {visibleCharts.map(
          (visible, i) =>
            visible && (
              <ChartMode
                key={i}
                index={i}
                onHide={amountVisible > 1 ? () => hideChart(i) : null}
                currentScenario={currentSector}
                settings={medidaSettings}
                medidasActivas={medidasActivas}
                type={"sector"}
                selected={currentSector.name}
              />
            )
        )}
        {amountVisible < 5 && (
          <AddMode>
            <button type="button" onClick={showAdditionalChart}>
              Agregar indicador +
            </button>
          </AddMode>
        )}
      </Main>
      <Sidebar>
        <HowTo>
          Lista de las medidas contabilizadas, para todos los sectores.
          <span
            style={{ fontSize: "16px" }}
            role="img"
            aria-labelledby="down-pointing"
          >
            &#x1f447;
          </span>
        </HowTo>
        <Settings.Panel.Medidas>
          <h4>Medidas Contabilizadas</h4>

          <ul>{mostrarMedidas(medidasActivas)}</ul>
        </Settings.Panel.Medidas>
      </Sidebar>
    </Wrapper>
  );
};

export default DashboardContainer;

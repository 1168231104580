import styled from 'styled-components';

const Fact = styled.div`
padding: 8px;
margin: 3px;
font-size: 10px;
border-radius: 4px;
max-height: 300px;
border: 1px solid #e4e7f0;
background-color: #f8f8f8;
text-color: #009ba6;

${props => props.baseline && 'align-self: baseline'}
${props => props.table && 'display: table-cell'}
${props => props.table && 'width: 50%'}
${props => props.notTable && 'display: table-cell'}
${props => props.notTable && 'width: 100%'}

h4 { 
  margin: 0 0 20px 0;
  color: #7b91b1;
}

h3 {
  margin: 0 0 0 0;
  color: #7b91b1;
}
span{
  align: center;
  font-size: 8px;
  justify: center;
  color: black;
}

.success {
  color: #0D9207;
}

.warning {
  color: #F92403;
}


img {
  display: block;
  margin: 20px auto 0;
}

b {
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
  margin: 0 4px;
}

@media(max-width:720px) {
    padding: 3%;
    margin: 1%;
    font-size: 8px;
    border-radius: 2px;

    h4 { 
  margin: 0 0 20px 0;
  color: #7b91b1;
  fontSize:8px
}
  }
`;

export default Fact;

import React from "react";
import { func, object } from "prop-types";
import SistemasGestionHelp from "components/help/helpMedidas/SistemasGestionHelp";
import ElectrificacionMotrizIndustriaHelp from "components/help/helpMedidas/ElectrificacionMotrizIndustriaHelp";
import MepsMotores100Help from "components/help/helpMedidas/MepsMotores100Help";
import UsoMotricesHelp from "components/help/helpMedidas/UsoMotricesHelp";
import SSTIndustriaMineriaHelp from "components/help/helpMedidas/SSTIndustriaMineriaHelp";
import ElectrificacionTermicaHelp from "components/help/helpMedidas/ElectrificacionTermicaHelp";
import ElectrificacionMotrizCobreHelp from "components/help/helpMedidas/ElectrificacionMotrizCobreHelp";
import ElectrificacionMotrizRestoHelp from "components/help/helpMedidas/ElectrificacionMotrizRestoHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";

const chooseHelp = (medida) => {
  if (medida === "Sistemas de gestión de energía") {
    return <SistemasGestionHelp />;
  } else if (medida === "Electrificación motriz industria") {
    return <ElectrificacionMotrizIndustriaHelp />;
  } else if (medida === "MEPS Motores hasta 100HP") {
    return <MepsMotores100Help />;
  } else if (medida === "Usos motrices en industria y minería - Hidrógeno") {
    return <UsoMotricesHelp />;
  } else if (medida === "SST Industria y minería") {
    return <SSTIndustriaMineriaHelp />;
  } else if (medida === "Electrificación térmica") {
    return <ElectrificacionTermicaHelp />;
  } else if (medida === "Electrificación motriz - minería cobre") {
    return <ElectrificacionMotrizCobreHelp />;
  } else if (medida === "Electrificación motriz - resto minería") {
    return <ElectrificacionMotrizRestoHelp />;
  }
  return null;
};

const IndustriasPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="INDUSTRIAS MANUFACTURERAS Y DE LA CONSTRUCCIÓN"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

IndustriasPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default IndustriasPolicies;

import BigNumber from "./layout/bigNumber";
import Fact from "./layout/fact";
import React from "react";
import Columnas from "components/help/Columnas";

const MedidasAño = ({ base, newValue, año, kpiDict }) => {
  return (
    <div>
      <Columnas>
        <Fact table>
          <h4>Emisiones con las medidas contabilizadas son</h4>
          <BigNumber>
            <h1>{Number(base).toFixed(1).replace(".", ",")}</h1>
            <label>
              {" "}
              millones de toneladas de CO<sub>2e</sub> en el año {año}.
            </label>
          </BigNumber>
        </Fact>

        <Fact table>
          <h4>Emisiones mitigadas por las medidas contabilizadas son</h4>
          <BigNumber>
            <h1>{newValue.toFixed(1).replace(".", ",")}</h1>
            <label>
              {" "}
              millones de toneladas de CO<sub>2e</sub> en el año {año}.
            </label>
          </BigNumber>
        </Fact>
      </Columnas>
    </div>
  );
};

export default MedidasAño;

import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/ui/Loader";

const BannerList = styled(List)`
  display: block;
  @media (max-width: 720px) {
    display: flex;
    flexdirection: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }
`;

const Table = styled(Link)`
  padding: 8px;
  margin: 2px;
  border-radius: 4px;
  border: 1px solid #e4e7f0;
  diplay: table;
  width: 300px;
  color: #000000;
  text-decoration: none;
  :hover {
    color: #009245;
  }
  @media (max-width: 720px) {
    display: table-cell;
    max-width: 200px;
    height: 250px;
    img {
      max-height: 50%;
      width: auto;
      max-width: 100%;
    }
  }
`;

const Row = styled.div`
  display: table-row;
  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const Title = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  color: #009245;
  margin-bottom: 2px;
  @media (max-width: 720px) {
    font-size: 12px;
    font-weight: 700;
  }
`;

const NoContent = styled.div`
  justify-content: center !important;
  text-align: center !important;
  font-size: 12px;
  margin: 10px;
`;
/**
 * InfoBanner.js
 * Componente que contiene una columna de 5 noticias
 */
const InfoBanner = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    // Carga los ultimos 5 posts de noticias de WP rest api
    async function loadPosts() {
      const response = await fetch(
        "https://noticias.observatoriocarbononeutral.cl/index.php/wp-json/wp/v2/posts?_embed&per_page=5"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      setPosts(posts);
    }
    loadPosts();
  }, []);
  return (
    <>
      <center>
        <Title to="/noticias">Noticias</Title>
      </center>
      <BannerList>
        {posts && posts.length > 0 ? (
          posts.map((item) => (
            <ListItem
              key={item.slug}
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                paddingTop: "2",
                paddingBottom: "2",
              }}
            >
              <Table to={`/noticias/${item.slug}`}>
                <img
                  src={item._embedded["wp:featuredmedia"]["0"].source_url}
                  srcSet={item._embedded["wp:featuredmedia"]["0"].source_url}
                  width={`100%`}
                  alt={item.title.rendered}
                  loading="lazy"
                />
                <Row>
                  <span>{item.title.rendered}</span>
                </Row>
              </Table>
            </ListItem>
          ))
        ) : posts && posts.length === 0 ? (
          <NoContent>No hay noticias que mostrar.</NoContent>
        ) : (
          <Loader />
        )}
      </BannerList>
    </>
  );
};

export default InfoBanner;

import findLastIndex from 'lodash/findLastIndex';

function lineTo(ctx, p0, p1, invert) {
  if (p1.steppedLine === true) {
    ctx.lineTo(p1.x, p0.y);
    ctx.lineTo(p1.x, p1.y);
  } else if (p1.tension === 0) {
    ctx.lineTo(p1.x, p1.y);
  } else {
    ctx.bezierCurveTo(
      invert ? p0.controlPointPreviousX : p0.controlPointNextX,
      invert ? p0.controlPointPreviousY : p0.controlPointNextY,
      invert ? p1.controlPointNextX : p1.controlPointPreviousX,
      invert ? p1.controlPointNextY : p1.controlPointPreviousY,
      p1.x,
      p1.y
    );
  }
}

function isStackFillDataset(chart, idx) {
  const ds = chart.config.data.datasets[idx];
  const md = chart.getDatasetMeta(idx) || {};

  return ds.type === 'line' && ds.stackFill && !md.hidden;
}

function fillPath(ctx, p00, p01, p10, p11) {
  ctx.beginPath();
  ctx.moveTo(p00.x, p00.y);
  lineTo(ctx, p00, p01);
  ctx.lineTo(p11.x, p11.y);
  lineTo(ctx, p11, p10, true);
  ctx.closePath();
  ctx.fill();
}

function fillArea(chart, md0, md1) {
  const ctx = chart.chart.ctx;
  const line0 = md0.dataset;
  const line1 = md1.dataset;
  const points0 = line0._children;
  const points1 = line1._children;
  const count = Math.min(points0.length, points1.length);

  if (!count) {
    return;
  }

  ctx.fillStyle = line1._view.backgroundColor;

  let i = 0;
  for (i = 0; i < count - 1; ++i) {
    const p00 = points0[i]._view;
    const p01 = points0[i + 1]._view;
    const p10 = points1[i]._view;
    const p11 = points1[i + 1]._view;
    fillPath(ctx, p00, p01, p10, p11);
  }
}

function fillToBottom(chart, md1) {
  const ctx = chart.chart.ctx;
  const line1 = md1.dataset;
  const points1 = line1._children;
  const count = points1.length;
  const bottom = chart.chartArea.bottom;

  if (!count) {
    return;
  }

  ctx.fillStyle = line1._view.backgroundColor;

  let i = 0;
  for (i = 0; i < count - 1; ++i) {
    const p00 = {
      ...points1[i]._view,
      y: bottom,
      controlPointNextY: bottom,
      controlPointPreviousY: bottom,
    };
    const p01 = {
      ...points1[i + 1]._view,
      y: bottom,
      controlPointNextY: bottom,
      controlPointPreviousY: bottom,
    };
    const p10 = points1[i]._view;
    const p11 = points1[i + 1]._view;
    fillPath(ctx, p00, p01, p10, p11);
  }
}

/**
 * Fills stacked line datasets from one to the other instead of filling each dataset to bottom.
 *
 * WORKAROUND: for https://github.com/chartjs/Chart.js/issues/2380
 */
const StackFill = {
  id: 'stackFill',

  afterDatasetsDraw: chart => {
    let i = 0;
    for (i = chart.data.datasets.length - 1; i >= 0; i--) {

      if (!isStackFillDataset(chart, i)) {
        continue;
      }
      const md1 = chart.getDatasetMeta(i) || {};
      let nextDatasetIdx = -1;

      if (i !== 0) {
        // we need to find the closest line dataset
        nextDatasetIdx = findLastIndex(
          chart.data.datasets,
          (d, idx) => isStackFillDataset(chart, idx),
          i - 1,
        );
      }

      if (nextDatasetIdx !== -1) {
        // we've found another stackFill line dataset
        const md0 = chart.getDatasetMeta(nextDatasetIdx) || {};
        fillArea(chart, md0, md1);
      } else {
        // no more stackFill line datasets, draw to bottom
        fillToBottom(chart, md1);
      }
    }
  },
};

export default StackFill;
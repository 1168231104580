import React from 'react';
import { func, object } from 'prop-types';
import RtViviendasHelp from 'components/help/helpMedidas/RtViviendasHelp';
import GeneracionDistribuidaHelp from 'components/help/helpMedidas/GeneracionDistribuidaHelp';
import FomentoRenovacionHelp from 'components/help/helpMedidas/FomentoRenovacionHelp';
import CalefaccionPublicoComercialHelp from 'components/help/helpMedidas/CalefaccionPublicoComercialHelp';
import SistemasSolaresHelp from 'components/help/helpMedidas/SistemasSolaresHelp';
import TaxisElectricosHelp from 'components/help/helpMedidas/TaxisElectricosHelp';
import BombasGeoHelp from 'components/help/helpMedidas/BombasGeoHelp';
import UsoTermicoGasoductosHelp from 'components/help/helpMedidas/UsoTermicoGasoductosHelp';
import CalefaccionDistritalHelp from 'components/help/helpMedidas/CalefaccionDistritalHelp';
import CambioModalHelp from 'components/help/helpMedidas/CambioModalHelp';
import VehiculosParticularesHelp from 'components/help/helpMedidas/VehiculosParticularesHelp';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "RT viviendas vulnerables"){
        return <RtViviendasHelp/>;
    }else if (medida === "Generación distribuida"){
        return <GeneracionDistribuidaHelp/>;
    }else if(medida === "Fomento a renovación energética de viviendas"){
        return <FomentoRenovacionHelp/>;
    }else if(medida === "Calefacción eléctrica público comercial"){
        return <CalefaccionPublicoComercialHelp/>;
    }else if(medida === "Sistemas solares térmicos – residencial y público"){
        return <SistemasSolaresHelp/>;
    }else if(medida === "Taxis eléctricos"){
        return <TaxisElectricosHelp/>;
    }else if (medida === "Bombas Geotérmicas de Calor"){
        return <BombasGeoHelp/>;
    }else if (medida === "Uso térmico vía gasoductos - Hidrógeno"){
        return <UsoTermicoGasoductosHelp/>;
    }else if (medida === "Calefacción distrital"){
        return <CalefaccionDistritalHelp/>;
    }else if (medida === "Cambio modal transporte"){
        return <CambioModalHelp/>;
    }else if (medida === "Vehículos particulares eléctricos"){
        return <VehiculosParticularesHelp/>;
    }
    return null;
}
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Vivienda y Urbanismo
const MinisterioViviendaPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => {
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Vivienda y Urbanismo'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
    );
}

MinisterioViviendaPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
export default MinisterioViviendaPolicies;
export const SCENARIOS_FILE_PATH = "./scenarios.json";
export const POLICIES_FILE_PATH = "./medidasSector.json";
export const APOLICIES_FILE_PATH = "./medidasAutoridad.json";
export const RPOLICIES_FILE_PATH = "./medidasRegiones.json";
export const REGIONES_PATH = "./infoRegiones.json";
export const chartSettings = [
  {
    id: "inventario_con_medidas_sin_proyecciones",
    label: "Escenario medidas de mitigación",
    name: "inventario con medidas simple",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, junto con la cuantificación de las reducciones producidas por las medidas de mitigación seleccionadas.
    La reducción de emisiones de las medidas de mitigación ha sido calculada por el Observatorio.`,
  },

  {
    id: "inventario_con_medidas",
    label: "Escenario de mitigación y carbon budget",
    name: "inventario con medidas",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, además del escenario de Carbono Neutralidad y el Carbon Budget asociado.
    Valores generados por el Observatorio.`,
  },
  {
    id: "inventario_con_medidas_observatorio",
    label: "Escenario medidas de mitigación y Proyección Observatorio",
    name: "inventario con medidas y proyecciones",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, además del escenario de Carbono Neutralidad,
    junto con la cuantificación de las reducciones producidas por las medidas
    de mitigación calculadas por el Observatorio.`,
  },
];

export const inventoryChartSettings = [
  {
    id: "inventario",
    label: "Inventario oficial de GEI",
    name: "inventario",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile 
    publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a los sectores
    emisores.`,
  },
  {
    id: "inventario_neto",
    label: "Inventario oficial de GEI Neto",
    name: "inventario neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: -100,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero 
    de Chile publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a
    los sectores emisores. Es neto ya que incluye las capturas del sector forestal.`,
  },
  {
    id: "inventario_actualizado_barras",
    label: "Inventario actualizado con sectores",
    name: "inventario actualizado barras",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de 
    Chile publicado por el Ministerio del Medio Ambiente y actualizado con los valores 
    generados por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
  {
    id: "inventario_actualizado",
    label: "Inventario actualizado de GEI",
    name: "inventario actualizado",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio.`,
  },
  {
    id: "inventario_actualizado_neto",
    label: "Inventario actualizado neto con sectores",
    name: "inventario actualizado Neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: -100,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio, desagregado de acuerdo a los sectores emisores. Es neto ya que incluye las
    capturas del sector forestal.`,
  },
  {
    id: "inventario_con_medidas_solo_oficial",
    label: "Escenario oficial de mitigación y carbon Budget ",
    name: "inventario con medidas oficial",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado con los valores generados por el Observatorio, además del escenario de Carbono
    Neutralidad y el Carbon Budget asociado.`,
  },
];

export const inventoryChartSettings2 = [
  {
    id: "inventario_actualizado_barras",
    label: "Inventario actualizado con sectores",
    name: "inventario actualizado barras",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de 
    Chile publicado por el Ministerio del Medio Ambiente y actualizado con los valores 
    generados por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
  {
    id: "inventario_proyectado_barras",
    label: "Inventario actualizado: proyección con sectores",
    name: "inventario con proyecciones",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 130,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
      actualizado, junto con la cuantificación de las reducciones producidas por las medidas de
      mitigación calculadas por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
];
export const chartSettingsRegiones2 = [
  {
    id: "inventario",
    label: "Inventario oficial de GEI",
    name: "inventario",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile 
    publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a los sectores
    emisores.`,
  },
  {
    id: "inventario_neto",
    label: "Inventario oficial de GEI Neto",
    name: "inventario neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 10,
    minValue: -10,
    stepSize: 15,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero 
    de Chile publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a
    los sectores emisores. Es neto ya que incluye las capturas del sector forestal.`,
  },
  {
    id: "inventario_actualizado",
    label: "Inventario actualizado de GEI",
    name: "inventario actualizado",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio.`,
  },
  {
    id: "inventario_actualizado_barras",
    label: "Inventario actualizado con sectores",
    name: "inventario actualizado barras",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de 
    Chile publicado por el Ministerio del Medio Ambiente y actualizado con los valores 
    generados por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
  {
    id: "inventario_actualizado_neto",
    label: "Inventario actualizado neto con sectores",
    name: "inventario actualizado Neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 120,
    minValue: -100,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio, desagregado de acuerdo a los sectores emisores. Es neto ya que incluye las
    capturas del sector forestal.`,
  },
  {
    id: "inventario_con_medidas_sin_proyecciones",
    label: "Escenario medidas de mitigación",
    name: "inventario con medidas simple",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 2,
    minValue: 0,
    stepSize: 1,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, junto con la cuantificación de las reducciones producidas por las medidas de mitigación seleccionadas.
    La reducción de emisiones de las medidas de mitigación ha sido calculada por el Observatorio.`,
  },
];
export const chartSettingsRegiones3 = [
  {
    id: "inventario",
    label: "Inventario oficial de GEI",
    name: "inventario",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile 
    publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a los sectores
    emisores.`,
  },
  {
    id: "inventario_neto",
    label: "Inventario oficial de GEI Neto",
    name: "inventario neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 5,
    minValue: -5,
    stepSize: 10,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero 
    de Chile publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a
    los sectores emisores. Es neto ya que incluye las capturas del sector forestal.`,
  },
  {
    id: "inventario_actualizado",
    label: "Inventario actualizado de GEI",
    name: "inventario actualizado",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio.`,
  },
  {
    id: "inventario_actualizado_barras",
    label: "Inventario actualizado con sectores",
    name: "inventario actualizado barras",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: false,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de 
    Chile publicado por el Ministerio del Medio Ambiente y actualizado con los valores 
    generados por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
  {
    id: "inventario_actualizado_neto",
    label: "Inventario actualizado neto con sectores",
    name: "inventario actualizado Neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 5,
    minValue: -5,
    stepSize: 10,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio, desagregado de acuerdo a los sectores emisores. Es neto ya que incluye las
    capturas del sector forestal.`,
  },
  {
    id: "inventario_con_medidas_sin_proyecciones",
    label: "Escenario medidas de mitigación",
    name: "inventario con medidas simple",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 2,
    minValue: 0,
    stepSize: 1,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, junto con la cuantificación de las reducciones producidas por las medidas de mitigación seleccionadas.
    La reducción de emisiones de las medidas de mitigación ha sido calculada por el Observatorio.`,
  },
];
export const chartSettingsRegiones = [
  {
    id: "inventario",
    label: "Inventario oficial de GEI",
    name: "inventario",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 30,
    minValue: 0,
    stepSize: 5,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile 
    publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a los sectores
    emisores.`,
  },
  {
    id: "inventario_neto",
    label: "Inventario oficial de GEI Neto",
    name: "inventario neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 30,
    minValue: -30,
    stepSize: 10,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero 
    de Chile publicado por el Ministerio del Medio Ambiente, desagregado de acuerdo a
    los sectores emisores. Es neto ya que incluye las capturas del sector forestal.`,
  },
  {
    id: "inventario_actualizado",
    label: "Inventario actualizado de GEI",
    name: "inventario actualizado",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio.`,
  },
  {
    id: "inventario_actualizado_barras",
    label: "Inventario actualizado con sectores",
    name: "inventario actualizado barras",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de 
    Chile publicado por el Ministerio del Medio Ambiente y actualizado con los valores 
    generados por el Observatorio, desagregado de acuerdo a los sectores emisores.`,
  },
  {
    id: "inventario_actualizado_neto",
    label: "Inventario actualizado neto con sectores",
    name: "inventario actualizado Neto",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: -100,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Corresponde al Inventario Nacional de Gases de Efecto Invernadero de Chile
    publicado por el Ministerio del Medio Ambiente y actualizado con los valores generados por
    el Observatorio, desagregado de acuerdo a los sectores emisores. Es neto ya que incluye las
    capturas del sector forestal.`,
  },
  {
    id: "inventario_con_medidas_sin_proyecciones",
    label: "Escenario medidas de mitigación",
    name: "inventario con medidas simple",
    stacked: true,
    unit: "Millón ton. de CO₂e",
    setScale: true,
    maxValue: 120,
    minValue: 0,
    stepSize: 20,
    chartType: "bar",
    multipleEnabled: false,
    description: `Presenta el Inventario Nacional de Gases de Efecto Invernadero de Chile
    actualizado, junto con la cuantificación de las reducciones producidas por las medidas de mitigación seleccionadas.
    La reducción de emisiones de las medidas de mitigación ha sido calculada por el Observatorio.`,
  },
];

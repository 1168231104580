import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/meps motores hasta 100hp.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const MepsMotores100Help = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );
  
  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_arica_y_parinacota: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_tarapaca: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_antofagasta: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_atacama: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_coquimbo: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_valparaiso: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_metropolitana: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_ohiggins: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_del_maule: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_del_nuble: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_del_biobio: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_la_araucania: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_los_rios: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_los_lagos: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_aysen: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
    region_de_magallanes: {
      estado_de_implementacion: {
        porcentaje: 38,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("MEPS_Motores_hasta_100HP")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["MEPS Motores hasta 100HP"]);
      set2050(dataset[60]["MEPS Motores hasta 100HP"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>MEPS Motores hasta 100HP</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em", width:"400px" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida tiene como objetivo disminuir el consumo eléctrico del
        sector industria y minería restringiendo la entrada de motores
        eléctricos nuevos a motores que cumplan con un estándar mínimo de
        eficiencia. Actualmente existe un MEPS (estándares de mínimos de
        eficiencia energética) para motores de hasta 10 HP, esta medida
        abarcaría un rango mayor.
      </p>
      <p>
        Se considera el recambio paulatino de motores del rango menor a 10 HP
        hasta el recambio total en el año 2030. La mejora en eficiencia asociada
        se asume en 3% de mayor rendimiento (diferencia asociada a la mejora de
        rendimiento entre un motor ineficiente y un motor de alta eficiencia).
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Recambio total de motores de hasta 100 HP en el año 2030
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,7 (2030) y 0,4 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Participación actual de motores con MEPS a nivel nacional (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>Recambio total de motores de hasta 100 HP en el año 2030</p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,7 (2030) y 0,4 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Participación actual de motores con MEPS (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.7, 0.4, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default MepsMotores100Help;

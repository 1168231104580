import React, { useEffect } from "react";
import ChartJs from "chart.js";
import styled from "styled-components";

const ChartContainer = styled.div`
  width: 100%;
  height: 240px;
  font-size: 11px;
`;

const initChart = (chile) => {
  const elem = document.getElementById("kpi_chart");
  const canvasId = Math.random();
  elem.innerHTML = `<canvas id="${canvasId}" height="240px"></canvas>`;
  const ctx = document.getElementById(canvasId).getContext("2d");
  new ChartJs(ctx, {
    type: "bar",
    data: {
      datasets: [
        {
          data: [15, chile, 9, 4.5, 2.1],
          backgroundColor: "#639fed",
          label: "Emisiones per cápita",
        },
      ],
      labels: ["USA", "Chile", "OCDE", "Mundo", "América No-OCDE"],
    },
    options: {
      responsive: true,
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          padding: 10,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "#eeeeee",
            },
          },
        ],
        yAxes: [
          {
            id: "y-axes",
            stacked: false,
            scaleLabel: {
              display: true,
              labelString: "Millón de ton. de CO2e",
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 20,
              stepSize: 5,
            },
            gridLines: {
              color: "#eeeeee",
            },
          },
        ],
      },
    },
  });
};

/**
 * Gráfico de emisiones per capita
 */
const EmisionesChart = ({ chile }) => {
  useEffect(() => {
    initChart(chile);
  }, [chile]);

  return <ChartContainer id="kpi_chart" />;
};

export default EmisionesChart;

import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";
import UsoLodosHelp from "components/help/helpMedidas/UsoLodosHelp";
import CapturaBiogasHelp from "components/help/helpMedidas/CapturaBiogasHelp";

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (
    medida ===
    "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal"
  ) {
    return <UsoLodosHelp region={region} />;
  } else if (medida === "Captura o uso de biogás de rellenos sanitarios") {
    return <CapturaBiogasHelp region={region} />;
  }
  return null;
};

// Crea un container con la lista de medidas que se puede seleccionar para sector de 'Residuos'
const ResiduosPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="Residuos"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

ResiduosPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default ResiduosPolicies;

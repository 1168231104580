import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/electrificacionmotrizindustria.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const ElectrificacionMotrizIndustriaHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        Incremento2020: 10,
        Incremento2030: 20,
        Incremento2040: 30,
        Porcentaje_industrias: 67,
        Porcentaje: 90,
      },
      estado_de_implementacion: {
        Porcentaje_industrias: 99.5,
        Porcentaje_celulosa: 98.5,
        Porcentaje_cemento: 91.1,
        Porcentaje_siderurgia: 98,
        Incremento_industrias: 3.7,
        Incremento_celulosa: 0.5,
        Incremento_cemento: -6.6,
        Incremento_siderurgia: 1.3,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Electrificación_Motriz_Industria")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Electrificación motriz industria"]);
      set2050(dataset[60]["Electrificación motriz industria"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Electrificación Motriz-Industria</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em", width: "400px"}
          }
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera la electrificación de usos motrices en industrias
        varias, reemplazando del uso de combustibles fósiles por el uso de
        electricidad. Por usos motrices se consideran todos los procesos que
        utilizan tecnologías que involucren fuerzas mecánicas, fijas o móviles;
        concepto que considera actividades de molienda, transporte de carga al
        interior de los establecimientos y maquinarias especificas tales como
        motores, grúas, entre otros.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                Incremento de la participación a nivel nacional en 
                <span>
                {String(
                    cumplimientoRegion["compromiso_referencial"]["Incremento2020"]
                  ).replace(".", ",")}
                </span>%,{" "}
                <span>
                {String(
                    cumplimientoRegion["compromiso_referencial"]["Incremento2030"]
                  ).replace(".", ",")}
                </span>% y {" "}
                <span>
                {String(
                    cumplimientoRegion["compromiso_referencial"]["Incremento2040"]
                  ).replace(".", ",")}
                </span>% en usos motrices eléctricos en industrias varias 
                para los años 2020, 2030 y 2040, respectivamente.
              </p>
              <p>
                Se electrifica un
                <span>
                {String(
                    cumplimientoRegion["compromiso_referencial"]["Porcentaje_industrias"]
                  ).replace(".", ",")}
                </span>% en industrias varias al 2050,{" "}
                <span>
                {String(
                    cumplimientoRegion["compromiso_referencial"]["Porcentaje"]
                  ).replace(".", ",")}
                </span>% en papel y celulosa, cemento y siderurgia.
              </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0.6 (2030) y 1,8 (2050) </span>
              </p>
            </WrapperExp>
              </Columnas>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                Participación de usos motrices eléctricos a nivel nacional (estimación):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Porcentaje_industrias"]
                  ).replace(".", ",")}
                </span>
                % en industrias varias, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Porcentaje_celulosa"]
                  ).replace(".", ",")}
                </span>
                % en papel y celulosa, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Porcentaje_cemento"]
                  ).replace(".", ",")}
                </span>
                % en cemento, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Porcentaje_siderurgia"]
                  ).replace(".", ",")}
                </span>
                % en siderurgia. {" "}
              </p>
              <p>
                Incremento de participación desde 2020 (estimación):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Incremento_industrias"]
                  ).replace(".", ",")}
                </span>
                % en industrias varias, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Incremento_celulosa"]
                  ).replace(".", ",")}
                </span>
                % en papel y celulosa, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Incremento_cemento"]
                  ).replace(".", ",")}
                </span>
                % en cemento, {" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["Incremento_siderurgia"]
                  ).replace(".", ",")}
                </span>
                % en siderurgia. {" "}
              </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Incremento de la participación en 
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["Incremento2020"]
                  ).replace(".", ",")}
                </span>%,{" "}
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["Incremento2030"]
                  ).replace(".", ",")}
                </span>% y {" "}
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["Incremento2040"]
                  ).replace(".", ",")}
                </span>% en usos motrices eléctricos en industrias varias 
                para los años 2020, 2030 y 2040, respectivamente.
              </p>
              <p>
                Se electrifica un
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["Porcentaje_industrias"]
                  ).replace(".", ",")}
                </span>% en industrias varias al 2050,{" "}
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["Porcentaje"]
                  ).replace(".", ",")}
                </span>% en papel y celulosa, cemento y siderurgia.
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0.6 (2030) y 1,8 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>
          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Participación de usos motrices eléctricos (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Porcentaje_industrias"]
                  ).replace(".", ",")}
                </span>
                % en industrias varias, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Porcentaje_celulosa"]
                  ).replace(".", ",")}
                </span>
                % en papel y celulosa, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Porcentaje_cemento"]
                  ).replace(".", ",")}
                </span>
                % en cemento, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Porcentaje_siderurgia"]
                  ).replace(".", ",")}
                </span>
                % en siderurgia. {" "}
              </p>
              <p>
                Incremento de participación desde 2020 (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Incremento_industrias"]
                  ).replace(".", ",")}
                </span>
                % en industrias varias, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Incremento_celulosa"]
                  ).replace(".", ",")}
                </span>
                % en papel y celulosa, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Incremento_cemento"]
                  ).replace(".", ",")}
                </span>
                % en cemento, {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Incremento_siderurgia"]
                  ).replace(".", ",")}
                </span>
                % en siderurgia. {" "}
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.6, 1.8, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default ElectrificacionMotrizIndustriaHelp;
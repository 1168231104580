import React,  { useState } from 'react';
import styled from 'styled-components';
import MUICheckbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'react-modal'; 

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.disabled && `
    pointer-events: none;
    opacity: 0.4;
  `}

  label {
    cursor: pointer;
    text-align: left;
    width: 100%;
    padding-right: 15px;
  }
`;

const Icon = styled.i`
  cursor: pointer;
  position: relative;
  right: 10px;
  color: #333;

  &.fa-chevron-up, &.fa-chevron-down {
    position: relative;
    right: 15px;
  }
`;

const CloseIcon = styled.i`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  fontWeight: 700;
`;

/**
 * Contiene el clásico componente checkbox para activar o desactivar una opción.
 * Además de eso permite agregar una etiqueta, un ícono que permite desplegar una ventana de ayuda,
 * Un ícono que permite desplegar un tooltip informativo, y un ícono chevron para mostrar u ocultar las
 * opciones adyacentes
 */
const Checkbox = ({
  id,
  label,
  checked,
  onChange,
  visible,
  enabled,
  toggleVisible,
  help,
  info,
  disabled,
}) => {
  const [helpVisible, setHelpVisible] = useState(false);

  return (
    <CheckboxWrap disabled={disabled}>
      <MUICheckbox id={id} checked={checked} onChange={onChange} />
      <label htmlFor={id}>
        { label }
      </label>
      {
        enabled && (
          <Icon className={`fas fa-chevron-${visible ? 'up' : 'down'}`} onClick={toggleVisible} />
        )
      }
      {
        help && (
          <Icon className="fas fa-question-circle" onClick={() => setHelpVisible(true)} />
        )
      }
      {
        info && (
          <Tooltip title={info} aria-label={info}>
            <Icon className="fas fa-info-circle" />
          </Tooltip>
        )
      }
      {
        help && (
          <Modal
            isOpen={helpVisible}
            onRequestClose={() => setHelpVisible(false)}
            ariaHideApp={false}
            style={{
              zIndex: 99999,
            }}
          > 
            <CloseIcon className="fas fa-times" onClick={() => setHelpVisible(false)} />
            { help }
          </Modal>
        )
      }
    </CheckboxWrap>
  );
}

export default Checkbox;

import React from "react";
import Settings from "components/ui/Settings";
import Select from "components/ui/Select";
// import EscenarioBaseHelp from 'components/help/escenarios/EscenarioBaseHelp';
// import EscenarioRetiroCentralesHelp from 'components/help/escenarios/EscenarioRetiroCentralesHelp';
// import EscenarioElectroMovilidadHelp from 'components/help/escenarios/EscenarioElectroMovilidadHelp';
// import EscenarioMitigacionAceleradaHelp from 'components/help/escenarios/EscenarioMitigacionAceleradaHelp';
// import EscenarioBaseTestingHelp from 'components/help/escenarios/EscenarioBaseTestingHelp';
// import EscenarioCustomHelp from 'components/help/escenarios/EscenarioCustomHelp';

/**
 * Permite elegir entre los escenarios predeterminados,
 * O "valores personalizados"
 */
const AutoridadSelector = ({
  selected,
  scenarios,
  onChange,
  onChange2,
  onChange3,
}) => {
  const options = scenarios
    .filter((sce) => sce.name !== "custom")
    .map((scenario) => ({
      label: scenario.name,
      value: scenario.name,
    }));

  return (
    <Settings.Panel>
      <h4>Autoridad Climática</h4>
      <div style={{ padding: "0 2px", fontSize: "11px" }}>
        <Select
          value={selected}
          options={options}
          onChange={(value) => {
            onChange(scenarios.find((s) => s.name === value));
            onChange2(scenarios.find((s) => s.name === value).settings);
            onChange3([]);
          }}
        />
      </div>
      {/* <MoreInfoPopUp label="Más información" info={ parseHelp(selected) }>
        Descripción Sector
      </MoreInfoPopUp> */}
    </Settings.Panel>
  );
};

// function parseHelp(value) {
//   if(value === "Escenario Base") {
//     return <EscenarioBaseHelp/>;
//   }
//   else if(value === "Escenario Retiro Centrales") {
//     return <EscenarioRetiroCentralesHelp/>;
//   }
//   else if(value === "Escenario Electromovilidad") {
//     return <EscenarioElectroMovilidadHelp/>;
//   }
//   else if(value === "Escenario Mitigación Acelerada") {
//     return <EscenarioMitigacionAceleradaHelp/>;
//   }
//   else if(value === "Escenario Base Testing") {
//     return <EscenarioBaseTestingHelp/>;
//   }
//   else { //value == custom
//     return <EscenarioCustomHelp/>;
//   }
// }

export default AutoridadSelector;

import React from "react";
import { func, object } from "prop-types";
import MedidasSectoresContainer from "./MedidasSectoresContainer";

const chooseHelp = (medida) => {
  return null;
};
const ForestalPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="Forestal"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

ForestalPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default ForestalPolicies;

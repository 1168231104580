import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import fomentoimg from "img/medidas/fomento a la renovacion energetica de viviendas.jpg";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const FomentoRenovacionHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        calificacion_viviendas: 570000,
        electrificacion_casas: 57,
        electrificacion_departamento: 70,
      },
      estado_de_implementacion: {
        renovacion_de_viviendas: 300,
        electrificación_casas: 20,
        electrificación_departamento: 40,
        porcentaje_total: 19.7,
      },
    },
  };
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Fomento a renovación energética de viviendas"]);
      set2050(dataset[60]["Fomento a renovación energética de viviendas"]);
    })
    .catch((error) => {
      console.error(error);
    });

  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Fomento_A_Renovación_Energética_de_viviendas"
          )
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  return (
    <HelpContent>
      <div>
        <h1>Fomento a Renovación Energética de Viviendas</h1>
      </div>
      <h4>Sector Comercial / Institucional y Residencial</h4>
      <p>
        <img
          src={fomentoimg}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida comprende dos acciones: la renovación térmica y etiquetado
        de viviendas de sectores medios, junto a la electrificación de la
        calefacción a nivel residencial (reemplazando artefactos residenciales
        de calefacción que utilizan gas licuado, kerosene, gas natural).
      </p>
      <p>
        La medida de renovación térmica y etiquetado de viviendas considera una
        duración de 5 años, una penetración del 10% del parque de viviendas
        existentes construidas antes del 2000 con lo que 570.000 viviendas
        obtienen la calificación energética. En tanto, la medida de calefacción
        eléctrica residencial consideró una participación del 57% al 2050 para
        viviendas tipo casa y un 70% para viviendas tipo departamento. Las
        medidas consideran la actualización de la reglamentación térmica de la
        OGUC (artículo 4.1.10),
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    {" "}
                    Renovación térmica y etiquetado de viviendas de sectores medios
                    (<span>{cumplimientoRegion["compromiso_referencial"]["calificacion_viviendas"]}</span> {" "}
                    viviendas obtienen calificación energética al final de los 5 
                    años del programa), junto a la electrificación de la calefacción 
                    a nivel residencial 
                    (<span>{cumplimientoRegion["compromiso_referencial"]["electrificacion_casas"]}</span>
                    % para viviendas tipo casa y <span>{cumplimientoRegion["compromiso_referencial"]["electrificacion_departamento"]}</span>
                    % para viviendas tipo departamento).
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>1,5 (2030) y 7,5 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    {" "}
                    Cantidad de viviendas con renovación térmica a nivel nacional (estimación):{" "}
                    <span>
                      {cumplimientoRegion["estado_de_implementacion"]["renovacion_de_viviendas"]}
                    </span>
                  </p>
                  <p>
                    {" "}
                    Electrificación de calefacción según total de viviendas a nivel nacional (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["porcentaje_total"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
             

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>

              <p>
                {" "}
                Renovación térmica y etiquetado de viviendas de sectores medios
                (<span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["calificacion_viviendas"]}</span> {" "}
                viviendas obtienen calificación energética al final de los 5 
                años del programa), junto a la electrificación de la calefacción 
                a nivel residencial 
                (<span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["electrificacion_casas"]}</span>
                % para viviendas tipo casa y 
                <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["electrificacion_departamento"]}</span>
                % para viviendas tipo departamento).
              </p>
              {/* A continuacion se deben ingresar los valores referentes */}
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>1,5 (2030) y 7,5 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                {" "}
                Cantidad de viviendas con renovación térmica (estimación):{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["estado_de_implementacion"]["renovacion_de_viviendas"]}
                </span>
              </p>
              <p>
                {" "}
                Electrificación de calefacción según total de viviendas (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["porcentaje_total"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
              {/*casas (año 2018): <span>{FomentoRenovacion["electrificación_casas"]}</span>  Electrificación de calefacción actual en departamento: <span>{FomentoRenovacion["electrificación_departamento"]}</span></p>*/}
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>

          {desviacion(1.5, 7.5, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default FomentoRenovacionHelp;

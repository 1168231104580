import React from "react";
import { func, object } from "prop-types";
import GeneracionDistribuidaHelp from "components/help/helpMedidas/GeneracionDistribuidaHelp";
import FomentoRenovacionHelp from "components/help/helpMedidas/FomentoRenovacionHelp";
import CalefaccionPublicoComercialHelp from "components/help/helpMedidas/CalefaccionPublicoComercialHelp";
import SistemasSolaresHelp from "components/help/helpMedidas/SistemasSolaresHelp";
import ElectrificacionMotrizComercialHelp from "components/help/helpMedidas/ElectrificacionMotrizComercialHelp";
import BombasGeoHelp from "components/help/helpMedidas/BombasGeoHelp";
import RtViviendasHelp from "components/help/helpMedidas/RtViviendasHelp";
import UsoTermicoGasoductosHelp from "components/help/helpMedidas/UsoTermicoGasoductosHelp";
import CalefaccionDistritalHelp from "components/help/helpMedidas/CalefaccionDistritalHelp";
import MepsNuevosHelp from "components/help/helpMedidas/MepsNuevosHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";

const chooseHelp = (medida) => {
  if (medida === "Generación distribuida") {
    return <GeneracionDistribuidaHelp />;
  } else if (medida === "Fomento a renovación energética de viviendas") {
    return <FomentoRenovacionHelp />;
  } else if (medida === "Calefacción eléctrica público comercial") {
    return <CalefaccionPublicoComercialHelp />;
  } else if (medida === "Sistemas solares térmicos – residencial y público") {
    return <SistemasSolaresHelp />;
  } else if (medida === "Electrificación motriz comercial") {
    return <ElectrificacionMotrizComercialHelp />;
  } else if (medida === "Bombas Geotérmicas de Calor") {
    return <BombasGeoHelp />;
  } else if (medida === "RT viviendas vulnerables") {
    return <RtViviendasHelp />;
  } else if (medida === "Uso térmico vía gasoductos - Hidrógeno") {
    return <UsoTermicoGasoductosHelp />;
  } else if (medida === "Calefacción distrital") {
    return <CalefaccionDistritalHelp />;
  } else if (medida === "MEPS Nuevos") {
    return <MepsNuevosHelp />;
  }
  return null;
};
const OtrosSectoresPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="OTROS SECTORES: COMERCIAL, PÚBLICO Y RESIDENCIAL"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};
OtrosSectoresPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default OtrosSectoresPolicies;

import React from 'react';
import { func, object } from 'prop-types';
import CapturaBiogasHelp from 'components/help/helpMedidas/CapturaBiogasHelp';
import GeneracionBiogasHelp from 'components/help/helpMedidas/GeneracionBiogasHelp';
import UsoLodosHelp from 'components/help/helpMedidas/UsoLodosHelp';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal"){
        return <UsoLodosHelp/>;
    }else if (medida === "Captura o uso de biogás de rellenos sanitarios"){
        return <CapturaBiogasHelp/>;
    }else if(medida ==="Generación biogás"){
        return <GeneracionBiogasHelp/>;
    }
    return null;
}
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Salud
const MinisterioSaludPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => {
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Salud'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
);
}

MinisterioSaludPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
export default MinisterioSaludPolicies;
import styled from 'styled-components';

export default styled.div`
padding: 0px 10px;
margin-top: 5px;
margin-bottom: 5px;
border-radius: 4px;
border: 2px solid #e4e7f0;
background-color: #f8f8f8;
clear:both;
display: table-cell

${props => props.table && 'width: 50%'}

h5{
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-left: 2px;
}

p{
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 12px;
}

@media (max-width: 720px) {
  display: table;
  margin-top: 0px;
  margin-bottom: 0px;
  ${props => props.table && 'width: 100%'}

  h5{
    font-size: 14px;
  }
  p{
    font-size: 10px;
  }
}
`;

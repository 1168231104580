import BigNumber from "./layout/bigNumber";
import Fact from "./layout/fact";
import React from "react";
import Columnas from "components/help/Columnas";

const Medidas = ({ base, newValue, año, kpiDict, type }) => {
  console.log(type);
  return (
    <div>
      <Columnas>
        <Fact table>
          <h4>Emisiones en el 2030 son</h4>
          <BigNumber>
            <h1>{Number(kpiDict["tco2_2030"]).toFixed(1).replace(".", ",")}</h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>

        <Fact table>
          <h4>Emisiones acumuladas entre 2020 y 2030 son</h4>
          <BigNumber>
            <h1>
              {Number(kpiDict["tco2_entre_2020_2030"])
                .toFixed(1)
                .replace(".", ",")}
            </h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>
      </Columnas>
      {type !== "regional" ? (
        <>
          <Columnas>
            <Fact table>
              <h4>En el año 2030, con las medidas contabilizadas</h4>
              <BigNumber>
                {kpiDict["tco2_2030"] > 95 ? (
                  <h1 className="warning">se supera</h1>
                ) : (
                  <h1 className="success">no se supera</h1>
                )}
                <label>el límite de emisiones de 95 millones de tCO2.</label>
              </BigNumber>
            </Fact>

            <Fact table>
              <h4>Con las medidas contabilizadas</h4>
              <BigNumber>
                {kpiDict["tco2_entre_2020_2030"] > 1100 ? (
                  <h1 className="warning">se supera</h1>
                ) : (
                  <h1 className="success">no se supera</h1>
                )}
                <label>
                  el presupuesto de emisiones de 1.100 millones tCO2eq para el
                  periodo 2020-2030
                </label>
              </BigNumber>
            </Fact>
          </Columnas>
          <Columnas>
            <Fact notTable>
              <h4>
                Porcentaje de Carbon Budget utilizado entre 2020 y{" "}
                {kpiDict["Año Update"]} es
              </h4>
              <BigNumber>
                <h1>
                  {(kpiDict["Carbono Utilizado"] / 11)
                    .toFixed(1)
                    .replace(".", ",")}{" "}
                  %
                </h1>
              </BigNumber>
            </Fact>
          </Columnas>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Medidas;

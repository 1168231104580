import Header from "components/layout/Header";
import React, { useEffect, useState } from "react";
import Nav from "components/layout/Nav";
import Footer from "components/layout/Footer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import { Pagination } from "@material-ui/lab";
const BannerList = styled(List)`
  display: block;
  width: 800px;
`;
const PostTitle = styled(Link)`
  padding: 8px;
  color: #000000;
  text-decoration: none;
  :hover {
    color: #009245;
  }
`;

const BannerTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #009245;
  background-color: #ebebeb;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 800px;
  padding: 15px;
  position: sticky;
  top: 0px;
  z-index: 1;
  @media (max-width: 720px) {
    font-size: 10px;
    font-weight: 700;
    height: 30px;
  }
`;
const NoContent = styled.div`
  justify-content: center !important;
  text-align: center !important;
  font-size: 12px;
  margin: 10px;
`;
/**
 * Noticias.js
 * Componente que muestra las noticias disponibles en Wordpress
 */
const Noticias = () => {
  const [posts, setPosts] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Carga las paginas de noticias de WP rest api cada 5 posts
    async function loadPosts() {
      const response = await fetch(
        `https://noticias.observatoriocarbononeutral.cl/index.php/wp-json/wp/v2/posts?_embed&per_page=5&page=${currentPage}`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      const paginas = response.headers.get("X-WP-TotalPages");
      setPosts(posts);
      setPageCount(paginas);
      setIsLoaded(true);
    }
    loadPosts();
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(Number(value));
  };
  return (
    <>
      <div>
        <Header />
        <Nav />
        <center>
        <BannerTitle>Noticias</BannerTitle>
        
          <BannerList
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {isLoaded ? (
              posts.map((item) => {
                return (
                  <>
                    <ListItem key={item.slug}>
                      <img
                        src={item._embedded["wp:featuredmedia"]["0"].source_url}
                        srcSet={item._embedded["wp:featuredmedia"]["0"].source_url}
                        width={`300px`}
                        alt={item.title.rendered}
                        loading="lazy"
                      />
                      <PostTitle to={`/noticias/${item.slug}`}>
                        {item.title.rendered}
                      </PostTitle>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                );
              })
            ) : (
              <></>
            )}
          </BannerList>
        </center>
        {isLoaded ? (
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            style={{ margin: "20px" }}
          />
        ) : (
          <NoContent>No hay noticias que mostrar.</NoContent>
        )}

        <center>
          <Footer />
        </center>
      </div>
    </>
  );
};

export default Noticias;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/usoEficiente.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const AsistenciaHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState("./datasets/inventario/inventarioEmisionesOficialMedidas.csv")

  //Descomentar en caso de que se quiera agregar Compromiso Referencial y Estado de implementacion a la ficha regional
  //Contiene el compromiso referencial y estado de implementacion de la region 
  /* const [indicadores, setIndicadores] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const indicadoresPorRegion= {
      "region_de_arica_y_parinacota": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_tarapaca": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_antofagasta": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_atacama": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_coquimbo": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_valparaiso": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_metropolitana": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_ohiggins": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_del_maule": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_del_nuble": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_del_biobio": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_la_araucania": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_los_rios": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_los_lagos": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_aysen": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
      "region_de_magallanes": {"compromiso_referencial": 0, "estado_de_implementacion": 0},
    }
 */
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Asistencia_Técnica_al_Uso_Eficiente_de_Fertilizantes"
          )
        )
      ) {
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"])
        setPolicyInRegion(true);
        //const [key, value] = Object.entries(indicadoresPorRegion).find(([key,value]) => key === region.name);
        //setIndicadores(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(
        dataset[40]["Asistencia técnica al uso eficiente de fertilizantes"]
      );
      set2050(
        dataset[60]["Asistencia técnica al uso eficiente de fertilizantes"]
      );
    })
    .catch((error) => {
      console.error(error);
    });
  return (
    <HelpContent>
      <div>
        <h1>Asistencia Técnica al Uso Eficiente de Fertilizantes</h1>
      </div>
      <h4>Sector Agricultura</h4>
      <div>
        <p>
          <img
            src={proyeccionParticipacion}
            style={{ float: "right", marginBottom: "2em" }}
            alt="Proyección de participación de motores eficientes"
          />
          Esta medida considera la implementación de un Programa integral de
          capacitación, cooperación y apoyo técnico para mejorar la utilización
          de fertilizantes en cultivos, específicamente las prácticas asociadas
          a los usos excesivos de fertilizantes minerales.
        </p>

        <p>
          Se analizan cuatro tipos de fertilizantes nitrogenados,
          específicamente urea, salitre potásico, salitre sódico y fosfato de
          amonio, los cuales corresponden a los fertilizantes nitrogenados
          provistos por ODEPA como insumos de los productores.
        </p>
      </div>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion ? (
            <>
            {/* Descomentar en caso de que se quiera agregar los indicadores a la ficha*/}
            {/* 
            <Columnas>
              <WrapperExp table>
              <h5 style={{fontWeight:800, fontSize:"16px"}}>Compromiso referencial: <br/></h5>
              <p>

                <span>{indicadores ? indicadores["compromiso_referencial"] : <Loader/>}</span>
              </p>
              </WrapperExp>
              <WrapperExp table>
              <h5 style={{fontWeight:800, fontSize:"16px"}}>Estado de implementación de la medida: <br/></h5>
              <p>
                <span>{indicadores ? indicadores["estado_de_implementacion"] : <Loader/>}</span>
              </p>
              </WrapperExp>
            </Columnas>
           */}
            <WrapperExp notTable>
            <h5 style={{fontWeight:800, fontSize:"16px"}}>Reducción de emisiones esperada por el Observatorio: <br/></h5>
            <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {" "}
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
            </>
          ) : (
            <WrapperExp notTable>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,1 (2030) y 0,2 (2050) </span>
              </p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {" "}
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.1, 0.2, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default AsistenciaHelp;
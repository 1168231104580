import React,  { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'components/layout/Header';
import Container from 'components/layout/Container';
import Loader from 'components/ui/Loader';
import DashboardContainer from 'containers/DashboardContainer';
import { POLICIES_FILE_PATH } from 'config';
import NavSectorial from 'components/layout/NavSectorial';
import Footer from 'components/layout/Footer';

/**
 * Dashboard.js
 * En este componente se realiza la carga de la información de los escenarios.
 */
const Dashboard = () => {
  // const [scenarios, setScenarios] = useState(null);
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    // Load scenarios json file
    axios.get(POLICIES_FILE_PATH)
      .then(response => setPolicies(response.data))
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <div>
      <Header />
      <NavSectorial/>
      <Container>
        {
          policies ? (
            <DashboardContainer policies={policies}/>
          ) : (
            <Loader />
          )
        }
      </Container>
      <div>
    <br/>
    <center>
    <Footer/>
    </center>
    </div>
    </div>
  );
};

export default Dashboard;

import React,  { useState } from 'react';
import styled from 'styled-components';

const Link = styled.div`
  font-size: 12px;
  width: 100%;
  text-align: right;
  padding: 10px 10px 0 0;
  text-decoration: underline;
  color:  #666;
  cursor: pointer;
`;

const Info = styled.div`
  width: 100%;
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 3px;
  text-align: justify;
`;

const always = (option, infoVisible, label, setInfoVisible) =>{
  if (option){
    return
  } else{
    return (
      <Link onClick={() => setInfoVisible(!infoVisible)}>
        { infoVisible ? 'Ocultar' : label }
      </Link>
    )
  }
}

const MoreInfo = ({ label, children, option}) => {
  const [infoVisible, setInfoVisible] = useState(option ? true : false);

  return (
    <div>
      {always(option, infoVisible, label, setInfoVisible)}
      {
        infoVisible && (
          <Info>
            { children }
          </Info>
        )
      }
    </div>
  );
};

export default MoreInfo;

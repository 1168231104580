import React, { useState } from "react";
import ChartModeHome from "./ChartModeHome";
import ChartModeHome2 from "./ChartModeHome2";
import styled from "styled-components";
const Wrapper = styled.div`
  display: flex;
  @media (max-width: 720px) {
    display: block;
  }
`;

const DashboardContainerInventarioHome = ({ policies, inv }) => {
  // Opciones del menú izquierdo, por ejemplo: Instrumentos económicos
  const medidaSettings = policies.find(
    (item) => item.name === "Seleccionar Autoridad Climática"
  ).settings;

  // Obtenemos la ruta del set de datos, basado en la configuración de las medidas.
  const currentAutoridad_Sectorial = policies.find(
    (item) => item.name === "Seleccionar Autoridad Climática"
  );

  // const [medidas, setMedidas] = useState(policies.find(item => item.name === "Transporte").medidas)
  // setup visible charts
  const [visibleCharts, setVisibleCharts] = useState([true]);

  // whether or not we're alread showing all the possible charts
  const amountVisible = visibleCharts.filter((item) => item).length;
  const medidasActivas = [];

  // hide chart on index === i
  const hideChart = (i) => {
    setVisibleCharts(
      visibleCharts.map((item, j) => {
        if (i === j) {
          return false;
        }
        return item;
      })
    );
  };

  return (
    <div>
      <Wrapper>
        {visibleCharts.map(
          (visible, i) =>
            visible &&
            (inv === "inventario2" ? (
              <ChartModeHome
                key={0}
                index={0}
                onHide={amountVisible > 1 ? () => hideChart(0) : null}
                currentScenario={currentAutoridad_Sectorial}
                settings={medidaSettings}
                medidasActivas={medidasActivas}
                type={"inventario2"}
              />
            ) : (
              <ChartModeHome2
                key={1}
                index={1}
                onHide={amountVisible > 1 ? () => hideChart(1) : null}
                currentScenario={currentAutoridad_Sectorial}
                settings={medidaSettings}
                medidasActivas={medidasActivas}
                type={"inventario3"}
              />
            ))
        )}
      </Wrapper>
    </div>
  );
};

export default DashboardContainerInventarioHome;

import React from "react";
import { func, object } from "prop-types";
import AsistenciaHelp from "components/help/helpMedidas/AsistenciaHelp";
import BiodigestoresHelp from "components/help/helpMedidas/BiodigestoresHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";
const chooseHelp = (medida) => {
  if (medida === "Asistencia técnica al uso eficiente de fertilizantes") {
    return <AsistenciaHelp />;
  } else if (medida === "Biodigestores de purines porcinos") {
    return <BiodigestoresHelp />;
  }
  return null;
};
const AgriculturaPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="Agricultura"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

AgriculturaPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default AgriculturaPolicies;

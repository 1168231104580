import React, { useState } from "react";
import styled from "styled-components";
// import ModeSelection from './ModeSelection';
import Grafico from "./Chart";
import { inventoryChartSettings2 } from "config";
import {
  MEDIDAS,
  MIN_YEAR,
  YEAR_INVENTORY,
  YEAR_UPDATE,
} from "utils/constants";
import MoreInfoHome from "components/ui/MoreInfoHome";

const Wrapper = styled.div`
  position: relative;
  ${(props) => props.index > 0 && "margin-top: 10px;"}
  max-width:1400px;
`;

const ChartModeHome = ({
  index,
  onHide,
  currentScenario,
  settings,
  medidasActivas,
  type,
}) => {
  // que reciba tambien si es Sector o autoridad, para hacer la bajada a los kpi y determinar contribucion
  // Modo del gráfico: Por ejemplo: Emisiones, generacion etc,.
  const mode =
    type === "inventario2"
      ? inventoryChartSettings2[0].id
      : inventoryChartSettings2[1].id;
  const min_max = [
    MIN_YEAR,
    type === "inventario"
      ? YEAR_INVENTORY
      : type === "inventario2"
      ? YEAR_UPDATE
      : 2050,
  ];
  const [years, setYears] = useState([
    MIN_YEAR,
    type === "inventario"
      ? YEAR_INVENTORY
      : type === "inventario2"
      ? YEAR_UPDATE
      : 2050,
  ]);
  // Multiple or single scenario
  const multipleScenarios = false;

  // Obtener opciones de configuración del gráfico actual
  const currentChartSettings = inventoryChartSettings2.find(
    (item) => item.id === mode
  );

  // get dataset path based on current scenario
  const datasetPath = currentScenario ? currentScenario.datasets[mode] : null;
  const noUsadas = (array, cate) => {
    for (let i in array) {
      if (array[i] === cate) {
        return false;
      }
    }
    return true;
  };
  let medidasNoUsadas = MEDIDAS.filter((item) =>
    noUsadas(medidasActivas, item)
  );

  return (
    <Wrapper index={index}>
      {currentChartSettings.description && (
        <MoreInfoHome label="Más información" option={true} tipo={1}>
          {currentChartSettings.description}
        </MoreInfoHome>
      )}
      {multipleScenarios ? (
        <Grafico
          id={`multiple_scenario_container` + index}
          mode={mode}
          currentScenario={currentScenario}
          datasetPath={`/datasets/${mode}/Escenarios.csv`}
          chartSettings={currentChartSettings}
          isMultiscenario
          settings={settings}
          years={years}
          min_year={min_max[0]}
          max_year={min_max[1]}
          setYears={setYears}
          medidasActivas={medidasNoUsadas}
          selected={"home"}
        />
      ) : (
        <Grafico
          id={`chart_container` + index}
          mode={mode}
          currentScenario={currentScenario}
          datasetPath={datasetPath}
          chartSettings={currentChartSettings}
          kpiMode={currentChartSettings ? "nacional" : null}
          allowDownloads
          settings={settings}
          years={years}
          min_year={min_max[0]}
          max_year={min_max[1]}
          setYears={setYears}
          medidasActivas={medidasNoUsadas}
          selected={"home"}
        />
      )}
    </Wrapper>
  );
};

export default ChartModeHome;

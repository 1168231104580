import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";
import GeneracionDistribuidaHelp from "components/help/helpMedidas/GeneracionDistribuidaHelp";
import FomentoRenovacionHelp from "components/help/helpMedidas/FomentoRenovacionHelp";
import CalefaccionPublicoComercialHelp from "components/help/helpMedidas/CalefaccionPublicoComercialHelp";
import SistemasSolaresHelp from "components/help/helpMedidas/SistemasSolaresHelp";
import ElectrificacionMotrizComercialHelp from "components/help/helpMedidas/ElectrificacionMotrizComercialHelp";
import BombasGeoHelp from "components/help/helpMedidas/BombasGeoHelp";
import RtViviendasHelp from "components/help/helpMedidas/RtViviendasHelp";
import UsoTermicoGasoductosHelp from "components/help/helpMedidas/UsoTermicoGasoductosHelp";
import CalefaccionDistritalHelp from "components/help/helpMedidas/CalefaccionDistritalHelp";
import MepsNuevosHelp from "components/help/helpMedidas/MepsNuevosHelp";

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (medida === "Generación distribuida") {
    return <GeneracionDistribuidaHelp region={region} />;
  } else if (medida === "Fomento a renovación energética de viviendas") {
    return <FomentoRenovacionHelp region={region} />;
  } else if (medida === "Calefacción eléctrica público comercial") {
    return <CalefaccionPublicoComercialHelp region={region} />;
  } else if (medida === "Sistemas solares térmicos – residencial y público") {
    return <SistemasSolaresHelp region={region} />;
  } else if (medida === "Electrificación motriz comercial") {
    return <ElectrificacionMotrizComercialHelp region={region} />;
  } else if (medida === "Bombas Geotérmicas de Calor") {
    return <BombasGeoHelp region={region} />;
  } else if (medida === "RT viviendas vulnerables") {
    return <RtViviendasHelp region={region} />;
  } else if (medida === "Uso térmico vía gasoductos - Hidrógeno") {
    return <UsoTermicoGasoductosHelp region={region} />;
  } else if (medida === "Calefacción distrital") {
    return <CalefaccionDistritalHelp region={region} />;
  } else if (medida === "MEPS Nuevos") {
    return <MepsNuevosHelp region={region} />;
  }
  return null;
};

// Crea un container con la lista de medidas que se puede seleccionar para 'Otros Sectores'
const OtrosSectoresPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="OTROS SECTORES: COMERCIAL, PÚBLICO Y RESIDENCIAL"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

OtrosSectoresPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default OtrosSectoresPolicies;

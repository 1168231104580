import React from 'react';
import { func, object } from 'prop-types';
import UsoLodosHelp from 'components/help/helpMedidas/UsoLodosHelp';
import AsistenciaHelp from 'components/help/helpMedidas/AsistenciaHelp';
import BiodigestoresHelp from 'components/help/helpMedidas/BiodigestoresHelp';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';
// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "Usos lodos plantas tratamiento aguas servidas, bioestabilizador forestal"){
        return <UsoLodosHelp/>;
    }else if (medida === "Asistencia técnica al uso eficiente de fertilizantes"){
        return <AsistenciaHelp/>;
    }else if(medida ==="Biodigestores de purines porcinos"){
        return <BiodigestoresHelp/>;
    }
    return null;
}
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Agricultura
const MinisterioAgriculturaPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => {
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Agricultura'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
    );
}

MinisterioAgriculturaPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
export default MinisterioAgriculturaPolicies;
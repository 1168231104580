import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import domToImage from "dom-to-image";
import { saveAs } from "file-saver";
import { string, bool } from "prop-types";
import axios from "axios";
import ChartJs from "chart.js";
import Color from "color";
import Loader from "components/ui/Loader";
import Message from "components/ui/Message";
import Slider from "@material-ui/core/Slider";
import KPI from "./KPI";
import EmisionesChart from "./EmisionesChart";
import csvtojson from "csvtojson/v2";
import XLSX from "xlsx";
import Chart from "chart.js";
import { chartSettings as charts } from "config";
import {
  YEAR_CATEGORY,
  COLORS,
  YEAR_INVENTORY,
  YEAR_UPDATE,
  YEAR_UPDATE_NDC,
  MEDIDAS,
  Autoridades,
  medidasPorMinisterio,
  sectoresEmisores,
  IPPU,
  Electricidad,
  desagregacion,
} from "utils/constants";
import stackFill from "utils/plugin.stackFill";
import {
  parseValue,
  categoryLabel,
  truncOneDecimal,
  toOneDecimal,
} from "utils/helpers";
import Select from "components/ui/Select";
import PdfGenerator from "utils/generate-pdf/pdfGenerator";
import { pdf } from "@react-pdf/renderer";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
Chart.plugins.register(ChartDataLabels);
Chart.plugins.register(zoomPlugin);

/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

/**
 *  Obtenener el color de fondo de usa serie
 *  basado en el color de la serie
 */
const getBg = (code) => {
  if (code === "rgba(0, 0, 0, 1)") {
    return "rgba(0, 0, 0, 0.5)";
  }
  const color = Color(code);
  return color.alpha(0.4).rgb().string();
};

/**
 * Determinar si la serie actual corresponde al escenario base
 */
const isBase = (scenario) => {
  const scenarioName = scenario.toLowerCase();
  return scenarioName === "escenario base" || scenarioName === "base";
};

/**
 * Determinar la altura del canvas para el gráfico
 */
const getCanvasHeight = (categories, mode, selected) => {
  const W = window.innerWidth;
  if (W < 720) {
    return 300;
  } else if (W < 1200 || (W < 1600) & (selected === "home")) {
    return 180;
  } else {
    return 100;
  }
};

const getCanvasHeightPDF = (categories, mode) => {
  return 300 + 10 * categories.length;
};

/**
 * Initializa el gráfico, dados los datos y los parámetros de configuración del escenario.
 */
let chart = null;
const initChart = (
  selected,
  setKpiDict,
  setKpiAmounts,
  kpiDict,
  kpiYear,
  medidasActivas,
  minYear,
  maxYear,
  datasetPath,
  chartSettings,
  setError,
  setLoading,
  setChart,
  scenarioName,
  id,
  isMultiscenario,
  pdf
) => {
  let elem = document.getElementById(id);
  if (pdf) {
    elem.innerHTML = "<h4>Cargando gráfico...</h4>";
  } else {
    if (elem) {
      elem.innerHTML = "<h4>Cargando gráfico...</h4>";
    } else {
      return null;
    }
    setLoading(true);
  }
  const check = (cate, array) => {
    for (let i in array) {
      if (array[i] === cate) {
        return true;
      }
    }
    return false;
  };
  const checkFalse = (cate, array) => {
    for (let i in array) {
      if (array[i] === cate) {
        return false;
      }
    }
    return true;
  };
  getData(datasetPath)
    .then((dataset) => {
      // get list of categories
      let cats = Object.keys(dataset[0]).filter((cat) => cat !== YEAR_CATEGORY);
      if (chartSettings.id === "emisiones_netas" && !isMultiscenario) {
        cats.push("Emisiones Netas");
      }
      if (
        chartSettings.id === "inventario" ||
        chartSettings.id === "inventario_neto"
      ) {
        cats.push("Inventario");
      }
      if (
        (chartSettings.id === "inventario_proyectado_neto_barras" ||
          chartSettings.id === "inventario_proyectado_barras" ||
          chartSettings.id === "inventario_proyectado" ||
          chartSettings.id === "inventario_proyectado_neto" ||
          chartSettings.id === "inventario_actualizado" ||
          chartSettings.id === "inventario_actualizado_neto" ||
          chartSettings.id === "inventario_con_medidas" ||
          chartSettings.id === "inventario_con_medidas_solo_oficial" ||
          chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
          chartSettings.id === "inventario_con_medidas_observatorio" ||
          chartSettings.id === "inventario_actualizado_barras") &&
        !isMultiscenario
      ) {
        cats.push("Inventario");
        cats.push("Estimación Inventario");
      }
      if (
        chartSettings.id === "inventario_proyectado_neto_barras" ||
        chartSettings.id === "inventario_proyectado_barras" ||
        chartSettings.id === "inventario_proyectado_neto" ||
        chartSettings.id === "inventario_proyectado" ||
        chartSettings.id === "inventario_con_medidas" ||
        chartSettings.id === "inventario_con_medidas_solo_oficial" ||
        chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
        chartSettings.id === "inventario_con_medidas_observatorio"
      ) {
        cats.push("Min Observatorio");
        cats.push("Max Observatorio");
      }
      if (
        chartSettings.id === "inventario_proyectado_neto" ||
        chartSettings.id === "inventario_proyectado" ||
        chartSettings.id === "inventario_con_medidas" ||
        chartSettings.id === "inventario_con_medidas_solo_oficial" ||
        chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
        chartSettings.id === "inventario_con_medidas_observatorio"
      ) {
        cats.push("Carbon Budget");
      }

      if (
        chartSettings.id === "inventario_con_medidas" ||
        chartSettings.id === "inventario_con_medidas_solo_oficial" ||
        chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
        chartSettings.id === "inventario_con_medidas_observatorio"
      ) {
        cats.push("Emisiones con Medidas");
      }

      if (
        (chartSettings.id === "inventario_proyectado" ||
          chartSettings.id === "inventario_proyectado_neto" ||
          chartSettings.id === "inventario_actualizado") &&
        !isMultiscenario
      ) {
        cats.push("Min Inventario");
        cats.push("Max Inventario");
      }

      let categories = cats.filter((item) => checkFalse(item, medidasActivas));
      let año_minimo = minYear;
      let año_maximo = maxYear;
      let years = [];
      for (let year = año_minimo; year <= año_maximo; year++) {
        years.push(year);
      }

      const canvasId = Math.random();
      if (pdf) {
        elem.innerHTML = `<canvas id="${canvasId}" height="${getCanvasHeightPDF(
          categories,
          chartSettings.id
        )}" width="${800}" ></canvas>`;
      } else {
        elem.innerHTML = `<canvas id="${canvasId}" height="${getCanvasHeight(
          categories,
          chartSettings.id,
          selected
        )}"></canvas>`;
        setError(false);
      }

      const calculoPorcentajeMedidas = (medida) => {
        if (check(scenarioName, Autoridades)) {
          return medidasPorMinisterio[scenarioName][medida];
        } else {
          return 1;
        }
      };

      const calculoPorMinisterio = (ministerio, diccionario) => {
        if ("Ministerio de Energía" === ministerio) {
          return (
            diccionario["Consumo Propio"] +
            diccionario["Emisiones fugitivas de combustibles"] +
            diccionario["Comercial"] +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              (IPPU["Industrial"] + IPPU["Comercial"] + IPPU["Energía"]) +
            diccionario["Industria"] +
            (Electricidad["Industria"] +
              Electricidad["Comercial"] +
              Electricidad["Consumo Propio"]) *
              diccionario["Electricidad"]
          );
        } else if (
          "Ministerio de Transportes y Telecomunicaciones" === ministerio
        ) {
          return (
            diccionario["Transporte"] +
            Electricidad["Transporte"] * diccionario["Electricidad"] +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              IPPU["Transporte"]
          );
        } else if ("Ministerio de Salud" === ministerio) {
          return (
            diccionario["Residuos No aguas"] +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              IPPU["Salud"]
          );
        } else if ("Ministerio de Obras Públicas" === ministerio) {
          return (
            diccionario["Público"] +
            (diccionario["Residuos"] - diccionario["Residuos No aguas"]) +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              IPPU["MOP"] +
            diccionario["Electricidad"] * Electricidad["Público"]
          );
        } else if ("Ministerio de Vivienda y Urbanismo" === ministerio) {
          return (
            diccionario["Residencial"] +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              IPPU["Residencial"] +
            diccionario["Electricidad"] * Electricidad["Residencial"]
          );
        } else if ("Ministerio de Minería" === ministerio) {
          return (
            diccionario["Minería"] +
            diccionario["Procesos industriales y uso de productos (IPPU)"] *
              IPPU["Minería"] +
            Electricidad["Minería"] * diccionario["Electricidad"]
          );
        } else if ("Ministerio de Agricultura" === ministerio) {
          return diccionario["Agricultura"];
        } else {
          return 0;
        }
      };

      // Y AXES
      const yAxes = [
        {
          id: "non-stacked",
          stacked: false,
          scaleLabel: {
            display: true,
            labelString: chartSettings.unit || "",
          },
          gridLines: {
            color: "#eeeeee",
          },
        },
        {
          id: "stacked",
          display: false,
          stacked: chartSettings.stacked,
          gridLines: {
            color: "#eeeeee",
          },
        },
      ];
      let a = MEDIDAS;

      const ticks = {
        suggestedMin: chartSettings.setScale ? chartSettings.minValue : null,
        suggestedMax: chartSettings.setScale ? chartSettings.maxValue : null,

        //suggestedMin: chartSettings.minValue || 0,
        //suggestedMax: chartSettings.maxValue,
        stepSize: chartSettings.stepSize,
      };
      yAxes[0].ticks = ticks;
      yAxes[1].ticks = ticks;

      const electricidad = categories.filter((cat) => cat === "electricidad");
      if (electricidad.length) {
        yAxes.push({
          id: "elec-axis",
          display: true,
          stacked: false,
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "USD/MWh",
          },
          ticks: {
            min: isBase(scenarioName) ? 99 : 190,
            max: isBase(scenarioName) ? 100.1 : 215,
          },
        });
      }

      // Allow only data in the date range
      let filteredDataset = {};
      let sum_ref = 0;
      let sum_med = 0;
      let carbon_utilizado = 0;
      let carb_neut = false;
      let med_tot = 0;
      let tco2_2030 = 0;
      let tco2_entre_2020_2030 = 0;
      let tco2_entre_2020_2030_ref = 0;
      let co2_entre_2020_2030_sector = {
        "Industrias de la energía": 0,
        "Industrias manufactureras y de la construcción": 0,
        Transporte: 0,
        "Otros sectores": 0,
        "No especificado": 0,
        "Emisiones fugitivas de combustibles": 0,
        "Transporte y almacenamiento de CO2": 0,
        "Procesos industriales y uso de productos (IPPU)": 0,
        Agricultura: 0,
        Residuos: 0,
        "Residuos No aguas": 0,
        "Consumo Propio": 0,
        Electricidad: 0,
        Industria: 0,
        Minería: 0,
        Público: 0,
        Comercial: 0,
        Residencial: 0,
      };
      if (
        (chartSettings.id === "emisiones_netas" ||
          chartSettings.id === "inventario_proyectado_barras" ||
          chartSettings.id === "inventario_proyectado_neto_barras" ||
          chartSettings.id === "inventario" ||
          chartSettings.id === "inventario_proyectado_neto" ||
          chartSettings.id === "inventario_proyectado" ||
          chartSettings.id === "inventario_con_medidas" ||
          chartSettings.id === "inventario_con_medidas_solo_oficial" ||
          chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
          chartSettings.id === "inventario_con_medidas_observatorio" ||
          chartSettings.id === "inventario_actualizado" ||
          chartSettings.id === "inventario_actualizado_neto" ||
          chartSettings.id === "inventario_actualizado_barras" ||
          chartSettings.id === "inventario_neto") &&
        !isMultiscenario
      ) {
        filteredDataset = dataset
          .filter((item) => {
            const year = parseInt(item[YEAR_CATEGORY]);
            return year >= año_minimo && year <= año_maximo;
          })
          .map((item) => {
            let sum = 0;
            categories.forEach((category) => {
              if (
                !isBase(category) &&
                category !== "Proyeccion Observatorio" &&
                category !== "Min Acelerada" &&
                category !== "Max Acelerada" &&
                category !== "Min Neta" &&
                category !== "Max Neta" &&
                category !== "Proyeccion NDC oficial" &&
                category !== "Escenario Referencia" &&
                category !== "Emisiones con Medidas"
              ) {
                if (checkFalse(category, desagregacion)) {
                  sum += truncOneDecimal(parseValue(item[category]));
                }
                if (
                  item["año"] >= 2020 &&
                  item["año"] <= 2030 &&
                  check(category, sectoresEmisores)
                ) {
                  if (category === "Residuos No aguas") {
                    co2_entre_2020_2030_sector[category] +=
                      parseValue(item["Residuos"]) * parseValue(item[category]);
                  } else {
                    co2_entre_2020_2030_sector[category] += parseValue(
                      item[category]
                    );
                  }
                }
              }
            });
            if (chartSettings.id === "emisiones_netas" && !isMultiscenario)
              item["Emisiones Netas"] = sum.toFixed(1).toString();
            if (
              (chartSettings.id === "inventario_proyectado_barras" ||
                chartSettings.id === "inventario" ||
                chartSettings.id === "inventario_neto" ||
                chartSettings.id === "inventario_proyectado_neto_barras" ||
                chartSettings.id === "inventario_con_medidas" ||
                chartSettings.id === "inventario_con_medidas_solo_oficial" ||
                chartSettings.id ===
                  "inventario_con_medidas_sin_proyecciones" ||
                chartSettings.id === "inventario_con_medidas_observatorio" ||
                chartSettings.id === "inventario_proyectado_neto" ||
                chartSettings.id === "inventario_proyectado" ||
                chartSettings.id === "inventario_actualizado" ||
                chartSettings.id === "inventario_actualizado_neto" ||
                chartSettings.id === "inventario_actualizado_barras") &&
              !isMultiscenario
            )
              if (item["año"] <= YEAR_INVENTORY) {
                item["Inventario"] = sum.toFixed(1).toString();
                item["Proyeccion Observatorio"] = null;
                item["Escenario Referencia"] = null;
                if (
                  chartSettings.id === "inventario_con_medidas" ||
                  chartSettings.id === "inventario_con_medidas_solo_oficial" ||
                  chartSettings.id ===
                    "inventario_con_medidas_sin_proyecciones" ||
                  chartSettings.id === "inventario_con_medidas_observatorio"
                ) {
                  item["Emisiones con Medidas"] = null;
                  for (let medida in a) {
                    item[a[medida]] = null;
                  }
                }
              }
            if (item["año"] < YEAR_UPDATE_NDC) {
              item["Proyeccion NDC oficial"] = null;
            }
            if (item["año"] >= YEAR_INVENTORY && item["año"] <= YEAR_UPDATE) {
              item["Estimación Inventario"] = sum.toFixed(1).toString();
              item["Min Inventario"] = (
                parseValue(sum.toFixed(1).toString()) -
                (0.195 * item["año"] - 390.6)
              )
                .toFixed(1)
                .toString();
              item["Max Inventario"] = (
                parseValue(sum.toFixed(1).toString()) +
                (0.195 * item["año"] - 390.6)
              )
                .toFixed(1)
                .toString();
            }
            if (item["año"] >= 2020 && item["año"] <= YEAR_UPDATE) {
              carbon_utilizado += parseValue(sum.toFixed(1));
              tco2_entre_2020_2030 += parseValue(sum.toFixed(1));
              tco2_entre_2020_2030_ref += parseValue(sum.toFixed(1));
            }
            if (item["año"] >= YEAR_INVENTORY && item["año"] < YEAR_UPDATE) {
              item["Proyeccion Observatorio"] = null;
              item["Escenario Referencia"] = null;
              if (
                chartSettings.id === "inventario_con_medidas" ||
                chartSettings.id === "inventario_con_medidas_solo_oficial" ||
                chartSettings.id ===
                  "inventario_con_medidas_sin_proyecciones" ||
                chartSettings.id === "inventario_con_medidas_observatorio"
              ) {
                item["Emisiones con Medidas"] = null;
                for (let medida in a) {
                  item[a[medida]] = null;
                }
              }
            }
            if (
              item["año"] >= YEAR_UPDATE &&
              (chartSettings.id === "inventario_con_medidas" ||
                chartSettings.id === "inventario_con_medidas_solo_oficial" ||
                chartSettings.id ===
                  "inventario_con_medidas_sin_proyecciones" ||
                chartSettings.id === "inventario_con_medidas_observatorio")
            ) {
              item["Emisiones con Medidas"] = item["Escenario Referencia"];
              for (let medida in a) {
                if (checkFalse(a[medida], medidasActivas)) {
                  item["Emisiones con Medidas"] = (
                    item["Emisiones con Medidas"] -
                    item[a[medida]] * calculoPorcentajeMedidas(a[medida])
                  )
                    .toFixed(1)
                    .toString();
                  item[a[medida]] = item["Emisiones con Medidas"];
                } else {
                  item[a[medida]] = null;
                }
              }

              sum_med += parseValue(item["Emisiones con Medidas"]);
              if (Number(item["año"]) === kpiYear) {
                med_tot = item["Emisiones con Medidas"];
                sum_ref = parseValue(item["Escenario Referencia"]);
                carb_neut = item["Emisiones con Medidas"] <= 0;
              }
              if (Number(item["año"]) === 2030) {
                tco2_2030 = Number(item["Emisiones con Medidas"]);
              }
              if (item["año"] > YEAR_UPDATE && item["año"] <= 2030) {
                tco2_entre_2020_2030_ref += parseValue(
                  item["Escenario Referencia"]
                );
                tco2_entre_2020_2030 += parseValue(
                  item["Emisiones con Medidas"]
                );
              }
            }
            if (
              chartSettings.id === "inventario_con_medidas" ||
              chartSettings.id === "inventario_con_medidas_solo_oficial" ||
              chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
              chartSettings.id === "inventario_con_medidas_observatorio" ||
              chartSettings.id === "inventario_proyectado"
            ) {
              if (item["año"] >= YEAR_UPDATE_NDC && item["año"] <= 2030) {
                item["Carbon Budget"] = parseValue(
                  item["Proyeccion NDC oficial"]
                )
                  .toFixed(1)
                  .toString();
              }
              if (item["año"] < YEAR_UPDATE_NDC || item["año"] > 2030) {
                item["Carbon Budget"] = null;
              }
            }
            if (
              chartSettings.id === "inventario_proyectado_barras" ||
              chartSettings.id === "inventario_proyectado_neto_barras" ||
              chartSettings.id === "inventario_proyectado_neto" ||
              chartSettings.id === "inventario_proyectado" ||
              chartSettings.id === "inventario_con_medidas" ||
              chartSettings.id === "inventario_con_medidas_solo_oficial" ||
              chartSettings.id === "inventario_con_medidas_sin_proyecciones" ||
              chartSettings.id === "inventario_con_medidas_observatorio"
            ) {
              if (item["año"] >= YEAR_UPDATE) {
                item["Min Observatorio"] = (
                  parseValue(item["Proyeccion Observatorio"]) -
                  (0.194 * item["año"] - 390.6)
                )
                  .toFixed(1)
                  .toString();
                item["Max Observatorio"] = (
                  parseValue(item["Proyeccion Observatorio"]) +
                  (0.194 * item["año"] - 390.6)
                )
                  .toFixed(1)
                  .toString();
              }
            }
            return item;
          })
          .map((item) => {
            categories.forEach((category) => {
              if (
                category !== "Min Observatorio" &&
                category !== "Max Observatorio" &&
                category !== "Min Inventario" &&
                category !== "Max Inventario" &&
                category !== "Estimación Inventario" &&
                category !== "Carbon Budget" &&
                !check(category, a) &&
                checkFalse(category, desagregacion)
              ) {
                if (
                  item["año"] >= YEAR_INVENTORY &&
                  category !== "Inventario"
                ) {
                  if (item["año"] <= YEAR_UPDATE) {
                    if (
                      category !== "Proyeccion NDC oficial" &&
                      category !== "Proyeccion Observatorio" &&
                      category !== "Escenario Referencia" &&
                      category !== "Emisiones con Medidas"
                    ) {
                      item[category] = parseValue(item[category])
                        .toFixed(1)
                        .toString();
                    }
                  } else {
                    item[category] = parseValue(item[category])
                      .toFixed(1)
                      .toString();
                  }
                } else if (
                  item["año"] <= YEAR_INVENTORY &&
                  category !== "Estimación Inventario" &&
                  category !== "Proyeccion NDC oficial" &&
                  category !== "Proyeccion Observatorio" &&
                  category !== "Escenario Referencia" &&
                  category !== "Emisiones con Medidas" &&
                  check(category, MEDIDAS) === false
                ) {
                  item[category] = parseValue(item[category])
                    .toFixed(1)
                    .toString();
                } else if (
                  item["año"] >= YEAR_UPDATE &&
                  category !== "Estimación Inventario" &&
                  category !== "Inventario"
                ) {
                  item[category] = parseValue(item[category])
                    .toFixed(1)
                    .toString();
                }
              }
            });
            return item;
          });
      } else {
        filteredDataset = dataset
          .filter((item) => {
            const year = parseInt(item[YEAR_CATEGORY]);
            return year >= año_minimo && year <= año_maximo;
          })
          .map((value) => toOneDecimal(value));
      }

      setKpiAmounts([med_tot, sum_ref - med_tot]);
      setKpiDict({
        ...kpiDict,
        "Carbono Neutralidad": carb_neut,
        "Carbono Utilizado": carbon_utilizado,
        "Año Update": YEAR_UPDATE,
        tco2_2030: tco2_2030,
        tco2_entre_2020_2030: tco2_entre_2020_2030,
        tco2_entre_2020_2030_ref: tco2_entre_2020_2030_ref,
        sum_med: sum_med,
        co2_entre_2020_2030_autoridad: calculoPorMinisterio(
          selected,
          co2_entre_2020_2030_sector
        ),
      });
      const ctx = elem.getElementsByTagName("canvas")[0]
        ? elem.getElementsByTagName("canvas")[0].getContext("2d")
        : null;
      if (ctx) {
        const colors = chartSettings.customColors || COLORS;
        const defaultSettings = {
          type: "line",
          borderWidth: 1.6,
          pointRadius: 2,
          pointHitRadius: 10,
          fill: false,
          stackFill: true,
        };
        const barSettings = {
          type: "bar",
          borderWidth: 1,
          pointRadius: 0.5,
          pointHitRadius: 10,
          fill: false,
          stackFill: true,
        };

        categories = categories.filter((item) =>
          checkFalse(item, desagregacion)
        );
        chart = new ChartJs(ctx, {
          type:
            chartSettings.chartType && !isMultiscenario
              ? chartSettings.chartType
              : "line",
          plugins: [stackFill, ChartDataLabels, zoomPlugin],
          data: {
            datasets: [
              ...categories
                .filter(
                  (cat) =>
                    !isBase(cat) &&
                    cat !== "Emisiones Netas" &&
                    cat !== "Inventario" &&
                    cat !== "electricidad" &&
                    cat !== "Proyeccion Observatorio" &&
                    cat !== "Max Observatorio" &&
                    cat !== "Min Observatorio" &&
                    cat !== "Proyeccion NDC oficial" &&
                    chartSettings.id !== "inventario_proyectado" &&
                    chartSettings.id !== "inventario_proyectado_neto" &&
                    cat !== "Estimación Inventario" &&
                    chartSettings.id !== "inventario_actualizado" &&
                    chartSettings.id !== "inventario_con_medidas" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones" &&
                    chartSettings.id !== "inventario_con_medidas_observatorio"
                )
                .map((cat, i) => ({
                  label: categoryLabel(cat),
                  borderColor: colors[i],
                  backgroundColor:
                    chartSettings.stacked && !isMultiscenario
                      ? getBg(colors[i], chartSettings.id)
                      : "transparent",
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: isMultiscenario ? "non-stacked" : "stacked",
                  order: 2,
                  ...(chartSettings.chartType === "bar" && !isMultiscenario
                    ? barSettings
                    : defaultSettings),
                  datalabels: {
                    display: false,
                  },
                })),
              ...categories
                .filter((cat) => cat === "Estimación Inventario")
                .map((cat) => ({
                  label: categoryLabel(cat),
                  borderColor: "red",
                  backgroundColor: "transparent",
                  order: 0,
                  borderDash: [9, 2],
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  datalabels: {
                    align: "start",
                    anchor: "start",
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Min Inventario" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones" &&
                    chartSettings.id !== "inventario_con_medidas_observatorio"
                )
                .map((cat) => ({
                  label: categoryLabel(cat),
                  borderColor: "rgba(255, 0, 0, 0.6)",
                  backgroundColor: "transparent",
                  order: 2,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  datalabels: {
                    display: false,
                  },
                })),

              ...categories
                .filter(
                  (cat) =>
                    cat === "Max Inventario" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones" &&
                    chartSettings.id !== "inventario_con_medidas_observatorio"
                )
                .map((cat) => ({
                  label: categoryLabel(cat),
                  borderColor: "rgba(255, 0, 0, 0,6)",
                  backgroundColor: "rgba(255, 0, 0, 0.3)",
                  order: 2,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  fill: +1,
                  datalabels: {
                    display: false,
                  },
                })),
              ...categories
                .filter(
                  (cat) => cat === "Emisiones Netas" || cat === "Inventario"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "black",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  datalabels: {
                    align: "end",
                    anchor: "end",
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Carbon Budget" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones" &&
                    chartSettings.id !== "inventario_con_medidas_observatorio"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "transparent",
                  backgroundColor: "rgba(255, 18, 18, 0.4)",
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 0.01,
                  pointRadius: 0.01,
                  pointHitRadius: 10,
                  fill: "origin",
                  stackFill: false,
                  datalabels: {
                    display: function (context) {
                      if (
                        chartSettings.id === "inventario_con_medidas" ||
                        chartSettings.id ===
                          "inventario_con_medidas_solo_oficial" ||
                        chartSettings.id ===
                          "inventario_con_medidas_sin_proyecciones" ||
                        chartSettings.id ===
                          "inventario_con_medidas_observatorio"
                      ) {
                        return context.dataIndex === 20;
                      } else {
                        return context.dataIndex === 35;
                      }
                    },
                    align: "start",
                    anchor: "start",
                    color: "white",
                    fontSize: window.innerWidth > 1200 ? 11 : 6,
                    offset: 110,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    formatter: function (value, context) {
                      return "Carbon Budget";
                    },
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Proyeccion Observatorio" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "rgb(0, 77, 6)",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1.6,
                  pointRadius: 2,
                  datalabels: {
                    display: false,
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Min Observatorio" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "rgba(0, 115, 13, 0.6)",
                  backgroundColor: "transparent",
                  order: 3,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  datalabels: {
                    display: false,
                  },
                })),

              ...categories
                .filter(
                  (cat) =>
                    cat === "Max Observatorio" &&
                    chartSettings.id !==
                      "inventario_con_medidas_solo_oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "rgba(0, 115, 13, 0,6)",
                  backgroundColor: "rgba(0, 115, 13, 0.6)",
                  order: 3,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  fill: +1,
                  datalabels: {
                    display: false,
                  },
                })),

              ...categories
                .filter(
                  (cat) =>
                    cat === "Proyeccion NDC oficial" &&
                    chartSettings.id !==
                      "inventario_con_medidas_sin_proyecciones" &&
                    chartSettings.id !== "inventario_con_medidas_observatorio"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "rgb(107, 59, 0)",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1.6,
                  pointRadius: 2,
                  datalabels: {
                    display: function (context) {
                      if (
                        chartSettings.id ===
                        "inventario_con_medidas_solo_oficial"
                      ) {
                        return context.dataIndex === 35;
                      } else {
                        return false;
                      }
                    },
                    align: "start",
                    anchor: "start",
                    color: "white",
                    font: {
                      size: window.innerWidth > 1200 ? 11 : 8,
                    },
                    offset: 30,
                    backgroundColor: "rgba(107, 59, 0,0.6)",
                    formatter: function (value, context) {
                      return "Proyección Oficial";
                    },
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    isBase(cat) &&
                    (chartSettings.id !== "emisiones_netas" || isMultiscenario)
                )
                .map((cat) => ({
                  label: categoryLabel(cat),
                  borderColor: "black",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1,
                  datalabels: {
                    display: false,
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Emisiones con Medidas" &&
                    chartSettings.id !== "inventario_con_medidas_solo_oficial"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "black",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1.6,
                  pointRadius: 2,
                  datalabels: {
                    display: false,
                  },
                })),
              ...categories
                .filter(
                  (cat) =>
                    cat === "Escenario Referencia" &&
                    chartSettings.id !== "inventario_con_medidas_solo_oficial"
                )
                .map((cat) => ({
                  //cambiar por emisiones netas
                  label: categoryLabel(cat),
                  borderColor: "rgb(187, 0, 255)",
                  backgroundColor: "transparent",
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1.6,
                  pointRadius: 2,
                  datalabels: {
                    display: function (context) {
                      return context.dataIndex === 30;
                    },
                    align: "start",
                    anchor: "start",
                    color: "white",
                    font: {
                      size: window.innerWidth > 1200 ? 11 : 8,
                    },
                    offset:
                      chartSettings.id ===
                      "inventario_con_medidas_sin_proyecciones"
                        ? -32
                        : -28,
                    backgroundColor: "rgba(187, 0, 255, 0.6)",
                    formatter: function (value, context) {
                      return "Escenario Referencia";
                    },
                  },
                })),
              ...categories
                .filter((cat) => {
                  return (
                    check(cat, a) &&
                    chartSettings.id !== "inventario_con_medidas"
                  );
                })
                .map((cat, i) => ({
                  label: categoryLabel(cat),
                  borderColor: colors[MEDIDAS.indexOf(cat) % colors.length],
                  backgroundColor: getBg(
                    colors[MEDIDAS.indexOf(cat) % colors.length]
                  ),
                  order: 0,
                  data: filteredDataset.map((data) => parseValue(data[cat])),
                  yAxisID: "non-stacked",
                  ...defaultSettings,
                  borderWidth: 1.6,
                  pointRadius: 2,
                  fill: { target: "-1", propagate: false },
                })),
            ],
            labels: years.map((y) => String(y)),
          },
          options: {
            animation: {
              duration: pdf ? 0 : 400,
              easing: "linear",
              onComplete: function () {
                if (pdf) {
                  setChart(this.chart);
                } else {
                  setChart(null);
                  setChart(this.chart);
                  setLoading(false);
                }
              },
            },
            plugins: {
              filler: {
                propagate: true,
              },
              autocolors: false,
              datalabels: {
                borderRadius: 4,
                color: "black",
                font: {
                  weight: "bold",
                  size: window.innerWidth > 1600 ? 11 : 8,
                },
                display: function (context) {
                  return (
                    context.dataset.data[context.dataIndex] -
                      context.dataset.data[context.dataIndex - 1] >
                    5
                  );
                },
                formatter: Math.round,
                padding: 6,
                title: {
                  color: "blue",
                },
              },
              zoom: {
                zoom: {
                  enabled: true,
                  drag: {
                    enabled: true,
                  },
                  mode: "y",
                },
              },
            },
            responsive: pdf ? false : true,
            legend: {
              display: true,
              position:
                selected === "home"
                  ? window.innerWidth > 1600
                    ? "right"
                    : "bottom"
                  : "bottom",
              labels: {
                boxWidth: 10,
                padding: 10,
                fontSize: window.innerWidth > 1200 ? 11 : 8,
                filter: function (legendItem, data) {
                  return (
                    legendItem.text !== "Min Observatorio" &&
                    legendItem.text !== "Max Observatorio" &&
                    legendItem.text !== "Min Inventario" &&
                    legendItem.text !== "Max Inventario" &&
                    checkFalse(legendItem.text, MEDIDAS)
                  );
                },
              },
              onClick: pdf
                ? () => {}
                : function (e, legendItem) {
                    var index = legendItem.datasetIndex;

                    var ci = this.chart;
                    var alreadyHidden =
                      ci.getDatasetMeta(index).hidden === null
                        ? false
                        : ci.getDatasetMeta(index).hidden;
                    if (alreadyHidden) {
                      ci.getDatasetMeta(index).hidden = false;
                    } else {
                      ci.getDatasetMeta(index).hidden = true;
                    }

                    var notHiddenData = ci.data.datasets
                      .filter((item, i) => {
                        var meta = ci.getDatasetMeta(i);
                        return (
                          !meta.hidden &&
                          !isBase(item["label"]) &&
                          item["label"] !== "Emisiones Netas" &&
                          item["label"] !== "Proyeccion Observatorio" &&
                          item["label"] !== "Min Observatorio" &&
                          item["label"] !== "Max Observatorio" &&
                          item["label"] !== "Min Inventario" &&
                          item["label"] !== "Max Inventario" &&
                          item["label"] !== "Inventario" &&
                          item["label"] !== "Proyeccion NDC oficial" &&
                          item["label"] !== "Estimación Inventario" &&
                          item["label"] !== "Carbon Budget" &&
                          item["label"] !== "Escenario Referencia" &&
                          item["label"] !== "Emisiones con Medidas" &&
                          !check(item["label"], a)
                        );
                      })
                      .map((item) => item["data"])
                      .map((values) =>
                        values.map((value) => truncOneDecimal(value))
                      );
                    let min_value = 0;
                    let max_value = 0;

                    if (notHiddenData.length > 0) {
                      let aux_inventario = new Array(
                        notHiddenData[0].length
                      ).fill(0);
                      let aux_estimacion = new Array(
                        notHiddenData[0].length
                      ).fill(0);
                      let aux = new Array(notHiddenData[0].length).fill(0);
                      let aux_min = new Array(notHiddenData[0].length).fill(-4);
                      let aux_max = new Array(notHiddenData[0].length).fill(4);
                      if (
                        chartSettings.id !== "inventario_actualizado" &&
                        chartSettings.id !== "inventario_actualizado_neto" &&
                        chartSettings.id !== "inventario_actualizado_barras"
                      ) {
                        notHiddenData.forEach((element) => {
                          for (let index = 0; index < element.length; index++) {
                            min_value = Math.max(
                              Math.min(min_value, element[index]),
                              chartSettings.minValue
                            );
                            max_value = Math.min(
                              Math.max(max_value, element[index], 160),
                              chartSettings.maxValue
                            );
                            aux_min[index] += truncOneDecimal(element[index]);
                            aux_max[index] += truncOneDecimal(element[index]);
                            aux[index] += truncOneDecimal(element[index]);
                          }
                        });
                      } else {
                        notHiddenData.forEach((element) => {
                          for (let index = 0; index < element.length; index++) {
                            min_value = Math.max(
                              Math.min(min_value, element[index]),
                              chartSettings.minValue
                            );
                            max_value = Math.min(
                              Math.max(max_value, element[index], 160),
                              chartSettings.maxValue
                            );
                            if (index + 1990 <= YEAR_INVENTORY) {
                              aux_inventario[index] += truncOneDecimal(
                                element[index]
                              );
                              if (index + 1990 < YEAR_INVENTORY) {
                                aux_estimacion[index] = null;
                              }
                            }
                            if (index + 1990 >= YEAR_INVENTORY) {
                              if (index + 1990 > YEAR_INVENTORY) {
                                aux_inventario[index] = null;
                              }
                              aux_estimacion[index] += truncOneDecimal(
                                element[index]
                              );
                            }
                            if (index + 1990 > YEAR_UPDATE) {
                              aux_inventario[index] = null;
                              aux_estimacion[index] = null;
                            }
                          }
                        });
                      }

                      if (
                        ci.data.datasets.filter(
                          (item) => item.label === "Emisiones Netas"
                        ).length > 0 &&
                        chartSettings.id !== "inventario_proyectado" &&
                        chartSettings.id !== "inventario_proyectado_neto" &&
                        chartSettings.id !==
                          "inventario_proyectado_neto_barras" &&
                        chartSettings.id !== "inventario_proyectado_barras"
                      ) {
                        const ticks = {
                          suggestedMin: min_value || 0,
                          suggestedMax: chartSettings.maxValue,
                          stepSize: chartSettings.stepSize,
                        };
                        ci.options.scales.yAxes[0].ticks = ticks;
                        ci.options.scales.yAxes[1].ticks = ticks;
                        let index = ci.data.datasets.findIndex(
                          (item) => item.label === "Emisiones Netas"
                        );
                        ci.data.datasets[index]["data"] = aux.map((value) => {
                          return truncOneDecimal(value).toFixed(1);
                        });
                      }
                      if (
                        ci.data.datasets.filter(
                          (item) => item.label === "Inventario"
                        ).length > 0 &&
                        (chartSettings.id === "inventario" ||
                          chartSettings.id === "inventario_proyectado" ||
                          chartSettings.id === "inventario_neto" ||
                          chartSettings.id === "inventario_con_medidas" ||
                          chartSettings.id ===
                            "inventario_con_medidas_solo_oficial" ||
                          chartSettings.id ===
                            "inventario_con_medidas_sin_proyecciones" ||
                          chartSettings.id ===
                            "inventario_con_medidas_observatorio")
                      ) {
                        const ticks = {
                          suggestedMin: min_value || 0,
                          suggestedMax: chartSettings.maxValue,
                          stepSize: chartSettings.stepSize,
                        };
                        ci.options.scales.yAxes[0].ticks = ticks;
                        ci.options.scales.yAxes[1].ticks = ticks;
                        let index = ci.data.datasets.findIndex(
                          (item) => item.label === "Inventario"
                        );
                        ci.data.datasets[index]["data"] = aux.map((value) => {
                          return truncOneDecimal(value).toFixed(1);
                        });
                      }
                      if (
                        chartSettings.id === "inventario_actualizado_neto" ||
                        chartSettings.id === "inventario_actualizado_barras"
                      ) {
                        const ticks = {
                          suggestedMin: min_value || 0,
                          suggestedMax: chartSettings.maxValue,
                          stepSize: chartSettings.stepSize,
                        };
                        ci.options.scales.yAxes[0].ticks = ticks;
                        ci.options.scales.yAxes[1].ticks = ticks;
                        let index = ci.data.datasets.findIndex(
                          (item) => item.label === "Inventario"
                        );
                        ci.data.datasets[index]["data"] = aux_inventario;
                        let index2 = ci.data.datasets.findIndex(
                          (item) => item.label === "Estimación Inventario"
                        );
                        ci.data.datasets[index2]["data"] = aux_estimacion;
                      }
                      if (chartSettings.id === "emisiones_gei") {
                        const ticks = {
                          suggestedMin: min_value || 0,
                          max: max_value + 10 || 1200,
                          stepSize: chartSettings.stepSize,
                        };
                        ci.options.scales.yAxes[0].ticks = ticks;
                        ci.options.scales.yAxes[1].ticks = ticks;
                      }
                    }
                    ci.update();
                  },
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    labelString: "Año",
                  },
                  gridLines: {
                    color: "#f5f5f5",
                  },
                  stacked: true,
                  ticks: {
                    callback: (value) => {
                      if (value === año_minimo || value % 5 === 0) {
                        return value;
                      }
                      return "";
                    },
                    minRotation: 0,
                    maxRotation: 0,
                  },
                },
              ],
              yAxes,
            },
          },
        });
      }
    })
    .catch((error) => {
      console.error(error);
      if (!pdf) setError("Error cargando set de datos");
    });
};

const Wrapper = styled.div`
  background-color: white;
  border-radius: 3px;
  display: block;
  padding: 15px;
  margin-bottom: 5px;
  min-width: auto;
  @media (max-width: 720px) {
    padding: 0;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  font-size: 11px;
  margin-bottom: 20px;
  padding-top: 30px;
  display: block;
  @media (min-width: 720px) {
    padding-top: 0;
    margin-bottom: 15px;
    font-size: 9px;
    h4 {
      font-size: 10px;
    }
  }
  canvas {
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  h4 {
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #999;
    font-weight: normal;
  }
`;

const ChartOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
  min-height: auto;
  a,
  span {
    color: #666;
    font-size: 10px;
    display: inline-block;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 720px) {
    a,
    span {
      font-size: 8px;
    }
  }
`;

const SliderWrap = styled.div`
  margin-top: 10px;
  padding: 0 10px 10px 20px;
`;

const SelectContainer = styled.div`
  padding: 0 40px 20px 20px;
  display: flex;
`;

const downloadAsImage = (name, id) => {
  const chartNode = document.getElementById(id);
  if (chartNode) {
    domToImage.toBlob(chartNode).then(function (blob) {
      saveAs(blob, name + ".png");
    });
  }
};

const descargarXLSX = (path) => {
  let labels = chart.data.labels;
  let chart_data = chart.data.datasets.filter(
    (_, i) => chart.getDatasetMeta(i).hidden !== true
  );
  let titles = chart_data.map((item) => item.label);
  titles.unshift("año");
  let xlsx_data = [];
  xlsx_data.push(titles);
  labels.forEach((anno, index) => {
    let data_anno = chart_data.map((item) => item.data[index]);
    data_anno.unshift(anno);
    xlsx_data.push(data_anno);
  });

  let name = path.split("/");

  var wb = XLSX.utils.book_new();
  wb.SheetNames.push("Test Sheet");
  var ws = XLSX.utils.aoa_to_sheet(xlsx_data);
  wb.Sheets["Test Sheet"] = ws;

  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  let s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };
  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    name[name.length - 1].split(".")[0] + ".xlsx"
  );
};

const loadKpi = (
  year,
  datasetPath,
  chartSettings,
  setKpiAmounts,
  setKpiDict,
  kpiDict
) => {
  return 1;
};

/**
 * Gráfico principal.
 * Renderiza el gráfico correspondiente al indicador y a el escenario seleccionado
 * @param {string} id - Id for the main dom element
 * @param {string} mode - Indicador seleccionado
 * @param {string} currentScenario - Escenario seleccionado
 * @param {string} datasetPath - Ruta del set de datos correspondiente
 * @param {object} chartSettings - Opciones de configuración para este gráfico
 * @param {bool} kpiMode  - KPI para regiones, nacional o sin KPI
 * @param {bool} allowDownloads
 */
const Grafico = ({
  id,
  mode,
  currentScenario,
  datasetPath,
  chartSettings,
  kpiMode,
  allowDownloads,
  isMultiscenario,
  settings,
  min_year,
  max_year,
  years,
  setYears,
  medidasActivas,
  selected,
}) => {
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(false);
  const [kpiAmounts, setKpiAmounts] = useState([0, 0]);
  const [kpiDict, setKpiDict] = useState({
    tco2_2030: 0,
    tco2_entre_2020_2030: 0,
    potencia_instalada_solar_fv: 0,
    potencia_instalada_solar_csp: 0,
    potencia_instalada_eolica: 0,
    potencia_instalada_almacenamiento: 0,
  });
  const [kpiYear, setKpiYear] = useState(2030);
  const [chart, setChart] = useState(null);
  const emisiones = [0, 0];
  // initial final years control
  let año_minimo = min_year;
  let año_maximo = max_year;

  const [loadingPdf, setLoadingPdf] = useState(false);
  let l = [];
  if (
    chartSettings.id === "inventario_con_medidas" ||
    chartSettings.id === "inventario_con_medidas_solo_oficial" ||
    chartSettings.id === "inventario_con_medidas_sin_proyecciones"
  ) {
    for (var i = YEAR_UPDATE; i <= año_maximo; i++) {
      l.push({ value: i, label: i });
    }
  } else {
    for (var ij = año_minimo; ij <= año_maximo; ij++) {
      l.push({ value: ij, label: ij });
    }
  }
  const arrayYears = l;
  const sliderChange = (_, newValue) => {
    setYears(newValue);
  };

  // En este punto settings trae los insttrumentos, las politicas y el pib
  const generatePDFDocument = async (name) => {
    setLoadingPdf(true);
    let charts2 = charts;
    let chartsData = new Array(charts2.length).fill(0);
    let kpis = new Array(charts2.length).fill(0);
    charts.forEach((item, index) => {
      let idc = Math.random();
      ReactDOM.render(
        React.createElement(ChartContainer, { id: idc }, null),
        document.getElementById("pdf-charts" + index)
      );
      const chartCallback = (value) => {
        chartsData[index] = truncOneDecimal(value.canvas);
      };
      const kpiCallback = (value) => {
        kpis[index] = value;
      };
      initChart(
        selected,
        setKpiDict,
        setKpiAmounts,
        kpiDict,
        kpiYear,
        medidasActivas,
        years[0],
        years[1],
        currentScenario.datasets[item.id],
        item,
        null,
        null,
        chartCallback,
        currentScenario.name,
        idc,
        false,
        true
      );

      loadKpi(
        kpiYear,
        currentScenario.datasets[item.id],
        item,
        kpiCallback,
        setKpiDict,
        kpiDict
      );
    });
    const descargar = () => {
      setTimeout(async function () {
        if (chartsData.includes(0) || kpis.includes(0)) {
          descargar();
        } else {
          setTimeout(async function () {
            const blob = await pdf(
              <PdfGenerator
                key="pdf"
                graphImgs={chartsData}
                settings={charts}
                datasetPath={datasetPath}
                kpis={kpis}
                year={kpiYear}
                escenario={currentScenario}
              />
            ).toBlob();

            saveAs(blob, name);
            setLoadingPdf(false);
            // for(i = 0; i <= 10; i++){
            //   document.getElementById("pdf-charts" + i).hidden = true;
            // }
            // document.getElementById("emisiones").hidden = true;
          }, 20);
        }
      }, 1000);
    };
    descargar();
  };
  useEffect(() => {
    if (datasetPath) {
      initChart(
        selected,
        setKpiDict,
        setKpiAmounts,
        kpiDict,
        kpiYear,
        medidasActivas,
        years[0],
        years[1],
        datasetPath,
        chartSettings,
        setError,
        setLoading,
        setChart,
        currentScenario.name,
        id,
        isMultiscenario
      );
    }
  }, [
    selected,
    medidasActivas,
    years,
    kpiYear,
    datasetPath,
    chartSettings,
    currentScenario,
    id,
    kpiMode,
    isMultiscenario,
  ]);
  useEffect(() => {}, [loadingPdf]);
  useEffect(() => {
    if (mode === "emisiones_netas") {
      setKpiYear(2050); //caso de borde pedido para emisiones netas de gei -> 2050 por defecto
    } else {
      setKpiYear(2030);
    }
  }, [mode]);

  return (
    <Wrapper>
      {error ? (
        <Message error>{error}</Message>
      ) : datasetPath ? (
        <React.Fragment>
          <ChartOptions>
            <span
              style={{
                color: "#2d4ffa",
                textDecoration: "none",
                cursor: "none",
                marginTop: "0px",
                marginBottom: "10px",
              }}
            >
              {" "}
              Mover punto azul para variar rango de años
            </span>
          </ChartOptions>
          <br />
          <SliderWrap>
            <Slider
              value={years}
              onChange={sliderChange}
              min={año_minimo}
              max={año_maximo}
              valueLabelDisplay="on"
            />
          </SliderWrap>

          <ChartContainer id={id}>
            <Loader />
          </ChartContainer>
          {allowDownloads && (
            <ChartOptions>
              <span onClick={() => downloadAsImage(mode, id)}>
                Descargar gráfico
              </span>

              <span onClick={() => descargarXLSX(datasetPath)}>
                Descargar datos
              </span>
            </ChartOptions>
          )}
          {loading ? (
            <Loader />
          ) : kpiMode ? (
            [
              <KPI
                key="kpi-year"
                mode={mode}
                currentScenario={currentScenario}
                base={kpiAmounts[0]}
                newValue={kpiAmounts[1]}
                kpiDict={kpiDict}
                año={kpiYear}
                selector={"no año"}
                selected={selected}
                type={kpiMode}
              />,
              chartSettings.id === "inventario" ||
              chartSettings.id === "inventario_proyectado_neto_barras" ||
              chartSettings.id === "inventario_proyectado_barras" ||
              chartSettings.id === "inventario_neto" ||
              chartSettings.id === "inventario_actualizado" ||
              chartSettings.id === "inventario_actualizado_barras" ||
              chartSettings.id === "inventario_actualizado_neto" ||
              chartSettings.id === "inventario_proyectado_neto" ||
              chartSettings.id === "inventario_proyectado" ||
              selected === "Seleccionar Autoridad Climática" ? null : (
                <SelectContainer key="select-container">
                  <Select
                    key="select-year"
                    value={kpiYear}
                    options={arrayYears}
                    onChange={(value) => {
                      // loadKpi(value);
                      setKpiYear(value);
                    }}
                  />{" "}
                </SelectContainer>
              ),
              <KPI
                key="kpi-year-1"
                mode={mode}
                currentScenario={currentScenario}
                base={kpiAmounts[0]}
                newValue={kpiAmounts[1]}
                kpiDict={kpiDict}
                año={kpiYear}
                selector={"año"}
                selected={selected}
                type={"nacional"}
              />,
            ]
          ) : null}
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts0"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts1"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts2"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts3"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts4"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts5"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts6"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts7"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts8"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts9"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts10"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts11"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts12"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts13"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts14"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts15"
          ></div>
          <div
            style={{ position: "absolute", left: -10000 }}
            id="pdf-charts16"
          ></div>

          <div style={{ position: "absolute", left: -10000 }} id="emisiones">
            {" "}
            <EmisionesChart
              chile={Number((emisiones[1] / 18).toFixed(1))}
            />{" "}
          </div>
        </React.Fragment>
      ) : (
        <Message info>
          No hay set de datos disponibles para la selección actual.
        </Message>
      )}
    </Wrapper>
  );
};

Grafico.propTypes = {
  datasetPath: string,
  stacked: bool,
};

export default Grafico;

import styled from 'styled-components';

const BigNumber = styled.div`
  color: var(--primary-color);

  h1 {
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 36px;
    margin: 0;
    padding: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
  
  }

  h2 {
    width: 100%;
    text-align: center;
    font-weight: normal;
    margin: 0;
    padding: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 20px;
  
  }

  label {
    color: var(--dark-blue-color);;
    display: block;
    width: 200px;
    margin: 0 auto;
    text-align: center;
  }

  @media(max-width:720px) {

    h1{
      font-size: 20px;
    }
    h2{
      font-size: 12px;
    }
    label{
      width: 100%;
    }


  }
`;

export default BigNumber;
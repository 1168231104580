import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";
import GeneracionBiogasHelp from "components/help/helpMedidas/GeneracionBiogasHelp";
import EnergiasRenovablesHelp from "components/help/helpMedidas/EnergiasRenovablesHelp";
// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (medida === "Generación biogás") {
    return <GeneracionBiogasHelp region={region} />;
  } else if (
    medida === "Energías renovables en reemplazo de centrales Térmicas"
  ) {
    return <EnergiasRenovablesHelp region={region} />;
  }
  return null;
};
// Crea un container con la lista de medidas que se puede seleccionar para el sector 'Generacion de electricidad'
const EnergiaPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="Energía"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

EnergiaPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default EnergiaPolicies;

import React, { useState } from "react";
import styled from "styled-components";
import ModeSelection from "./ModeSelection";
import Grafico from "./Chart";
import {
  chartSettings,
  inventoryChartSettings,
  inventoryChartSettings2,
  chartSettingsRegiones,
  chartSettingsRegiones2,
  chartSettingsRegiones3,
} from "config";
import {
  MEDIDAS,
  MIN_YEAR,
  YEAR_INVENTORY,
  YEAR_UPDATE,
} from "utils/constants";
import { useEffect } from "react";

const Wrapper = styled.div`
  position: relative;
  ${(props) => props.index > 0 && "margin-top: 10px;"}
`;

const Remove = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: #333;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 10px;
`;

const CheckboxWrap = styled.div`
  font-size: 12px;
  z-index: 99;
  padding: 0 0 10px 5px;
  color: var(--dark-grey-color);

  label {
    cursor: pointer;
  }
`;

const ChartMode = ({
  index,
  onHide,
  currentScenario,
  settings,
  medidasActivas,
  type,
  selected,
  onInventorySelect,
}) => {
  // que reciba tambien si es Sector o autoridad, para hacer la bajada a los kpi y determinar contribucion
  // Modo del gráfico: Por ejemplo: Emisiones, generacion etc,.
  const [mode, setMode] = useState(
    type === "inventario"
      ? inventoryChartSettings[0].id
      : type === "inventario2"
      ? inventoryChartSettings2[0].id
      : type === "inventario3"
      ? inventoryChartSettings2[1].id
      : type === "regiones"
      ? chartSettingsRegiones[0].id
      : type === "regiones2"
      ? chartSettingsRegiones2[0].id
      : type === "regiones3"
      ? chartSettingsRegiones3[0].id
      : chartSettings[0].id
  );
  const [min_max, setMinMax] = useState([
    mode === "inventario_con_medidas_sin_proyecciones" ? 2005 : MIN_YEAR,
    type === "inventario" ||
    type === "regiones" ||
    type === "regiones2" ||
    type === "regiones3"
      ? YEAR_INVENTORY
      : type === "inventario2"
      ? YEAR_UPDATE
      : 2050,
  ]);
  const [years, setYears] = useState([
    mode === "inventario_con_medidas_sin_proyecciones" ? 2005 : MIN_YEAR,
    type === "inventario" ||
    type === "regiones" ||
    type === "regiones2" ||
    type === "regiones3"
      ? YEAR_INVENTORY
      : type === "inventario2"
      ? YEAR_UPDATE
      : 2050,
  ]);
  // const [region, setRegion] = useState('nacional')
  // Multiple or single scenario
  const [multipleScenarios, setMultipleScenarios] = useState(false);

  // Obtener opciones de configuración del gráfico actual
  const currentChartSettings =
    type === "inventario"
      ? inventoryChartSettings.find((item) => item.id === mode)
      : type === "regiones"
      ? chartSettingsRegiones.find((item) => item.id === mode)
      : type === "regiones2"
      ? chartSettingsRegiones2.find((item) => item.id === mode)
      : type === "regiones3"
      ? chartSettingsRegiones3.find((item) => item.id === mode)
      : chartSettings.find((item) => item.id === mode);

  // get dataset path based on current scenario
  const datasetPath = currentScenario ? currentScenario.datasets[mode] : null;
  const noUsadas = (array, cate) => {
    for (let i in array) {
      if (array[i] === cate) {
        return false;
      }
    }
    return true;
  };
  let medidasNoUsadas = MEDIDAS.filter((item) =>
    noUsadas(medidasActivas, item)
  );

  const handleCheckbox = () => {
    setMultipleScenarios(!multipleScenarios);
  };
  const chooseMinMaxYear = (value) => {
    let minMax = [];
    if (
      value === "inventario_con_medidas" ||
      value === "inventario_con_medidas_solo_oficial" ||
      value === "inventario_con_medidas_sin_proyecciones" ||
      value === "inventario_con_medidas_observatorio"
    ) {
      minMax = [2005, 2050];
    } else if (
      value === "inventario_proyectado_neto_barras" ||
      value === "inventario_proyectado_barras" ||
      value === "inventario_proyectado_neto" ||
      value === "inventario_proyectado"
    ) {
      minMax = [1990, 2050];
    } else if (value === "inventario" || value === "inventario_neto") {
      minMax = [1990, YEAR_INVENTORY];
    } else if (
      value === "inventario_actualizado" ||
      value === "inventario_actualizado_neto" ||
      value === "inventario_actualizado_barras"
    ) {
      minMax = [1990, YEAR_UPDATE];
    } else {
      minMax = [2017, 2050];
    }
    return minMax;
  };

  useEffect(
    () =>
      onInventorySelect
        ? mode === "inventario" ||
          mode === "inventario_neto" ||
          mode === "inventario_actualizado_barras" ||
          mode === "inventario_actualizado" ||
          mode === "inventario_actualizado_neto" ||
          mode === "inventario_con_medidas_solo_oficial"
          ? onInventorySelect(true)
          : onInventorySelect(false)
        : () => <></>,
    [mode]
  );
  return (
    <Wrapper index={index}>
      <ModeSelection
        mode={mode}
        onChange={(value) => {
          setMode(value);
          setMultipleScenarios(false);
          setYears(chooseMinMaxYear(value));
          setMinMax(chooseMinMaxYear(value));
        }}
        prepend={onHide && <Remove onClick={onHide}>Ocultar</Remove>}
        type={type}
      />
      {currentChartSettings.multipleEnabled && (
        <CheckboxWrap>
          <label htmlFor={`check` + index}>Ver múltiples escenarios</label>{" "}
          <input
            id={`check` + index}
            type="checkbox"
            checked={multipleScenarios}
            onChange={handleCheckbox}
          />
        </CheckboxWrap>
      )}
      {multipleScenarios ? (
        <Grafico
          id={`multiple_scenario_container` + index}
          mode={mode}
          currentScenario={currentScenario}
          datasetPath={`/datasets/${mode}/Escenarios.csv`}
          chartSettings={currentChartSettings}
          isMultiscenario
          settings={settings}
          years={years}
          min_year={min_max[0]}
          max_year={min_max[1]}
          setYears={setYears}
          medidasActivas={medidasNoUsadas}
          selected={selected}
          type={type}
        />
      ) : (
        <Grafico
          id={`chart_container` + index}
          mode={mode}
          currentScenario={currentScenario}
          datasetPath={datasetPath}
          chartSettings={currentChartSettings}
          kpiMode={
            currentChartSettings &&
            (type === "regiones" ||
              type === "regiones2" ||
              type === "regiones3")
              ? "regional"
              : currentChartSettings
              ? "nacional"
              : null
          }
          allowDownloads
          settings={settings}
          years={years}
          min_year={min_max[0]}
          max_year={min_max[1]}
          setYears={setYears}
          medidasActivas={medidasNoUsadas}
          selected={selected}
          type={type}
        />
      )}
    </Wrapper>
  );
};

export default ChartMode;

import React from "react";
import { func, object } from "prop-types";
import CambioModalHelp from "components/help/helpMedidas/CambioModalHelp";
import TaxisElectricosHelp from "components/help/helpMedidas/TaxisElectricosHelp";
import VehiculosComercialesElectricosHelp from "components/help/helpMedidas/VehiculosComercialesElectricosHelp";
import TransporteCargaHidroHelp from "components/help/helpMedidas/TransporteCargaHidroHelp";
import TransporteRMHelp from "components/help/helpMedidas/TransporteRMHelp";
import TransporteRegionesHelp from "components/help/helpMedidas/TransporteRegionesHelp";
import VehiculosParticularesHelp from "components/help/helpMedidas/VehiculosParticularesHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";
const chooseHelp = (medida) => {
  if (medida === "Cambio modal transporte") {
    return <CambioModalHelp />;
  } else if (medida === "Taxis eléctricos") {
    return <TaxisElectricosHelp />;
  } else if (medida === "Vehículos comerciales eléctricos") {
    return <VehiculosComercialesElectricosHelp />;
  } else if (medida === "Transporte de carga - Hidrógeno") {
    return <TransporteCargaHidroHelp />;
  } else if (medida === "Buses Eléctricos en Transporte público RM") {
    return <TransporteRMHelp />;
  } else if (medida === "Buses Eléctricos en Transporte público regiones") {
    return <TransporteRegionesHelp />;
  } else if (medida === "Vehículos particulares eléctricos") {
    return <VehiculosParticularesHelp />;
  }
  return null;
};

const TransportePolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="Transporte"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

TransportePolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default TransportePolicies;

import styled from 'styled-components';

export default  styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1450px;
  padding: 0 20px;
  @media(max-width: 720px) {
    display: block;
    max-width: 600px;
  padding: 0 10px;
  }
`;

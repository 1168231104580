import React from 'react';
import styled from 'styled-components';
import Container from 'components/layout/Container';
import logoCr2 from 'img/cr2_OCN.png';
import title from 'img/logoOCN_450.png';
import logoCentroFCFM from 'img/CE_UCH.png';

const HeaderMain = styled.div`
  background-color: white;
  align-content: center;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  margin: 0px auto;
`

const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 8px;
  margin-bottom: 1px;
  @media (max-width: 720px) {
    display: block;
  }
`;

// const Title = styled.div`
//   font-weight: bold;
//   font-size: 22px;
//   padding-top: 5px;
//   padding-left: 10px;

//   & > img {
//     display: block;
//     width: 110px;
//     margin-bottom: 15px;
//   }

//   @media (max-width: 720px) {
//     font-size: 14px;
//   }
// `;

const Logos = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 5px;

  img {
    align-self: flex-end;
  }
  @media (max-width: 720px) {
   display:block;
  }
`;


const CentroLogo = styled.img`
  width: 120px;
  margin-right: 20px;
  @media (max-width: 720px) {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-top:10px;
  }
`;

const Cr2Logo = styled.img`
  margin-right: 20px;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 720px) {
    width: 170px;
    height: 60px;
    margin-right: 10px;
    margin-top: 10px;
  }
`;

const TitleImg = styled.img`
  width: 450px;
  height: 36px;
  margin-top: 40px;

  @media (max-width: 720px) {
    width: 280px;
    height: 25px
  }

`;


const Header = () => (
  <HeaderMain>
    <Wrapper>
      <TitleImg src={title} alt="titleOCN"/>
      <Logos>
        <Cr2Logo src={logoCr2} alt="CenterForClimateAndResilienceResearch"/>
        <CentroLogo src={logoCentroFCFM} alt="centroenergia" />
      </Logos>
    </Wrapper>
  </HeaderMain>
);

export default Header;

const shadows = [];
for (let i = 0; i < 5; i += 1) {
  shadows.push("0 1px 1px 1px rgba(0, 0, 0, 0.1)");
}
for (let i = 5; i < 10; i += 1) {
  shadows.push("0 1px 1px 2px rgba(0, 0, 0, 0.18)");
}
for (let i = 10; i < 25; i += 1) {
  shadows.push("0 1px 1px 3px rgba(0, 0, 0, 0.25)");
}

export default {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: "#16528a",
      contrastText: "white",
    },
    secondary: {
      main: "#666",
      contrastText: "white",
    },
    default: {
      main: "white",
      contrastText: "black",
    },
  },
  slider: {
    trackSize: 50,
    handleSize: 100,
  },
  typography: {
    allVariants: {
      fontFamily: "Arial",
      textTransform: "none",
    },
    fontFamily: "Open Sans",
    useNextVariants: true,
  },
  shadows,
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
};

import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  return null;
};
// Crea un container con la lista de medidas que se puede seleccionar para el sector 'Forestal'
const ForestalPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="Forestal"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

ForestalPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default ForestalPolicies;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/Electrificacion motriz mineria cobre.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const ElectrificacionMotrizCobreHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        PorcentajeTajoAbierto: 57,
        PorcentajeSubterranea: 74,
        GWhTajoAbierto: 4300,
        GWhSubterranea: 400,
      },
      estado_de_implementacion: {
        TajoAbierto: 0,
        Subterranea: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Electrificación_Motriz_Minería_Cobre")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Electrificación motriz - minería cobre"]);
      set2050(dataset[60]["Electrificación motriz - minería cobre"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Electrificación Motriz - Minería Cobre</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera la electrificación de usos motrices en el segmento
        de minería del cobre, reemplazando del uso de combustibles fósiles por
        el uso de electricidad. Por usos motrices se consideran todos los
        procesos que utilizan tecnologías que involucren fuerzas mecánicas,
        fijas o móviles; concepto que considera actividades de molienda,
        transporte de carga al interior de las faenas y maquinarias específicas
        asociadas a operaciones mineras, tales como retroexcavadoras, palas
        mecánicas, rastrillos, etc.
      </p>
      <p>
        Se identifican procesos motrices en la minería del cobre, tanto a rajo
        abierto como subterránea, que corresponde principalmente a la demanda
        energética de motores.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Electrificación del consumo energético de usos motrices del 
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["PorcentajeTajoAbierto"]
                      ).replace(".", ",")}
                    </span>%  en minería rajo abierto al año 2050, y de un 
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["PorcentajeSubterranea"]
                      ).replace(".", ",")}
                    </span>% en minería subterránea, a nivel nacional.
                  </p>
                  
                </WrapperExp>

                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0.7 (2030) y 3.0 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Electrificación en minería a rajo abierto a nivel nacional (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["TajoAbierto"]
                      ).replace(".", ",")}
                    </span>
                    %<br />
                    Electrificación en minería subterránea (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["Subterranea"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
            
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Electrificación en minería a rajo abierto electrificación del{" "}
                <span>{String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["PorcentajeTajoAbierto"]
                  ).replace(".", ",")}
                </span>% del consumo energético de usos motrices al año 2050, y de un {" "}
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["PorcentajeSubterranea"]
                  ).replace(".", ",")}
                </span>% en minería subterránea, respecto a escenario de referencia.
              </p>
              <p>
                Lo anterior equivale a electrificar {" "}
                <span>
                {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhTajoAbierto"]
                  ).replace(".", ",")} {" "}
                </span> GWh en minería rajo abierto y {" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhSubterranea"]
                  ).replace(".", ",")}</span> {" "}
                GWh en minería subterránea al año 2050.
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0.7 (2030) y 3.0 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Electrificación en minería a rajo abierto (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["TajoAbierto"]
                  ).replace(".", ",")}
                </span>
                %<br />
                Electrificación en minería subterránea (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["Subterranea"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.7, 3.0, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default ElectrificacionMotrizCobreHelp;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "components/ui/Loader";
import Nav from "components/layout/Nav";
import Header from "components/layout/Header";
import Footer from "components/layout/Footer";
import styled from "styled-components";
import InfoBanner from "containers/BannerNoticias";
import moment from "moment";
import "moment/locale/es";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 720px) {
    display: block;
  }
`;

const Noticia = styled.div`
  padding: 8px;
  width: 100%;
  diplay: table;
  font-size: 14px;
  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const Componente = styled.div`
  display: table-Componente;
  text-align: justify;
`;

const Image = styled.img`
  max-height: 300px;
  max-width: 500px;
`;

const Title = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 700;
  }
`;

const Sidebar = styled.div`
  overflow-x: hidden;
  width: 30%;
  max-width:300px
  min-width:200px;
  margin-left: 80px;
  overflow-y: scroll;
  height: 700px;
  @media(max-width:720px) {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-left:0px;
  }
`;

const Main = styled.div`
  width: 50%;
  @media (max-width: 720px) {
    width: 90%;
    margin-left:10px;
    margin-right:10px;
  }
`;

const FechaPublicacion = styled.div`
  color: #808080;
`;

/**
 * InfoPost.js
 * Componente que corresponde a una página de una noticia/post en particular
 */
const PostNoticia = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    // Carga el post con la id del url desde WP rest api
    async function loadPosts() {
      const response = await fetch(
        `https://noticias.observatoriocarbononeutral.cl/index.php/wp-json/wp/v2/posts?_embed&slug=${slug}`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const post = await response.json();
      setPost(post[0]);
    }
    loadPosts();
  }, [slug]);
  return (
    <>
      <Header />
      <Nav />
      <center>
        <Wrapper>
          <Main>
            {post ? (
              <Noticia>
                <Componente>
                  <center>
                    <Image
                      src={`${post._embedded["wp:featuredmedia"]["0"].source_url}`}
                      srcSet={`${post._embedded["wp:featuredmedia"]["0"].source_url}`}
                      width={`100%`}
                      alt={post.title.rendered}
                      loading="lazy"
                    />
                  </center>
                </Componente>
                <Componente>
                  <Title>{post.title.rendered}</Title>
                </Componente>
                <Componente>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.content.rendered,
                    }}
                  />
                </Componente>
                <Componente>
                  <FechaPublicacion>
                    <center>
                      Fecha de publicación: {moment(post.date).format("LL")}
                    </center>
                  </FechaPublicacion>
                </Componente>
              </Noticia>
            ) : (
              <Loader />
            )}
          </Main>
          <Sidebar>
            <InfoBanner />
          </Sidebar>
        </Wrapper>
      </center>
      <center>
        <Footer />
      </center>
    </>
  );
};

export default PostNoticia;

import React from "react";
import { func, object } from "prop-types";
import MedidasRegionesContainer from "./MedidasRegionesContainer";
import SistemasGestionHelp from "components/help/helpMedidas/SistemasGestionHelp";
import ElectrificacionMotrizIndustriaHelp from "components/help/helpMedidas/ElectrificacionMotrizIndustriaHelp";
import MepsMotores100Help from "components/help/helpMedidas/MepsMotores100Help";
import UsoMotricesHelp from "components/help/helpMedidas/UsoMotricesHelp";
import SSTIndustriaMineriaHelp from "components/help/helpMedidas/SSTIndustriaMineriaHelp";
import ElectrificacionTermicaHelp from "components/help/helpMedidas/ElectrificacionTermicaHelp";
import ElectrificacionMotrizCobreHelp from "components/help/helpMedidas/ElectrificacionMotrizCobreHelp";
import ElectrificacionMotrizRestoHelp from "components/help/helpMedidas/ElectrificacionMotrizRestoHelp";

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida, region) => {
  if (medida === "Sistemas de gestión de energía") {
    return <SistemasGestionHelp region={region} />;
  } else if (medida === "Electrificación motriz industria") {
    return <ElectrificacionMotrizIndustriaHelp region={region} />;
  } else if (medida === "MEPS Motores hasta 100HP") {
    return <MepsMotores100Help region={region} />;
  } else if (medida === "Usos motrices en industria y minería - Hidrógeno") {
    return <UsoMotricesHelp region={region} />;
  } else if (medida === "SST Industria y minería") {
    return <SSTIndustriaMineriaHelp region={region} />;
  } else if (medida === "Electrificación térmica") {
    return <ElectrificacionTermicaHelp region={region} />;
  } else if (medida === "Electrificación motriz - minería cobre") {
    return <ElectrificacionMotrizCobreHelp region={region} />;
  } else if (medida === "Electrificación motriz - resto minería") {
    return <ElectrificacionMotrizRestoHelp region={region} />;
  }
  return null;
};
// Crea un container con la lista de medidas que se puede seleccionar para el sector 'Industrias Manufactureras y de la construccion'
const IndustriasPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
  regionInfo,
}) => {
  return (
    <MedidasRegionesContainer
      sector="INDUSTRIAS MANUFACTURERAS Y DE LA CONSTRUCCIÓN"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      regionInfo={regionInfo}
      chooseHelp={chooseHelp}
    />
  );
};

IndustriasPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default IndustriasPolicies;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/generacion de biogas.png";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const GeneracionBiogasHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_arica_y_parinacota: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_tarapaca: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_antofagasta: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_atacama: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_coquimbo: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_valparaiso: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_metropolitana: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_ohiggins: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_del_maule: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_del_nuble: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_del_biobio: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_la_araucania: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_los_rios: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_los_lagos: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_aysen: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
    region_de_magallanes: {
      estado_de_implementacion: {
        CentralesBiogas: 0,
      },
    },
  };

  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Generación_Biogás")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Generación biogás"]);
      set2050(dataset[60]["Generación biogás"]);
    })
    .catch((error) => {
      console.error(error);
    });
  return (
    <HelpContent>
      <div>
        <h1>Generación Biogás</h1>
      </div>
      <h4>Sector Generación de Electricidad</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em", width: "400px"}}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida propone que en rellenos sanitarios nuevos se incremente el
        porcentaje de captura de biogás y se instalen centrales eléctricas.
      </p>
      <p>
        Considera una entrada paulatina de proyectos de generación en nuevos
        rellenos sanitarios que cumplen la condición de captura mínima de biogás
        (metano) necesaria para tener 1 MW de potencia instalada, equivalente
        aproximadamente a 2.283 tCH<sub>4</sub>/año). La generación eléctrica equivalente
        considerada fue de 2,5 kWh por m<sup>3</sup> de biogás combustionado (o 3,8 MWh/t
        metano) considerando una eficiencia de conversión eléctrica del 38%.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Proyectos de generación en nuevos rellenos sanitarios que
                    cumplen la condición de captura mínima de biogás (metano)
                    necesaria para tener 1 MW de potencia instalada, equivalente
                    aproximadamente a 2.283 tCH<sub>4</sub>/año
                  </p>
                </WrapperExp>

                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada : <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,3 (2030) y 0,7 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>

                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Capacidad de nuevas centrales de generación eléctrica con biogás de rellenos
                    sanitarios a nivel regional (marzo 2024):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["CentralesBiogas"]
                      ).replace(".", ",")}
                    </span>{" "}
                    MW{" "}
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Proyectos de generación en nuevos rellenos sanitarios que
                cumplen la condición de captura mínima de biogás (metano)
                necesaria para tener 1 MW de potencia instalada, equivalente
                aproximadamente a 2.283 tCH<sub>4</sub>/año
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada : <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,3 (2030) y 0,7 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
              Capacidad de nuevas centrales de generación eléctrica con biogás de rellenos sanitarios (marzo 2024):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["CentralesBiogas"]
                  ).replace(".", ",")}
                </span>{" "}
                MW{" "}
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.3, 0.7, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default GeneracionBiogasHelp;

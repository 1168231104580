import React from "react";
import styled from "styled-components";
import Select from "components/ui/Select";
import Settings from "components/ui/Settings";
import MoreInfo from "components/ui/MoreInfo";
import { chartSettings, inventoryChartSettings } from "config";
import {
  chartSettingsRegiones,
  chartSettingsRegiones2,
  chartSettingsRegiones3,
} from "config";

const Container = styled.div`
  ${(props) => props.paddingTop && "padding-top: 15px;"}
  background-color: white;
  border-radius: 3px;
`;

const Wrapper = styled.div`
  margin: 10px;
`;

const HowTo = styled.div`
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;
  text-align: justify;
`;

const info = (type) => {
  if (type === "inventario") {
    return;
  } else if (type === "regiones") {
    return (
      <HowTo>
        En este selector es posible elegir distintas visualizaciones para el
        Inventario Oficial, además de encontrar los indicadores que representan
        los distintos escenarios y proyecciones para el Inventario, en los
        cuales se pueden ver contabilizadas las distintas medidas de mitigación.
      </HowTo>
    );
  } else {
    return (
      <HowTo>
        En este selector, es posible encontrar los indicadores que representan
        los distintos escenarios y proyecciones para el Inventario, en los
        cuales se pueden ver contabilizadas las distintas medidas de mitigación.
      </HowTo>
    );
  }
};

/**
 * Permite seleccionar los diferentes indicadores,
 * por ejemplo: Emisiones GEI, Intensidad Emisiones.
 */
const ModeSelection = ({ mode, onChange, prepend, type }) => {
  const modeSettings =
    type === "inventario"
      ? inventoryChartSettings.find((item) => item.id === mode)
      : type === "regiones"
      ? chartSettingsRegiones.find((item) => item.id === mode)
      : type === "regiones2"
      ? chartSettingsRegiones2.find((item) => item.id === mode)
      : type === "regiones3"
      ? chartSettingsRegiones3.find((item) => item.id === mode)
      : chartSettings.find((item) => item.id === mode);
  const toUse =
    type === "inventario"
      ? inventoryChartSettings
      : type === "regiones"
      ? chartSettingsRegiones
      : type === "regiones2"
      ? chartSettingsRegiones2
      : type === "regiones3"
      ? chartSettingsRegiones3
      : chartSettings;
  return (
    <div>
      {info(type)}
      <Container paddingTop={prepend}>
        {prepend}
        <Settings.Panel>
          {/* <h4>Indicador/Escenario</h4> */}
          <Wrapper>
            <Select
              value={modeSettings.label}
              options={toUse.map((item) => ({
                label: item.label,
                value: item.id,
              }))}
              onChange={onChange}
            />
          </Wrapper>
          {modeSettings && modeSettings.description && (
            <Wrapper>
              <MoreInfo label="Más información" option={true}>
                {modeSettings.description}
              </MoreInfo>
            </Wrapper>
          )}
        </Settings.Panel>
      </Container>
    </div>
  );
};

export default ModeSelection;

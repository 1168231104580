import React from "react";
import Settings from "components/ui/Settings";
import Select from "components/ui/Select";

/**
 * Permite elegir entre los escenarios predeterminados,
 * O "valores personalizados"
 */
const ScenarioSelector = ({ selected, scenarios, onChange, onChange2 }) => {
  const options = scenarios
    .filter((sce) => sce.name !== "custom")
    .map((scenario) => ({
      label: scenario.name,
      value: scenario.name,
    }));

  return (
    <Settings.Panel>
      <h4>Categoría INGEI</h4>
      <div style={{ padding: "0 2px", fontSize: "11px" }}>
        <Select
          value={selected}
          options={options}
          onChange={(value) => {
            onChange(scenarios.find((s) => s.name === value));
            onChange2(scenarios.find((s) => s.name === value).settings);
          }}
        />
      </div>
    </Settings.Panel>
  );
};

export default ScenarioSelector;

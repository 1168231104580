import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/hidrogeno verde usos motrices.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const UsoMotricesHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial:{
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        mina_rajo: 37,
        industrias: 12,
        mina_cobre: 8,
      },
      estado_de_implementacion: {
        mina_rajo: 0,
        industrias: 0,
        mina_cobre: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Usos_Motrices_en_Industria_y_Minería_Hidrógeno"
          )
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Usos motrices en industria y minería - Hidrógeno"]);
      set2050(dataset[60]["Usos motrices en industria y minería - Hidrógeno"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Usos Motrices en Industria y Minería - Hidrógeno</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida considera el reemplazo del uso de diésel por hidrógeno verde
        en usos motrices de la minería e industria nacional. Por usos motrices
        se consideran todos los procesos que utilizan tecnologías que involucren
        fuerzas mecánicas, fijas o móviles.
      </p>
      <p>
        Para esta medida se considera el reemplazo de hasta un 37%, 12% y 8% de
        la energía utilizada para fines motrices en los sectores de cobre de
        mina rajo, industrias varias y cobre de mina subterránea
        respectivamente, al 2050. Se consideró la producción de hidrógeno a
        través del proceso de electrólisis, cuyos insumos son electricidad y
        agua. Se considera que la producción de hidrógeno no genera emisiones de
        gases de efecto invernadero o son despreciables.
      </p>
      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Reemplazo por hidrógeno de hasta un{" "}
                    <span>
                      {cumplimientoRegion["compromiso_referencial"]["mina_rajo"]}
                    </span>
                    %,
                    <span>
                      {cumplimientoRegion["compromiso_referencial"]["industrias"]}
                    </span>
                    % y
                    <span>
                      {cumplimientoRegion["compromiso_referencial"]["mina_cobre"]}
                    </span>
                    % de energía motriz en cobre de mina rajo, industrias varias
                    y cobre de mina subterránea respectivamente, a nivel nacional al año 2050.
                  </p>
                </WrapperExp>
              <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,8 (2030) y 9,1 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Porcentaje de reemplazo en mina rajo a nivel regional (marzo 2024):{" "}
                    <span>
                      {cumplimientoRegion["estado_de_implementacion"]["mina_rajo"]}
                    </span>
                    %
                  </p>
                  <p>
                    Porcentaje de reemplazo en industrias varias a nivel regional (marzo 2024):{" "}
                    <span>
                      {cumplimientoRegion["estado_de_implementacion"]["industrias"]}
                    </span>
                    %
                  </p>
                  <p>
                    Porcentaje de reemplazo en mina de cobre a nivel regional (marzo 2024):{" "}
                    <span>
                      {cumplimientoRegion["estado_de_implementacion"]["mina_cobre"]}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Reemplazo por hidrógeno de hasta un <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["mina_rajo"]}</span>%,{" "}
                <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["industrias"]}</span>
                % y <span>{cumplimientoMedidas["nacional"]["compromiso_referencial"]["mina_cobre"]}</span>% de energía motriz en cobre de
                mina rajo, industrias varias y cobre de mina subterránea
                respectivamente, al 2050.
              </p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,8 (2030) y 9,1 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>
          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Porcentaje de reemplazo en mina rajo (marzo 2024):{" "}
                <span>{String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["mina_rajo"]).replace(".", ",")}</span>%
              </p>
              <p>
                Porcentaje de reemplazo en industrias varias (marzo 2024):{" "}
                <span>{String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["industrias"]).replace(".", ",")}</span>%
              </p>
              <p>
                Porcentaje de reemplazo en mina de cobre (marzo 2024):{" "}
                <span>{String(cumplimientoMedidas["nacional"]["estado_de_implementacion"]["mina_cobre"]).replace(".", ",")}</span>%
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.8, 9.1, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default UsoMotricesHelp;

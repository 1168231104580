import React from "react";
import Medidas from "./kpis/kpiMedidas";
import Inventario from "./kpis/kpiInventario";
import MedidasAño from "./kpis/kpiMedidasAño";
import { Autoridades } from "utils/constants";
import Autoridad from "./kpis/kpiAutoridad";

/**
 * Permite mostrar los KPIs asociados a ciertos indicadores
 */
const KPI = ({
  mode,
  currentScenario,
  base,
  newValue,
  kpiDict,
  año,
  selector,
  selected,
  type,
}) => {
  if (selector === "año" && selected !== "Seleccionar Autoridad Climática") {
    if (
      mode === "inventario_con_medidas" ||
      mode === "inventario_con_medidas_sin_proyecciones" ||
      mode === "inventario_con_medidas_observatorio"
    ) {
      return (
        <MedidasAño
          base={base}
          newValue={newValue}
          año={año}
          kpiDict={kpiDict}
        />
      );
    }
    if (mode === "inventario_con_medidas_solo_oficial") {
      return (
        <Inventario
          base={base}
          newValue={newValue}
          año={año}
          kpiDict={kpiDict}
        />
      );
    }
  } else if (Autoridades.includes(selected)) {
    return (
      <Autoridad
        base={base}
        newValue={newValue}
        año={año}
        kpiDict={kpiDict}
        selected={selected}
      ></Autoridad>
    );
  } else if (
    Autoridades.includes(selected) === false &&
    selector !== "año" &&
    selected !== "Seleccionar Autoridad Climática"
  ) {
    if (
      mode === "inventario_con_medidas" ||
      mode === "inventario_con_medidas_observatorio" ||
      mode === "inventario_con_medidas_sin_proyecciones"
    ) {
      return (
        <Medidas
          base={base}
          newValue={newValue}
          año={año}
          kpiDict={kpiDict}
          type={type}
        />
      );
    }
    if (mode === "inventario_con_medidas_solo_oficial") {
      return (
        <Inventario
          base={base}
          newValue={newValue}
          año={año}
          kpiDict={kpiDict}
        />
      );
    }
  }

  return null;
};

export default KPI;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/SistemasDeGestionDeEnergia.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const SistemasGestionHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        ahorro2030_2040: 1.5,
        ahorro2040_2050: 2.5,
      },
      estado_de_implementacion: {
        ahorro: 0.5,
      },
    },
  };

  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Sistemas_de_Gestión_de_Energía")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Sistemas de gestión de energía"]);
      set2050(dataset[60]["Sistemas de gestión de energía"]);
    })
    .catch((error) => {
      console.error(error);
    });
  return (
    <HelpContent>
      <div>
        <h1>Sistemas de Gestión de Energía</h1>
      </div>
      <h4>Sector Industrias Manufactureras y de la Construcción</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Esta medida corresponde a la promoción de medidas de eficiencia
        energética en los procesos productivos (más conocido como Sistemas de
        Gestión de la Energía - SGE) en los grandes consumidores de energía
        (GCE). Estas empresas tienen un consumo energético superior a 50
        Teracalorías anuales.{" "}
      </p>
      <p>
        Esta medida considera meta, el logro de un ahorro del 2,5% del consumo
        energético de las empresas señaladas producto de la implementación del
        Proyecto de Ley de Eficiencia Energética.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    Ahorro de{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["ahorro2030_2040"]
                        ).replace(".", ",")}
                    </span>
                    % del consumo energético en los años 2030-2040 y de{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["ahorro2040_2050"]
                      ).replace(".", ",")}
                    </span>
                    % en los años 2040-2050, en los GCE. <br />
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada : <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,1 (2030) y 2,5 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>

                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                    Ahorro en los GCE actual a nivel nacional (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["ahorro"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                Ahorro de{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["ahorro2030_2040"]
                    ).replace(".", ",")}
                </span>
                % del consumo energético en los años 2030-2040 y de{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["ahorro2040_2050"]
                    ).replace(".", ",")}
                </span>
                % en los años 2040-2050, en los GCE. <br />
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada : <br />
              </h5>
              <p>
                La reducción esperada de emisiones desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,1 (2030) y 2,5 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          {/* A continuacion se deben ingresar los valores referentes */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                Ahorro en los GCE actual (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["ahorro"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.1, 2.5, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default SistemasGestionHelp;

import React from "react";
import { RPOLICIES_FILE_PATH } from "config";
import { useState, useEffect } from "react";
import axios from 'axios';
import styled from "styled-components";
import { Box, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Loader from "components/ui/Loader";


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MyTab = withStyles(theme => ({
  selected: {
    color: "#ffffff",
    backgroundColor : "#009245",
  }
}))(Tab);

const HelpWrapper = styled.div`
width: 780px;
`;
const MenuWrapper = styled.div`
margin-top: 20px;
`;

/**
 * Componente que crea las fichas con un menu de regiones a la izquierda, abriendo por defecto en la region que se esta haciendo el analisis
 * @param {string} defaultRegionInfo region en la que se esta haciendo el analisis
 * @param {React.ReactNode} [props.children] ficha de la medida 
 * @returns Ficha con menu regional
 */
const RegionesHelp = ({defaultRegionInfo, children}) => {
  const [regiones, setRegiones] = useState(null);
  const [value, setValue] = useState(defaultRegionInfo.id);
  const [currentRegion, setCurrentRegion] = useState(null);
  useEffect(() => {
      // Carga los datos de la region en el url
      axios.get(RPOLICIES_FILE_PATH)
        .then((response) =>
          (setRegiones(response.data),
          setCurrentRegion(response.data[defaultRegionInfo.id]))
        )
        .catch((error) => {
          console.error(error);
        });
    }, []);
  // Cambia el indice y el nombre por el de la region seleccionada 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentRegion(regiones[newValue]);
  };
    return(
        <>
        <Box
          sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto', marginLeft:20}}
        >
          <MenuWrapper>
          {regiones && currentRegion? 
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={Number(value)}
            onChange={handleChange}
            indicatorColor="secondary"
            aria-label="Vertical tabs example"
          >
            {regiones.map((item, index) => <MyTab key={item.title} label={item.title} {...a11yProps(index)}/>) }
          </Tabs>
          : <Loader/>}
          </MenuWrapper>
          <HelpWrapper>{children(currentRegion)}</HelpWrapper>
        </Box>
        
        
        </>
    )
};
export default RegionesHelp;
import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/GeneracionDistribuida.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const GeneracionDistribuidaHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );
  
  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 16141,
        MW_inscritos_residencial: 39.35,
        instalaciones_inscritas_comercial: 863,
        MW_inscritos_comercial: 61.5,
        instalaciones_inscritas_otros: 2318,
        MW_inscritos_otros: 135.88,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 129,
        MW_inscritos_residencial: 0.3,
        instalaciones_inscritas_comercial: 12,
        MW_inscritos_comercial: 0.5,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 76,
        MW_inscritos_residencial: 0.1,
        instalaciones_inscritas_comercial: 12,
        MW_inscritos_comercial: 0.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 271,
        MW_inscritos_residencial: 0.4,
        instalaciones_inscritas_comercial: 10,
        MW_inscritos_comercial: 0.8,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 2226,
        MW_inscritos_residencial: 1.7,
        instalaciones_inscritas_comercial: 12,
        MW_inscritos_comercial: 1.9,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 321,
        MW_inscritos_residencial: 1,
        instalaciones_inscritas_comercial: 26,
        MW_inscritos_comercial: 1.6,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 1920,
        MW_inscritos_residencial: 6.6,
        instalaciones_inscritas_comercial: 109,
        MW_inscritos_comercial: 9.8,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 4949,
        MW_inscritos_residencial: 14.9,
        instalaciones_inscritas_comercial: 198,
        MW_inscritos_comercial: 21.6,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 807,
        MW_inscritos_residencial: 2.7,
        instalaciones_inscritas_comercial: 75,
        MW_inscritos_comercial: 6.6,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 1595,
        MW_inscritos_residencial: 3.8,
        instalaciones_inscritas_comercial: 132,
        MW_inscritos_comercial: 7.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 771,
        MW_inscritos_residencial: 1.7,
        instalaciones_inscritas_comercial: 48,
        MW_inscritos_comercial: 2.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 1211,
        MW_inscritos_residencial: 2.7,
        instalaciones_inscritas_comercial: 75,
        MW_inscritos_comercial: 4.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 794,
        MW_inscritos_residencial: 1.4,
        instalaciones_inscritas_comercial: 55,
        MW_inscritos_comercial: 2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 449,
        MW_inscritos_residencial: 0.8,
        instalaciones_inscritas_comercial: 40,
        MW_inscritos_comercial: 1.5,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 473,
        MW_inscritos_residencial: 0.8,
        instalaciones_inscritas_comercial: 37,
        MW_inscritos_comercial: 1.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_aysen: {
      ccompromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 83,
        MW_inscritos_residencial: 0.2,
        instalaciones_inscritas_comercial: 19,
        MW_inscritos_comercial: 0.2,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        MWresidencial: 1171,
        GWhResidencial: 1800,
        CantidadViviendas: 585000,
        MWcomercial: 3678,
        GWhComercial: 5657,
      },
      estado_de_implementacion: {
        instalaciones_inscritas_residencial: 69,
        MW_inscritos_residencial: 0.1,
        instalaciones_inscritas_comercial: 3,
        MW_inscritos_comercial: 0,
        instalaciones_inscritas_otros: 0,
        MW_inscritos_otros: 0,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty("Generación_Distribuida")
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Generación distribuida"]);
      set2050(dataset[60]["Generación distribuida"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Generación Distribuida</h1>
      </div>
      <h4>Sector Comercial / Institucional y Residencial</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Considera el impacto en demanda eléctrica de la generación distribuida,
        tanto residencial como comercial, bajo el amparo de la Ley de Generación
        Distribuida (actual Ley 21.118, que modifica la Ley 20.571), lo que
        permite la instalación de sistemas de energías renovables no
        convencionales con una capacidad de hasta 300 kW.
      </p>
      <p>
        El escenario referencial considera una proyección de 847 MW de
        generación fotovoltaica residencial al 2050, equivalente a 1.278 GWh,
        aportados por más de 420.000 viviendas; y una proyección de 2.407 MW de
        generación comercial, equivalente a 3.633 GWh al mismo año. Para el
        escenario de carbono-neutralidad se proyectan 1.171 MW instalados al
        2050, aproximadamente 585.000 viviendas, los cuales generan 1.800 GWh al
        2050 y 3.678 MW a nivel comercial los cuales generan 5.657 GWh al 2050.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    <span>
                      {cumplimientoMedidas["nacional"]["compromiso_referencial"]["MWresidencial"]}
                    </span>{" "}
                    MW instalados a nivel nacional en el sector residencial al año 2050, equivalente a{" "}
                    <span>
                      {cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhResidencial"]}
                    </span>{" "}
                    GWh generados y{" "}
                    <span>
                      {cumplimientoMedidas["nacional"]["compromiso_referencial"]["CantidadViviendas"]}
                    </span>{" "}
                    viviendas con instalaciones
                  </p>
                  <p>
                    <span>
                      {cumplimientoMedidas["nacional"]["compromiso_referencial"]["MWcomercial"]}
                    </span>{" "}
                    MW instalados a nivel nacional en el sector comercial al año 2050, equivalente a{" "}
                    <span>
                      {cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhComercial"]}
                    </span>{" "}
                    GWh generados
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,2 (2030) y 0,2 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>
                {" "}
                Cantidad de MW inscritos sector residencial a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["MW_inscritos_residencial"]
                  ).replace(".", ",")}
                </span>{" "}
                MW
                <br /> Cantidad de viviendas inscritas sector residencial a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["instalaciones_inscritas_residencial"]
                  ).replace(".", ",")}
                </span>
              </p>
              <p>
                {" "}
                Cantidad de MW inscritos sector comercial a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["MW_inscritos_comercial"]
                  ).replace(".", ",")}
                </span>{" "}
                MW
                <br /> Cantidad de viviendas inscritas sector comercial a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoRegion["estado_de_implementacion"]["instalaciones_inscritas_comercial"]
                  ).replace(".", ",")}
                </span>
              </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones (millón tCO<sub>2eq</sub>) a nivel regional: <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>
              <p>
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["MWresidencial"]}
                </span>{" "}
                MW instalados a nivel residencial al año 2050, equivalente a{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhResidencial"]}
                </span>{" "}
                GWh generados y{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["CantidadViviendas"]}
                </span>{" "}
                viviendas con instalaciones
              </p>

              <p>
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["MWcomercial"]}
                </span>{" "}
                MW instalados a nivel comercial al año 2050, equivalente a{" "}
                <span>
                  {cumplimientoMedidas["nacional"]["compromiso_referencial"]["GWhComercial"]}
                </span>{" "}
                GWh generados
              </p>
            </WrapperExp>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional desde el 2020 (millón
                tCO<sub>2eq</sub>): <br />
                <span>0,2 (2030) y 0,2 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>

          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>
                {" "}
                Cantidad de MW inscritos sector residencial (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["MW_inscritos_residencial"]
                  ).replace(".", ",")}
                </span>{" "}
                MW
                <br /> Cantidad de viviendas inscritas sector residencial (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["instalaciones_inscritas_residencial"]
                  ).replace(".", ",")}
                </span>
              </p>
              <p>
                {" "}
                Cantidad de MW inscritos sector comercial (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["MW_inscritos_comercial"]
                  ).replace(".", ",")}
                </span>{" "}
                MW
                <br /> Cantidad de viviendas inscritas sector comercial (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["instalaciones_inscritas_comercial"]
                  ).replace(".", ",")}
                </span>
              </p>
              {/*
              <p>
                {" "}
                Cantidad de MW inscritos en otros sectores a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["MW_inscritos_otros"]
                  ).replace(".", ",")}
                </span>{" "}
                MW
                <br /> Cantidad de viviendas inscritas en otros sectores a nivel regional (agosto 2023):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["instalaciones_inscritas_otros"]
                  ).replace(".", ",")}
                </span>
              </p>*/}
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.2, 0.2, dato_2030, dato_2050)}
        </>
      )}
    </HelpContent>
  );
};

export default GeneracionDistribuidaHelp;

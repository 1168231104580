import React from "react";
import WrapperExp from "components/help/WrapperExp";
import Columnas from "components/help/Columnas";
export const categoryLabel = (cat) => {
  const labels = {
    // emisiones GEI
    "generacion electrica": "Generación Eléctrica",
    "industria y mineria": "Industria y Minería",
    publico: "Público",
    "otras industrias energia": "Otras Industrias Energía",
    "otros sectores": "Otros Sectores",
    "emisiones fugitivas": "Emisiones Fugitivas",
    "procesos industriales": "Procesos Industriales",
    forestal: "Forestal",

    // demanda energia
    azucar: "Azúcar",
    industrias_varias: "Industrias varias",
    minas_varias: "Minas varias",
    petroquimica: "Petroquímica",
    siderurgia: "Siderúrgica",
    transporte_aereo: "Transporte aéreo",
    transporte_caminero_carga: "Trans. caminero \ncarga",
    transporte_caminero_pasajeros: "Trans. caminero \npasajeros",
    transporte_ferroviario_carga: "Trans. ferroviario \ncarga",
    transporte_ferroviario_pasajeros: "Trans. ferroviario \npasajeros",
    transporte_maritimo: "Transporte marítimo",

    // generacion
    carbon: "Carbón",
    eolica: "Eólica",
    "hidraulica embalse": "Hidráulica embalse",
    "hidraulica pasada": "Hidráulica pasada",
    "hidraulica serie": "Hidráulica serie",
  };

  return labels[cat] ? labels[cat] : cat.charAt(0).toUpperCase() + cat.slice(1);
};

export const parseValue = (value) =>
  value ? parseFloat(value.replace(/,/g, "")) : null;

export const truncOneDecimal = (value) => {
  const parts = String(value).split(",");
  if (parts.length > 1) {
    return parts[0] + "," + parts[1][0];
  }
  return value;
};

export const toOneDecimal = (dic) => {
  const new_dict = {};
  for (var x in dic) {
    new_dict[x] = Number(dic[x]).toFixed(1);
  }
  return new_dict;
};

export let max_value_from_budget = 0;

export const calc_presupuesto = (
  dataset,
  YEAR_CATEGORY,
  MIN_YEAR,
  MAX_YEAR
) => {
  let budget = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let aux = dataset
    .filter((item) => {
      const year = parseInt(item[YEAR_CATEGORY]);
      return year >= 2020 && year <= 2030;
    })
    .map((value) => toOneDecimal(value));
  let i = 0;
  aux.forEach((dic) => {
    for (var x in dic) {
      if (x !== YEAR_CATEGORY) {
        for (let j = i; j < budget.length; j++) {
          budget[j] += parseFloat(dic[x]);
          max_value_from_budget = Math.min(
            Math.max(max_value_from_budget, budget[j]),
            1200
          );
        }
      }
    }
    i += 1;
  });
  return dataset
    .filter((item) => {
      const year = parseInt(item[YEAR_CATEGORY]);
      return year >= MIN_YEAR && year <= MAX_YEAR;
    })
    .map((value) => toOneDecimal(value));
};

export const desviacion = (DR_2030, DR_2050, dato_2030, dato_2050) => {
  const desviacion_2030 = Number(dato_2030 - DR_2030);
  const desviacion_2050 = Number(dato_2050 - DR_2050);
  const porcentaje_2030 = (
    (desviacion_2030 * 100) /
    (DR_2030 === 0 ? 1 : DR_2030)
  )
    .toFixed(1)
    .replace(".", ",");
  const porcentaje_2050 = (
    (desviacion_2050 * 100) /
    (DR_2050 === 0 ? 1 : DR_2050)
  )
    .toFixed(1)
    .replace(".", ",");
  return (
    <Columnas>
      <WrapperExp notTable>
        <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
          {" "}
          Desviación de la reducción de emisiones esperada: <br />
        </h5>
        <p>
          La diferencia de reducción de emisiones entre lo proyectado y lo que
          se estima que ocurrirá.
          <br />
          {desviacion_2030 >= 0 ? (
            <span style={{ color: "#10d902" }}>
              {Number(dato_2030 - DR_2030)
                .toFixed(1)
                .replace(".", ",")}
              , es decir, {porcentaje_2030} %(2030)
            </span>
          ) : (
            <span style={{ color: "#ff0000" }}>
              {Number(dato_2030 - DR_2030)
                .toFixed(1)
                .replace(".", ",")}
              , es decir, {porcentaje_2030} %(2030)
            </span>
          )}
          <br />
          {desviacion_2050 >= 0 ? (
            <span style={{ color: "#10d902" }}>
              {Number(dato_2050 - DR_2050)
                .toFixed(1)
                .replace(".", ",")}
              , es decir, {porcentaje_2050} %(2050)
            </span>
          ) : (
            <span style={{ color: "#ff0000" }}>
              {Number(dato_2050 - DR_2050)
                .toFixed(1)
                .replace(".", ",")}
              , es decir, {porcentaje_2050} %(2050)
            </span>
          )}
          <br />
        </p>
      </WrapperExp>
    </Columnas>
  );
};

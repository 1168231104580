import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        // marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        // letterSpacing: 2,
        fontStyle: 'bold',
        fontSize: 16,
        textAlign: 'left',
        // fontWeight: '900',
        marginTop: '10',
        marginBottom: '0'
        // textTransform: 'uppercase',
    }
  });


  const SubTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
  );
  
  export default SubTitle
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "components/layout/Header";
import Container from "components/layout/Container";
import Loader from "components/ui/Loader";
import { RPOLICIES_FILE_PATH, REGIONES_PATH } from "config";
import Footer from "components/layout/Footer";
import styled from "styled-components";
import DashboardRegionesContainer from "containers/DashboardContainerRegiones";
import { useParams } from "react-router-dom";
import { Collapse } from "@material-ui/core";
const Title = styled.div`
  background-color: #009245;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  min-height: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
`;
const HowTo = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left:80px;
  padding-right: 80px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  text-align: justify;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;
  width: 92%;
  @media(max-width:720px) {
    padding-left:15px;
    padding-right: 15px;
  }
`;
/**
 * DashboardRegiones.js
 * En este componente carga las medidas y contiene la pagina de analisis por region
 */
const DashboarRegiones = () => {
  const { region } = useParams();
  const [currentRegionPolicies, setCurrentRegionPolicies] = useState(null);
  const [currentRegionInfo, setCurrentRegionInfo] = useState(null);

  useEffect(() => {
    // Carga los datos de la region en el url
    axios
      .get(RPOLICIES_FILE_PATH)
      .then((response) =>
        setCurrentRegionPolicies(response.data.find((item) => item.name === region))
      )
      .catch((error) => {
        console.error(error);
      });
  }, [region]);
  useEffect(() => {
    // Carga los datos de la region en el url
    axios
      .get(REGIONES_PATH)
      .then((response) =>
        setCurrentRegionInfo(response.data.find((item) => item.name === region))
      )
      .catch((error) => {
        console.error(error);
      });
  }, [region]);

  const [checked, setChecked] = useState(false);
  const handleMouseOver = () => {
    setChecked(true);
  };
  const handleMouseOut = () => {
    setChecked(false);
  }
  return (
    <div>
      <Header />
      {currentRegionPolicies && currentRegionInfo ?
      (<>
      <div>
      <Title onMouseOver={() => handleMouseOver()}>{currentRegionPolicies ? currentRegionInfo.title : <Loader />}</Title>
      <Collapse in={checked} >
        <center><HowTo dangerouslySetInnerHTML={{__html: currentRegionInfo.description}}/></center>
      </Collapse>
      </div>
      <Container onMouseOver={()=>handleMouseOut()}>
          <DashboardRegionesContainer policies={currentRegionPolicies.medidas} regionInfo={currentRegionInfo}/>
      </Container>
      </>
      ) : (
          <Loader />
        )}
      <div>
        <br />
        <center>
          <Footer />
        </center>
      </div>
    </div>
  );
};

export default DashboarRegiones;

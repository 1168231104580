import BigNumber from "./layout/bigNumber";
import Fact from "./layout/fact";
import Wrapper from "./layout/wrapper";
import React from "react";

const Inventario = ({ base, newValue, año, kpiDict }) => {
  return (
    <Wrapper>
      <div>
        <Fact>
          <h4>Emisiones en el 2030 son</h4>
          <BigNumber>
            <h1>{Number(kpiDict["tco2_2030"]).toFixed(1).replace(".", ",")}</h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>
      </div>
      <div>
        <Fact>
          <h4>Emisiones acumuladas entre 2020 y 2030 son</h4>
          <BigNumber>
            <h1>
              {Number(kpiDict["tco2_entre_2020_2030"])
                .toFixed(1)
                .replace(".", ",")}
            </h1>
            <label>millones tCO2e.</label>
          </BigNumber>
        </Fact>

        <Fact>
          <h4>
            Porcentaje de Carbon Budget utilizado entre 2020 y{" "}
            {kpiDict["Año Update"]} es
          </h4>
          <BigNumber>
            <h1>
              {(kpiDict["Carbono Utilizado"] / 11).toFixed(1).replace(".", ",")}{" "}
              %
            </h1>
          </BigNumber>
        </Fact>
      </div>
    </Wrapper>
  );
};

export default Inventario;

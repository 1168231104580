import styled from 'styled-components';
import Panel from 'components/ui/Panel';

const Settings = {};

Settings.Panel = styled(Panel)`
  padding: 0px;
  font-size: 12px;
  margin-bottom: 10px;
  marginRight: 3%;
  margin-top:0px;
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  width: 100%;
  justify-content: center;
  max-height: 450px;
  align-items: center;
  justify-content: center;
  text-Align: left;
  background-color:#f8f8f8;
  border: 1px solid #e4e7f0
  h4 {
    align-items: center;
  justify-content: center;
  text-Align: center;
    text-transform: uppercase;
    color: #000285;
    font-weight: normal;
    font-size: 18 px;
    padding: 0 10px;
    margin-bottom: 5px;
  }

  @media(max-width:100px) {
    font-size: 8px;
    display:block;
  }
`;

Settings.Panel.Correct = styled.div`
  padding: 10px 0 10px 0;
  font-size: 14px;
  margin-bottom: 10px;
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  width: 100%;
  overflow-y: scroll;
  max-height: 450px;


  h4 {
    text-transform: uppercase;
    color: var(--light-grey-color);
    font-weight: normal;
    font-size: 12px;
    padding: 0 10px;
    margin-bottom: 5px;
  }
  @media(max-width:100px) {
    font-size: 8px;
  }
`;


Settings.Panel.Medidas = styled.div`
  padding: 10px 0 10px 0;
  font-size: 14px;
  margin-bottom: 10px;
  color: #4b4c4d;
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  width: 100%;
  max-height: 400px;
  background-color: #ffffff;
  overflow-y: scroll;
  h4 {
    text-transform: uppercase;
    color: var(--light-grey-color);
    font-weight: normal;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 0 5px;
    margin-top: 2px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-left: calc(18% - 10px)
  }
  @media(max-width:100px) {
    font-size: 8px;
  }
`;

Settings.Options = styled.div`
  padding: 20px;
  background-color: #f3f3f3;

  label {
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;

    i {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
  }
  @media(max-width:100px) {
    font-size: 8px;
  }
`;

Settings.Item = styled.div`
  padding: 10px 20px;

  & > label {
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 20px;

    i {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
  }
  @media(max-width:100px) {
    font-size: 8px;
  }
`;

export default Settings;
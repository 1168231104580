import React from 'react';
import { func, object } from 'prop-types';
import GeneracionDistribuidaHelp from 'components/help/helpMedidas/GeneracionDistribuidaHelp';
import GeneracionBiogasHelp from 'components/help/helpMedidas/GeneracionBiogasHelp';
import EnergiasRenovablesHelp from 'components/help/helpMedidas/EnergiasRenovablesHelp';
import SistemasGestionHelp from 'components/help/helpMedidas/SistemasGestionHelp';
import ElectrificacionMotrizIndustriaHelp from 'components/help/helpMedidas/ElectrificacionMotrizIndustriaHelp';
import MepsMotores100Help from 'components/help/helpMedidas/MepsMotores100Help';
import UsoMotricesHelp from 'components/help/helpMedidas/UsoMotricesHelp';
import SSTIndustriaMineriaHelp from 'components/help/helpMedidas/SSTIndustriaMineriaHelp';
import ElectrificacionTermicaHelp from 'components/help/helpMedidas/ElectrificacionTermicaHelp';
import ElectrificacionMotrizCobreHelp from 'components/help/helpMedidas/ElectrificacionMotrizCobreHelp';
import ElectrificacionMotrizRestoHelp from 'components/help/helpMedidas/ElectrificacionMotrizRestoHelp';
import FomentoRenovacionHelp from 'components/help/helpMedidas/FomentoRenovacionHelp';
import CalefaccionPublicoComercialHelp from 'components/help/helpMedidas/CalefaccionPublicoComercialHelp';
import SistemasSolaresHelp from 'components/help/helpMedidas/SistemasSolaresHelp';
import ElectrificacionMotrizComercialHelp from 'components/help/helpMedidas/ElectrificacionMotrizComercialHelp';
import BombasGeoHelp from 'components/help/helpMedidas/BombasGeoHelp';
import UsoTermicoGasoductosHelp from 'components/help/helpMedidas/UsoTermicoGasoductosHelp';
import CalefaccionDistritalHelp from 'components/help/helpMedidas/CalefaccionDistritalHelp';
import MepsNuevosHelp from 'components/help/helpMedidas/MepsNuevosHelp';
import VehiculosComercialesElectricosHelp from 'components/help/helpMedidas/VehiculosComercialesElectricosHelp';
import TransporteCargaHidroHelp from 'components/help/helpMedidas/TransporteCargaHidroHelp';
import VehiculosParticularesHelp from 'components/help/helpMedidas/VehiculosParticularesHelp';
import TaxisElectricosHelp from 'components/help/helpMedidas/TaxisElectricosHelp';
import TransporteRMHelp from 'components/help/helpMedidas/TransporteRMHelp';
import TransporteRegionesHelp from 'components/help/helpMedidas/TransporteRegionesHelp';
import CapturaBiogasHelp from 'components/help/helpMedidas/CapturaBiogasHelp';
import BiodigestoresHelp from 'components/help/helpMedidas/BiodigestoresHelp';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';
// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "Generación biogás"){
        return <GeneracionBiogasHelp/>;
    }else if (medida === "Energías renovables en reemplazo de centrales Térmicas"){
        return <EnergiasRenovablesHelp/>;
    }else if(medida === "Sistemas de gestión de energía"){
        return <SistemasGestionHelp/>;
    }else if(medida === "Electrificación motriz industria"){
        return <ElectrificacionMotrizIndustriaHelp/>;
    }else if(medida === "MEPS Motores hasta 100HP"){
        return <MepsMotores100Help/>;
    }else if(medida === "Usos motrices en industria y minería - Hidrógeno"){
        return <UsoMotricesHelp/>;
    }else if (medida === "SST Industria y minería"){
        return <SSTIndustriaMineriaHelp/>;
    }else if (medida === "Electrificación térmica"){
        return <ElectrificacionTermicaHelp/>;
    }else if (medida === "Electrificación motriz - minería cobre"){
        return <ElectrificacionMotrizCobreHelp/>;
    }else if (medida === "Electrificación motriz - resto minería"){
        return <ElectrificacionMotrizRestoHelp/>;
    }else if (medida === "Generación distribuida"){
        return <GeneracionDistribuidaHelp/>;
    }else if (medida === "Fomento a renovación energética de viviendas"){
        return <FomentoRenovacionHelp/>;
    }else if (medida === "Calefacción eléctrica público comercial"){
        return <CalefaccionPublicoComercialHelp/>;
    }else if (medida === "Sistemas solares térmicos – residencial y público"){
        return <SistemasSolaresHelp/>;
    }else if (medida === "Electrificación motriz comercial"){
        return <ElectrificacionMotrizComercialHelp/>;
    }else if (medida === "Bombas Geotérmicas de Calor"){
        return <BombasGeoHelp/>;
    }else if (medida === "Uso térmico vía gasoductos - Hidrógeno"){
        return <UsoTermicoGasoductosHelp/>;
    }else if (medida === "Calefacción distrital"){
        return <CalefaccionDistritalHelp/>;
    }else if (medida === "MEPS Nuevos"){
        return <MepsNuevosHelp/>;
    }else if (medida === "Vehículos comerciales eléctricos"){
        return <VehiculosComercialesElectricosHelp/>;
    }else if (medida === "SST Industria y minería"){
        return <SSTIndustriaMineriaHelp/>;
    }else if (medida === "Transporte de carga - Hidrógeno"){
        return <TransporteCargaHidroHelp/>;
    }else if (medida === "Vehículos particulares eléctricos"){
        return <VehiculosParticularesHelp/>;
    }else if (medida === "Taxis eléctricos"){
        return <TaxisElectricosHelp/>;
    }else if (medida === "Buses Eléctricos en Transporte público RM"){
        return <TransporteRMHelp/>;
    }else if (medida === "Buses Eléctricos en Transporte público regiones"){
        return <TransporteRegionesHelp/>;
    }else if (medida === "Captura o uso de biogás de rellenos sanitarios"){
        return <CapturaBiogasHelp/>;
    }else if (medida === "Biodigestores de purines porcinos"){
        return <BiodigestoresHelp/>;
    }
    return null;
};
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Energia
const MinisterioEnergiaPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => {
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Energía'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
    );
}

MinisterioEnergiaPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
  export default MinisterioEnergiaPolicies;
import React from 'react';
import { func, object } from 'prop-types';
import ElectrificacionMotrizCobreHelp from 'components/help/helpMedidas/ElectrificacionMotrizCobreHelp';
import ElectrificacionMotrizRestoHelp from 'components/help/helpMedidas/ElectrificacionMotrizRestoHelp';
import BombasGeoHelp from 'components/help/helpMedidas/BombasGeoHelp';
import UsoMotricesHelp from 'components/help/helpMedidas/UsoMotricesHelp';
import SSTIndustriaMineriaHelp from 'components/help/helpMedidas/SSTIndustriaMineriaHelp';
import ElectrificacionTermicaHelp from 'components/help/helpMedidas/ElectrificacionTermicaHelp';
import CalefaccionDistritalHelp from 'components/help/helpMedidas/CalefaccionDistritalHelp';
import MepsMotores100Help from 'components/help/helpMedidas/MepsMotores100Help';
import MedidasAutoridadesContainer from './MedidasAutoridadesContainer';
import SistemasGestionHelp from 'components/help/helpMedidas/SistemasGestionHelp';

// Retorna la ficha dependiendo de la medida seleccionada
const chooseHelp = (medida) => {
    if (medida === "Electrificación motriz - minería cobre"){
        return <ElectrificacionMotrizCobreHelp/>;
    }else if (medida === "Electrificación motriz - resto minería"){
        return <ElectrificacionMotrizRestoHelp/>;
    }else if(medida === "Bombas Geotérmicas de Calor"){
        return <BombasGeoHelp/>;
    }else if(medida === "Usos motrices en industria y minería - Hidrógeno"){
        return <UsoMotricesHelp/>;
    }else if(medida === "SST Industria y minería"){
        return <SSTIndustriaMineriaHelp/>;
    }else if(medida === "Electrificación térmica"){
        return <ElectrificacionTermicaHelp/>;
    }else if (medida === "Calefacción distrital"){
        return <CalefaccionDistritalHelp/>;
    }else if (medida === "MEPS Motores hasta 100HP"){
        return <MepsMotores100Help/>;
    }else if (medida === "Sistemas de gestión de energía"){  // Esta medida no estaba para el Min de Mineria en la version antigua 
        return <SistemasGestionHelp/>;                       // pero lo agregue porque esta en medidasAutoridades.json
    }
    return null;
}
// Crea un container con la lista de medidas que se puede seleccionar para el Ministerio de Mineria
const MinisterioMineriaPolicies = ({ settings, onChange, setMedidasActivas, medidasActivas}) => { 
    return (
        <MedidasAutoridadesContainer
        sector='Ministerio de Minería'
        settings={settings}
        onChange={onChange}
        setMedidasActivas={setMedidasActivas}
        medidasActivas={medidasActivas}
        chooseHelp={chooseHelp}/>
);
}

MinisterioMineriaPolicies.propTypes = {
    onChange: func,
    settings: object,
  };
  
export default MinisterioMineriaPolicies;
import React from "react";
import { func, object } from "prop-types";
import GeneracionBiogasHelp from "components/help/helpMedidas/GeneracionBiogasHelp";
import EnergiasRenovablesHelp from "components/help/helpMedidas/EnergiasRenovablesHelp";
import MedidasSectoresContainer from "./MedidasSectoresContainer";
const chooseHelp = (medida) => {
  if (medida === "Generación biogás") {
    return <GeneracionBiogasHelp />;
  } else if (
    medida === "Energías renovables en reemplazo de centrales Térmicas"
  ) {
    return <EnergiasRenovablesHelp />;
  }
  return null;
};

const EnergiaPolicies = ({
  settings,
  onChange,
  setMedidasActivas,
  medidasActivas,
}) => {
  return (
    <MedidasSectoresContainer
      sector="Energía"
      settings={settings}
      onChange={onChange}
      setMedidasActivas={setMedidasActivas}
      medidasActivas={medidasActivas}
      chooseHelp={chooseHelp}
    />
  );
};

EnergiaPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default EnergiaPolicies;

import React, { useState, useEffect } from "react";
import HelpContent from "../HelpContent";
import proyeccionParticipacion from "img/medidas/calefaccion electrica publico y comercial.jpg";
import csvtojson from "csvtojson/v2";
import axios from "axios";
import { YEAR_CATEGORY } from "utils/constants";
import WrapperExp from "../WrapperExp";
import Columnas from "../Columnas";
import { desviacion } from "utils/helpers";
/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) =>
  axios.get(datasetPath).then((response) =>
    csvtojson({ ignoreEmpty: true })
      // TODO: dynamic path depending on settings

      // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
      // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
      // esta solucion temporal
      .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  );

const CalefaccionPublicoComercialHelp = ({ region }) => {
  const [dato_2030, set2030] = useState(0);
  const [dato_2050, set2050] = useState(0);

  //Si la region que se muestra en la ficha contiene esa medida segun medidasRegiones.csv
  const [policyInRegion, setPolicyInRegion] = useState(false);

  //DatasetPath que se cambia si es ficha regional
  const [datasetPath, setDatasetPath] = useState(
    "./datasets/inventario/inventarioEmisionesOficialMedidas.csv"
  );

  //Contiene el compromiso referencial y estado de implementacion de la region 
  const [cumplimientoRegion, setcumplimientoRegion] = useState(null);

  //Aquí se deben ingresar los datos referenciales y el avance de la medida
  const cumplimientoMedidas = {
    nacional: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_arica_y_parinacota: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_tarapaca: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_antofagasta: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_atacama: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_coquimbo: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_valparaiso: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_metropolitana: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_ohiggins: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_del_maule: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_del_nuble: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_del_biobio: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_la_araucania: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_los_rios: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_los_lagos: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_aysen: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
    region_de_magallanes: {
      compromiso_referencial: {
        supermercados: 84,
        multitiendas: 76,
        clinicas: 48,
        hospitales: 14,
      },
      estado_de_implementacion: {
        supermercados: 20,
        multitiendas: 15,
        clinicas: 10,
        hospitales: 2,
      },
    },
  };
  useEffect(() => {
    //Si esta en una ficha regional chequea que la region contenga la medida y setea los parametros
    if (region) {
      if (
        region.medidas.find((item) =>
          item.settings.hasOwnProperty(
            "Calefacción_Electrica_Público_Comercial"
          )
        )
      ) {
        setPolicyInRegion(true);
        setDatasetPath(region.medidas[0].datasets["inventario_con_medidas"]);
        const [key, value] = Object.entries(cumplimientoMedidas).find(
          ([key, value]) => key === region.name
        );
        setcumplimientoRegion(value);
      } else {
        setPolicyInRegion(false);
      }
    }
  }, [region]);
  getData(datasetPath)
    .then((dataset) => {
      set2030(dataset[40]["Calefacción eléctrica público comercial"]);
      set2050(dataset[60]["Calefacción eléctrica público comercial"]);
    })
    .catch((error) => {
      console.error(error);
    });

  return (
    <HelpContent>
      <div>
        <h1>Calefacción Eléctrica Público Comercial</h1>
      </div>
      <h4>Sector Comercial / Institucional y Residencial</h4>
      <p>
        <img
          src={proyeccionParticipacion}
          style={{ float: "right", marginBottom: "2em" }}
          alt="Proyección de participación de motores eficientes"
        />
        Considera el impacto en demanda eléctrica de la generación distribuida,
        tanto residencial como comercial, bajo el amparo de la Ley de Generación
        Distribuida (actual Ley 21.118, que modifica la Ley 20.571), lo que
        permite la instalación de sistemas de energías renovables no
        convencionales con una capacidad de hasta 300 kW.
      </p>
      <p>
        El escenario referencial considera una proyección de 847 MW de
        generación fotovoltaica residencial al 2050, equivalente a 1.278 GWh,
        aportados por más de 420.000 viviendas; y una proyección de 2.407 MW de
        generación comercial, equivalente a 3.633 GWh al mismo año. Para el
        escenario de carbono-neutralidad se proyectan 1.171 MW instalados al
        2050, aproximadamente 585.000 viviendas, los cuales generan 1.800 GWh al
        2050 y 3.678 MW a nivel comercial los cuales generan 5.657 GWh al 2050.
      </p>

      {region ? (
        <>
          {/* Segmento de ficha regional */}
          {policyInRegion && cumplimientoRegion ? (
            <>
              <Columnas>
                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Compromiso referencial: <br />
                  </h5>
                  <p>
                    En sector comercial: participación al año 2050 del{" "}
                    <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["supermercados"]
                      ).replace(".", ",")}
                    </span>%, <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["multitiendas"]
                      ).replace(".", ",")}
                    </span>% y <span>
                      {String(
                        cumplimientoRegion["compromiso_referencial"]["clinicas"]
                      ).replace(".", ",")}
                    </span>% de calefacción eléctrica en supermercados, 
                    multitiendas y clínicas respectivamente.
                    <br /> En sector público: participación del <span>{String(
                        cumplimientoRegion["compromiso_referencial"]["hospitales"]
                      ).replace(".", ",")}</span>%
                    en calefacción hospitales.{" "}
                  </p>
                </WrapperExp>
                <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones a nivel nacional  (millón tCO<sub>2eq</sub>): <br />
                <span>0,01 (2030) y 0,7 (2050) </span>
              </p>
            </WrapperExp>
            </Columnas>
            <Columnas>

                <WrapperExp table>
                  <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                    Estado de implementación de la medida: <br />
                  </h5>
                  <p>Participación de calefacción eléctrica a nivel nacional en: </p>
                  <p>
                    {" "}
                    Supermercados (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["supermercados"]
                      ).replace(".", ",")}
                    </span>
                    %<br /> Multitiendas (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["multitiendas"]
                      ).replace(".", ",")}
                    </span>
                    %<br /> Clínicas (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["clinicas"]
                      ).replace(".", ",")}
                    </span>
                    %<br /> Hospitales (estimación):{" "}
                    <span>
                      {String(
                        cumplimientoRegion["estado_de_implementacion"]["hospitales"]
                      ).replace(".", ",")}
                    </span>
                    %
                  </p>
                </WrapperExp>
              

              <WrapperExp table>
                <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                  Reducción de emisiones esperada por el Observatorio: <br />
                </h5>
                <p>
                  La reducción esperada de emisiones a nivel regional (millón tCO<sub>2eq</sub>): <br />
                  <span>
                    {" "}
                    {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                    {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                  </span>
                </p>
              </WrapperExp>
              </Columnas>
            </>
          ) : (
            <WrapperExp>
              <h5>No hay registros de esta medida en esta región.</h5>
            </WrapperExp>
          )}
        </>
      ) : (
        <>
          {/* Segmento de ficha nacional */}
          <Columnas>
            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Compromiso referencial: <br />
              </h5>

              <p>
                En sector comercial: participación al año 2050 del{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["supermercados"]
                  ).replace(".", ",")}
                </span>%, <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["multitiendas"]
                  ).replace(".", ",")}
                </span>% y <span>{String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["clinicas"]
                  ).replace(".", ",")}
                </span>% de calefacción eléctrica en supermercados, 
                multitiendas y clínicas respectivamente.
                <br /> En sector público: participación del 
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["compromiso_referencial"]["hospitales"]
                  ).replace(".", ",")}
                </span>% en calefacción hospitales.{" "}
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>0,01 (2030) y 0,7 (2050) </span>
              </p>
            </WrapperExp>
          </Columnas>
          <Columnas>
            {/* A continuacion se deben ingresar los valores referentes */}

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Estado de implementación de la medida: <br />
              </h5>
              <p>Participación de calefacción eléctrica en: </p>
              <p>
                {" "}
                Supermercados (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["supermercados"]
                  ).replace(".", ",")}
                </span>
                %<br /> Multitiendas (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["multitiendas"]
                  ).replace(".", ",")}
                </span>
                %<br /> Clínicas (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["clinicas"]
                  ).replace(".", ",")}
                </span>
                %<br /> Hospitales (estimación):{" "}
                <span>
                  {String(
                    cumplimientoMedidas["nacional"]["estado_de_implementacion"]["hospitales"]
                  ).replace(".", ",")}
                </span>
                %
              </p>
            </WrapperExp>

            <WrapperExp table>
              <h5 style={{ fontWeight: 800, fontSize: "16px" }}>
                Reducción de emisiones esperada por el Observatorio: <br />
              </h5>
              <p>
                La reducción esperada de emisiones (millón tCO<sub>2eq</sub>): <br />
                <span>
                  {Number(dato_2030).toFixed(1).replace(".", ",")} (2030) y{" "}
                  {Number(dato_2050).toFixed(1).replace(".", ",")} (2050){" "}
                </span>
              </p>
            </WrapperExp>
          </Columnas>
          {desviacion(0.01, 0.7, dato_2030, dato_2050)}
          <br />
        </>
      )}
    </HelpContent>
  );
};

export default CalefaccionPublicoComercialHelp;

import React, { useState } from "react";
import styled from "styled-components";
import ChartMode from "./ChartMode";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  @media (max-width: 720px) {
    display: block;
  }
`;

const Sidebar = styled.div`
  width: ${(props) => (props.narrow ? "360px" : "420px")};
  margin-right: 10px;
  overflow-x: hidden;

  @media (max-width: 720px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

const Main = styled.div`
  width: 100%;
`;

const AddMode = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 10px;
    appearance: none;
    float: right;
    margin: 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    background-color: var(--light-grey-color);
    color: white;
    cursor: pointer;
  }
`;
const HowTo = styled.div`
  padding: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e4e7f0;
  background-color: #f8f8f8;
  text-color: #009ba6;
`;

const DashboardContainerInventario = ({ policies }) => {
  // Opciones del menú izquierdo, por ejemplo: Instrumentos económicos
  const medidaSettings = policies.find(
    (item) => item.name === "Seleccionar Autoridad Climática"
  ).settings;

  // Obtenemos la ruta del set de datos, basado en la configuración de las medidas.
  const currentAutoridad_Sectorial = policies.find(
    (item) => item.name === "Seleccionar Autoridad Climática"
  );

  // const [medidas, setMedidas] = useState(policies.find(item => item.name === "Transporte").medidas)
  // setup visible charts
  const [visibleCharts, setVisibleCharts] = useState([true]);

  // whether or not we're alread showing all the possible charts
  const amountVisible = visibleCharts.filter((item) => item).length;
  const medidasActivas = [];

  // hide chart on index === i
  const hideChart = (i) => {
    setVisibleCharts(
      visibleCharts.map((item, j) => {
        if (i === j) {
          return false;
        }
        return item;
      })
    );
  };

  const showAdditionalChart = () => {
    setVisibleCharts([...visibleCharts, true]);
  };
  return (
    <Wrapper>
      <Sidebar>
        <HowTo>
          En este selector es posible elegir distintas visualizaciones para el
          Inventario Oficial
          <span
            style={{ fontSize: "16px" }}
            role="img"
            aria-labelledby="right-pointing"
          >
            &#x1f449;
          </span>
        </HowTo>
        <HowTo>
          El gráfico representa los valores de gases de efecto invernadero, los
          cuales se encuentran expresados en millones de toneladas de Dióxido de
          Carbono Equivalente.
        </HowTo>
      </Sidebar>
      <Main>
        {visibleCharts.map(
          (visible, i) =>
            visible && (
              <ChartMode
                key={i}
                index={i}
                onHide={amountVisible > 1 ? () => hideChart(i) : null}
                currentScenario={currentAutoridad_Sectorial}
                settings={medidaSettings}
                medidasActivas={medidasActivas}
                type={"inventario"}
                selected={currentAutoridad_Sectorial.name}
              />
            )
        )}
        {amountVisible < 5 && (
          <AddMode>
            <button type="button" onClick={showAdditionalChart}>
              Agregar indicador +
            </button>
          </AddMode>
        )}
      </Main>
    </Wrapper>
  );
};

export default DashboardContainerInventario;
